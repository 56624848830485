import React, { useState, useEffect } from 'react'
import { ModalListDiv, Button, ButtonClose } from './ModalPrice.style'

import { useDispatch } from 'react-redux';

import useTheme from "../../lib/useTheme";
import {ISettlementType} from "../../types/operation";
import {numberWithCommasNumberType,  onlyNumber} from "../../lib/asyncUtils";
import {
  settlementPrice
} from "../../store/actions/operation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  valueData: ISettlementType
  modalVisible: boolean
  setModalVisible: any
  init: boolean
  setInit: any
}
/* 2022년 2월 21일 미사용 예정*/
function ModalPrice({valueData, modalVisible, setModalVisible, init, setInit  }: Props) {
  const [theme] = useTheme(); // 화면모드
  const dispatch = useDispatch();
  const [listTitle, setListTitle] = useState('')

  const [updateBtn, setUpdateBtn] = useState(false)
  const [selectSettlement, setSelectSettlement] = useState<ISettlementType>({
    id: 0,
    site_id: '',
    day: '',
    name: '',
    esSmp: 0,
    smp: 0,
    rec: 0,
    run_price: 0,
    rental: 0,
    vat: 0,
    total: 0,
    cross_yn: '',
    email: '',
    bill_yn: '',
    bill_date: '',
    bill_num: '',
    state: '',
    typeb: ''
  })

  const updatePrice = () =>{
    const result = dispatch(settlementPrice(selectSettlement))
    // @ts-ignore
    result.then(() => {
      toast.dark("업데이트 되었습니다.")
    }).catch(() => {
      toast.dark("업데이트 실패! 다시 시도해주세요.")
    })
  }

  const updateTotal = (name:string, val:number) =>{
    var total = 0
    /*if(name==='smp'){
      total = val +  Number(selectSettlement.rec) - Number(selectSettlement.rental) - Number(selectSettlement.run_price)
    }else if(name==='rec'){
      total = Number(selectSettlement.smp) + val - Number(selectSettlement.rental) - Number(selectSettlement.run_price)
    }else */
    if(name==='run_price'){
      total = Number(selectSettlement.smp) + Number(selectSettlement.rec) - Number(selectSettlement.rental) - val
    }
    return total
  }

  const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value } = e.target
    let tempValue = value
    if(name==='smp'||name==='rec'||name==='run_price'){
      tempValue = onlyNumber(value)
    }

    let total = updateTotal(name,Number(tempValue))
    setSelectSettlement({
      ...selectSettlement,
      [name]: Number(tempValue), ['total']: total
    })
    setUpdateBtn(true)
  }

  useEffect(() => {
    if(init === true){
       setListTitle('정산금액 수정')
       setInit(false)
       setSelectSettlement(valueData)
    }

  },[selectSettlement]);

  useEffect(() => {
    const handleEsc = (event:any) => {
      if (event.keyCode === 27) {
        setModalVisible(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);


    if(!modalVisible){
      return null;
    }

    return (
      <ModalListDiv>
        <div className='modal'>
          <div className='modal-header'>
            <div className='sub'>
              <div className='title'>{listTitle}</div>
            </div>
            <ButtonClose onClick={() => {setModalVisible(false)}} themeMode={theme}></ButtonClose>
          </div>
          <div className='modal-body'>
            <div className='modal-grid'>
              <div className='col-right'>
                  <div className='content'>
                    <div className='content-body'>
                      <div className='body-grid-2'>
                        <div className='grid-sub'>
                          <div className='edit_title'>SMP(단위: 원)</div>
                          <div className='price'>{numberWithCommasNumberType(selectSettlement.smp)}</div>
                          {/*<Input type='text' name='smp' value={selectSettlement.smp} handleChangeInput={handleChangeInput} max={12}/>*/}
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC(단위: 원)</div>
                          <div className='price'>{numberWithCommasNumberType(selectSettlement.rec)}</div>
                          {/*<Input type='text' name='rec' value={selectSettlement.rec} handleChangeInput={handleChangeInput} max={12}/>*/}
                        </div>
                      </div>

                      <div className='body-grid-2'>
                        {/*<div className='grid-sub'>
                          <div className='edit_title'>운영비(단위: 원)</div>
                          <Input type='text' name='run_price' value={selectSettlement.run_price} handleChangeInput={handleChangeInput} max={12}/>
                        </div>*/}
                        <div className='grid-sub'>
                          <div className='edit_title'>장비(단위: 원)</div>
                          <div className='price'>{numberWithCommasNumberType(selectSettlement.rental)}</div>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>합계</div>
                          <div className='price total'>{numberWithCommasNumberType(selectSettlement.total)+' 원'}</div>
                        </div>
                      </div>
                    </div>

                    {/*<div className='content-action'>
                      <Button bg={updateBtn} onClick={()=>updatePrice()}>수정</Button>
                    </div>*/}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1500}/>
      </ModalListDiv>
    );
  }

  export default ModalPrice;

  