import classNames from 'classnames'
import React,{useState} from 'react'
import ModalList from '../../components/ModalList'


export interface TableBody {
    [key: string]: {
        className?: string
        content: string | React.ReactNode
    }
}
interface IProps {
    header: { className?: string; name: string; key: string }[]
    body: TableBody[]
    clickAble?: boolean
}
function Table({ header, body, clickAble }: IProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [init, setInit] = useState(false);

    const [useType, setUseType] = useState('');
    const [useId, setUseId] = useState(0);

    const handleSelect = (id:any, type:any) => {
        setUseType(type)
        setUseId(id)
        setIsModalOpen(true)
        setInit(true)
    }

    
    return (
        <div>
        <table>
            <thead>
                <tr>
                    {header.map((h) => (
                        <th className={h.className} key={h.key}>
                            {h.name}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {body.map((b, i) => (
                    <tr key={i}>
                        {clickAble!==false &&
                            header.map((h) => (
                            <td key={h.key} className={classNames(b[h.key].className)} onClick={() => handleSelect(b['id'].content, b['type'].content)}>
                                {b[h.key].content}
                            </td>
                        ))}
                        {clickAble===false &&
                        header.map((h) => (
                            <td key={h.key} className={classNames(b[h.key].className)} style={{cursor:"auto"}}>
                                {b[h.key].content}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
        {isModalOpen && <ModalList modalVisible={isModalOpen} setModalVisible={setIsModalOpen} useId={useId} useType={useType} init={init} setInit={setInit}></ModalList>}
        </div>
    )
}

export default Table
