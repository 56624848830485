import React, { useCallback, useEffect, useState } from 'react'
import { State } from 'store/reducers'
import { Base64 } from 'js-base64'
import useInterval from 'lib/useInterval'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from 'lib/googleAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { numberWithCommas, percent, upDown } from 'lib/asyncUtils'
import { readAlerts, readAlertsAbnormal, readAlertsReplacement } from 'store/actions/alerts'
import { mySids, todayPg, monthPg, readDevices, todayRevenue, monthRevenue, selectSid } from 'store/actions/operation'

import TopBar from 'components/TopBar/TopBar'
import PgTimeBar from 'components/PgTimeBar/PgTimeBar'
import MainPanels from 'templates/Home/MainPanels'
import EnergyChart from 'templates/Home/EnergyChart'
import StatusPanels from 'templates/Common/StatusPanels'
import EarnDetailsCard from 'templates/Home/EarnDetailsCard'
import FaultDetectionCard from 'templates/Home/FaultDetectionCard'
import { InnerContainer } from './Home.style'

function HomePresenter() {
    useAnalytics('Home')
    const location = useLocation()
    const [load, setLoad] = useState(true)

    const dispatch = useDispatch()
    const stateIndex: any = useSelector((state: State) => state.alerts)
    const deviceIndex: any = useSelector((state: State) => state.devices)

    const initPage = useCallback(() => {
        // 주요내역
        dispatch(readAlerts(0))
        // 이상감지
        dispatch(readAlertsAbnormal(0))
        // 오늘의 발전량
        dispatch(todayPg('Y'))
        // 이달의 발전량
        dispatch(monthPg('Y'))
        // 오늘의 예상수익
        dispatch(todayRevenue('Y'))
        // 이달의 예상수익
        dispatch(monthRevenue('Y'))

        // 디바이스 정보
        dispatch(readDevices())
        setLoad(false)

        // 교체필요
        dispatch(readAlertsReplacement(0, 'replacement'))
    }, [dispatch])

    useEffect(() => {
        if (load) {
            // 사이트 정보 한번만 호출
            dispatch(mySids())
            initPage()
        }
    }, [dispatch, initPage, load])

    useEffect(() => {
        const sid = location.search.includes('site_id=') && location.search.split('site_id=')[1]
        if (sid && deviceIndex.mySids?.find((item: any) => item.site_id === sid)) {
            dispatch(selectSid(Base64.encode(sid)))
            initPage()
        }
    }, [dispatch, location.search, deviceIndex.mySids, initPage])

    // Dynamic delay
    const [delay] = useState<number>(60000)
    // ON/OFF
    const [isPlaying] = useState<boolean>(true)

    useInterval(
        () => {
            // 주요내역
            dispatch(readAlerts(0))
            // 이상감지
            dispatch(readAlertsAbnormal(0))
            // 오늘의 발전량
            dispatch(todayPg('Y'))
            // 이달의 발전량
            dispatch(monthPg('Y'))
            // 오늘의 예상수익
            dispatch(todayRevenue('Y'))
            // 이달의 예상수익
            dispatch(monthRevenue('Y'))

            // 디바이스 정보
            dispatch(readDevices())

            // 교체필요
            dispatch(readAlertsReplacement(0, 'replacement'))
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    return (
        <>
            <TopBar initPage={initPage} allSid={deviceIndex.mySids} />
            <InnerContainer>
                <MainPanels
                    values={{
                        monthProfit: {
                            current: numberWithCommas(deviceIndex.monthRevenue),
                            past: numberWithCommas(deviceIndex.lastMonthRevenue),
                            upDown: upDown(deviceIndex.lastMonthRevenue),
                        },
                        todayProfit: {
                            current: numberWithCommas(deviceIndex.todayRevenue),
                            past: numberWithCommas(deviceIndex.yesterdayRevenue),
                            upDown: upDown(deviceIndex.yesterdayRevenue),
                        },
                        todayEnergy: {
                            current: numberWithCommas(deviceIndex.todayPg, 1),
                            past: numberWithCommas(deviceIndex.yesterdayPg, 1),
                            upDown: upDown(deviceIndex.yesterdayPg),
                        },
                        monthEnergy: {
                            current: numberWithCommas(deviceIndex.monthPg, 1),
                            past: numberWithCommas(deviceIndex.lastPg, 1),
                            upDown: upDown(deviceIndex.lastPg),
                        },
                    }}
                />
                <PgTimeBar siteInfo={deviceIndex.mySids} deviceInfo={deviceIndex.devices} />
                <StatusPanels
                    values={{
                        totalNum: deviceIndex.stateT,
                        good: { number: deviceIndex.stateG, percent: percent(deviceIndex.stateG, deviceIndex.stateT) },
                        normal: {
                            number: deviceIndex.stateD,
                            percent: percent(deviceIndex.stateD, deviceIndex.stateT),
                        },
                        bad: {
                            number: deviceIndex.stateB,
                            percent: percent(deviceIndex.stateB, deviceIndex.stateT),
                            warningNum: stateIndex.replacementCnt,
                        },
                        sleep: { number: deviceIndex.stateS, percent: percent(deviceIndex.stateS, deviceIndex.stateT) },
                    }}
                />
                <EnergyChart />
                <FaultDetectionCard values={stateIndex.alertsAbnormal} />
                <EarnDetailsCard values={stateIndex.alerts.filter((_: any, index: number) => index < 7)} />
            </InnerContainer>
        </>
    )
}

export default HomePresenter
