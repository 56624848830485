import styled from 'styled-components'

import Ic_modalClose from '../../assets/img/ic_modal_close.svg'
import Ic_modalCloseDark from '../../assets/img/ic_modal_close_dark.svg'

export const ModalSubhomeDiv = styled.div<{themeMode:string}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .modal {
        background-color: ${(props) => props.themeMode==='light'? 'white':'#212429'};
        border: 1px solid ${(props) => props.themeMode==='light'? '#ECEFF2':'#ECEFF2'};
        border-radius: 10px;
        width: 900px;
        height: 700px;
        overflow: hidden;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 30px;
            height: 60px;
            background-color: ${(props) => props.themeMode==='light'? 'white':'#212429'};
            .sub {
                display: flex;
                align-items: center;
                color: ${(props) => props.themeMode==='light'? '#111':'#DDE2E5'};
            }
            .title {
                font-size: 24px;
                margin-right: 30px;
            }
        }    
        &-body {
            height: calc(100% - 48px);
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding-bottom: 60px;
        }    
    }
`

export const ButtonClose = styled.button<{themeMode:string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  appearance: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: url(${(props) => props.themeMode==='light'?Ic_modalClose:Ic_modalCloseDark}) no-repeat;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    padding-bottom: 1px;
  }
`
