import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useRef } from 'react'
import { IGraphDataCate} from '../../types/operation';
import useTheme from "../../lib/useTheme";


interface IProps {
    chartId: string
    chartData: IGraphDataCate[]
    inverter: number
}
//발전소 누르면 나오는 페이지
function ShModalChartPg({ chartId, chartData, inverter }: IProps) {
    const [theme] = useTheme(); // 화면모드
    let useData:IGraphDataCate[] = chartData

    am4core.useTheme(am4themes_animated)
    
    const chartRef = useRef<am4charts.XYChart | null>(null)

    //useLayoutEffect(() => {
    useEffect(() => {
        const chart = am4core.create(chartId, am4charts.XYChart)
        chart.data = useData
        chart.zoomOutButton.disabled = true
        chart.numberFormatter.numberFormat = "#,###.#"
        chart.paddingLeft = 30

        let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        dateAxis.renderer.grid.template.strokeOpacity = 0
        dateAxis.renderer.labels.template.fillOpacity = 0.5
        dateAxis.renderer.labels.template.fontSize = 12
        dateAxis.renderer.minGridDistance = 20
        dateAxis.cursorTooltipEnabled = false
        dateAxis.dataFields.category = "category"

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.labels.template.fillOpacity = 0.5
        valueAxis.cursorTooltipEnabled = false
        valueAxis.fontSize = 10

        let series1 = chart.series.push(new am4charts.LineSeries())
        series1.dataFields.categoryX = 'category'
        series1.dataFields.valueY = 'real1'
        series1.name = '인버터 1'
        series1.stacked = true
        series1.fillOpacity = 0.85
        series1.tooltipText = '{categoryX}시'+' [bold]{valueY}[/] kWh'

        if(inverter > 1) {
            let series2 = chart.series.push(new am4charts.LineSeries())
            series2.dataFields.categoryX = 'category'
            series2.dataFields.valueY = 'real2'
            series2.name = '인버터 2'
            series2.stacked = true
            series2.fillOpacity = 0.85
            series2.tooltipText = '{categoryX}시' + ' [bold]{valueY}[/] kWh'
        }

        if(inverter > 2) {
            let series3 = chart.series.push(new am4charts.LineSeries())
            series3.dataFields.categoryX = 'category'
            series3.dataFields.valueY = 'real3'
            series3.name = '인버터 3'
            series3.stacked = true
            series3.fillOpacity = 0.85
            series3.tooltipText = '{categoryX}시' + ' [bold]{valueY}[/] kWh'
        }

        if(inverter > 3) {
            let series4 = chart.series.push(new am4charts.LineSeries())
            series4.dataFields.categoryX = 'category'
            series4.dataFields.valueY = 'real4'
            series4.name = '인버터 4'
            series4.stacked = true
            series4.fillOpacity = 0.85
            series4.tooltipText = '{categoryX}시' + ' [bold]{valueY}[/] kWh'
        }

        chart.cursor = new am4charts.XYCursor()
        chart.cursor.behavior = 'panX'

        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.text = "{name}";

        //다크모드
        if(theme==='dark') {
            dateAxis.renderer.grid.template.stroke = am4core.color('#fff')
            dateAxis.renderer.labels.template.fill = am4core.color('#fff')
            valueAxis.renderer.grid.template.stroke = am4core.color('#fff')
            valueAxis.renderer.labels.template.fill = am4core.color('#fff')
            chart.legend.labels.template.fill = am4core.color('#fff')
        }

        chartRef.current = chart


        return () => {
            chart.dispose()
        }

    }, [chartId, chartData ])

    return <div id={chartId} style={{ width: '100%', height: '300px', minHeight: '250px' }}></div>
}

export default ShModalChartPg