import React, {useState}  from 'react'
import styled from 'styled-components'
import WarningIcon from '../../assets/img/ic_summary1.svg'
import SuccessIcon from '../../assets/img/ic_summary3.svg'
import DocumentIcon from '../../assets/img/ic_summary2.svg'

import WarningIconDark from '../../assets/img/ic_summary1_dark.svg'
import SuccessIconDark from '../../assets/img/ic_summary3_dark.svg'
import DocumentIconDark from '../../assets/img/ic_summary2_dark.svg'
import Card from '../../components/Card/Card'
import ModalList from '../../components/ModalList'
import useTheme from "../../lib/useTheme";


export interface Summary {
    type: 'replacement' | 'abnormal' | 'success' | 'document'
    title: string
    content: string
    process: string
    id: number
}

interface IProps {
    title: string
    onClickMore: string
    summaryList: Summary[]
    noClick?: boolean
}

const mappingStatusToIcon = {
    replacement: WarningIcon,
    abnormal: WarningIcon,
    success: SuccessIcon,
    document: DocumentIcon,
}

const mappingStatusToIconDark = {
    replacement: WarningIconDark,
    abnormal: WarningIconDark,
    success: SuccessIconDark,
    document: DocumentIconDark,
}

const mappingStatusToName = {
    'A': '알림',
    'C': '확인',
    'U': '미처리',
    'P': '처리완료',
    'H': '보류',
}

function SummaryCard({ title, onClickMore, summaryList, noClick = false }: IProps) {
    const [theme] = useTheme(); // 화면모드
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [init, setInit] = useState(false);
    const [useType, setUseType] = useState('');
    const [useId, setUseId] = useState(0);
    
    const handleSelect = (id:any, type:any) => {
        if(noClick){
            return
        }
        setUseType(type)
        setUseId(id)
        setIsModalOpen(true)
        setInit(true)
    }

    return (
        <Card girdArea="summary" title={title} onClickMore={onClickMore}>
            <SummaryItem noClick={noClick}>
                {summaryList.map((summary, index) => (
                    <div className="row" key={index} onClick={() => handleSelect(summary.id,onClickMore)}>
                        <img className="summary-icon" src={theme==='light'?mappingStatusToIcon[summary.type]:mappingStatusToIconDark[summary.type]} alt="" />
                        <div className="summary-content">
                            <em>{summary.title}</em>
                            {summary.content}
                        </div>
                        {(summary.process==='A'||summary.process==='C'||summary.process==='U') && <WarningLabel>{mappingStatusToName[summary.process]}</WarningLabel>}
                    </div>
                ))}
                {summaryList.length < 1 &&
                    <div className="row">
                        <div className="summary-content-no">
                            내용이 없습니다.
                        </div>
                    </div>
                }
            </SummaryItem>
            {isModalOpen && <ModalList modalVisible={isModalOpen} setModalVisible={setIsModalOpen} useId={useId} useType={useType} init={init} setInit={setInit}></ModalList>}
        </Card>
    )
}

export default SummaryCard

const SummaryItem = styled.div<{ noClick: boolean }>`
    ${(props) => props.noClick ?'max-height: 275px;overflow-y: auto;':''}
    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid ${(props) => props.theme.line};
        padding: 10px 0;
        cursor: ${(props) => props.noClick ?'':'pointer'};
    }
    .summary-icon {
        margin-right: 20px;
    }
    .summary-content {
        flex: 1 0 182px;
        font-size: 12px;
        color: ${(props) => props.theme.panelSubLabel};
        line-height: 1.5em;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        em {
            display: block;
            font-size: 14px;
            color: ${(props) => props.theme.textBase};
        }
    }
    .summary-content-no {
        flex: 1 0 182px;
        font-size: 15px;
        color: ${(props) => props.theme.panelSubLabel};
        line-height: 1.5em;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        cursor: default;
    }
    .summary-label {
        font-size: 13px;
        margin: 0 0 0 auto;
        background-color: #ef1212;
        color: white;
        padding: 5px 10px;
        border-radius: 50vh;
        white-space: nowrap;
    }
`
const WarningLabel = styled.div`
    display: inline-block;
    margin: 0 0 0 auto;
    position: relative;
    font-size: 13px;
    background-color: ${(props) => props.theme.textRed};
    padding: 7px 10px;
    border-radius: 50vh;
    color: white;
    white-space: nowrap;
    cursor: default;
}`