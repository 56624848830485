import { ThunkDispatch } from 'redux-thunk'
import types from './actionTypes'
import { IInverterType } from '../../types/status'

import Cookies from 'universal-cookie/es6'
import instance from '../../api'

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

const SERVICE_PATH_INVERTER = `${API_BASE}/api/inverterMng`
const SERVICE_PATH_JUNCTION = `${API_BASE}/api/junctionMng`
const SERVICE_PATH_NODATA = `${API_BASE}/api/nodataDevice`

export type CreateInverterInfo = {
    type: typeof types.CREATE_INVERTERINFO
    data: IInverterType
}

export type ReadInverterInfo = {
    type: typeof types.READ_INVERTERINFO
    data: IInverterType[]
    page: number
}
export type ReadInverterInfoAll = {
    type: typeof types.READ_INVERTERINFO_ALL
    data: IInverterType[]
}
export type DeleteInverterInfo = {
    type: typeof types.DELETE_INVERTERINFO
    data: IInverterType
}
export type ReadJunctionInfoALl = {
    type: typeof types.READ_JUNCTIONINFO
    data: IInverterType[]
}
export type ReadNodataDevice = {
    type: typeof types.READ_NODATA_DEVICE
    data: IInverterType[]
}

export type Actions =
    | CreateInverterInfo
    | ReadInverterInfo
    | ReadInverterInfoAll
    | DeleteInverterInfo
    | ReadJunctionInfoALl
    | ReadNodataDevice

export const createInverterInfo =
    (data: IInverterType) => async (dispatch: ThunkDispatch<any, any, CreateInverterInfo>) => {
        let res = await instance.post(`${SERVICE_PATH_INVERTER}`, data)
        return res.data
    }

export const readInverterInfo =
    (site_id: string, inverter_id: string) => async (dispatch: ThunkDispatch<any, any, ReadInverterInfo>) => {
        const params = { inverter_id: inverter_id, page: 0, size: 10 }
        let res = await instance(`${SERVICE_PATH_INVERTER}/${btoa(site_id)}`, { params: params })

        dispatch({ type: types.READ_INVERTERINFO, data: res.data.content, page: res.data.total_pages })
    }

export const readInverterInfoAll = () => async (dispatch: ThunkDispatch<any, any, ReadInverterInfoAll>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH_INVERTER}s/${selected_sid}`)
    dispatch({ type: types.READ_INVERTERINFO_ALL, data: res.data })
}

export const updateInverterInfo = (id: number, comment: string, process: string) => async () => {
    const params = { comment: comment, process: process }
    await instance.put(`${SERVICE_PATH_INVERTER}/${id}`, params)
}

export const deleteInverterInfo = (id: Number) => async () => {
    await instance.delete(`${SERVICE_PATH_INVERTER}/${id}`)
}

export const readJunctionInfo =
    (site_id: string, inverter_id: string) => async (dispatch: ThunkDispatch<any, any, ReadInverterInfo>) => {
        const params = { inverter_id: inverter_id, page: 0, size: 10 }
        let res = await instance(`${SERVICE_PATH_JUNCTION}/${btoa(site_id)}`, { params: params })

        dispatch({ type: types.READ_INVERTERINFO, data: res.data.content, page: res.data.total_pages })
    }

export const readJunctionInfoAll = () => async (dispatch: ThunkDispatch<any, any, ReadJunctionInfoALl>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH_JUNCTION}s/${selected_sid}`)
    dispatch({ type: types.READ_JUNCTIONINFO, data: res.data })
}

export const readNodataDevice = () => async (dispatch: ThunkDispatch<any, any, ReadNodataDevice>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH_NODATA}/${selected_sid}`)
    dispatch({ type: types.READ_NODATA_DEVICE, data: res.data })
}
