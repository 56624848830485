import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MaterialTable, { Column } from "material-table"
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ModalSubhome from "../ModalSubhome";
import ReactDOM from "react-dom";
import useTheme from "../../lib/useTheme";


const tableIcons = {
    Add: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:React.Ref<SVGSVGElement>) => <ViewColumn {...props} ref={ref} />)
};

type RowData = {
    id: number
    sid: number
    name: string
    today_accu_pg: string
    state: string
    i: number
    v: number
    p: number
}


interface IProps {
    values: any
    selectDate: string
}
//subhome에서 사용
function ReactTable({ values, selectDate }: IProps) {
    const [theme] = useTheme(); // 화면모드
    const [showData, setShowData] = useState<RowData[]>([])
    const options = {
        search: false,
        showTitle: false,
        paging: false,
        toolbar: true,
        draggable: false,
        exportButton: true
    }
    useEffect(() => {
        if(values.length > 0) {
            const tableData: RowData = values.map((item: any) => {
                const temp: RowData = {
                    id: item.id,
                    sid: item.sid ? Number(item.sid) : 0,
                    name: item.name,
                    today_accu_pg: item.today_accu_pg > 0 ? item.today_accu_pg : '-',
                    state: item.state,
                    i: item.input_i > 0 ? item.input_i : '-',
                    v: item.input_v > 0 ? item.input_v : '-',
                    p: item.input_p > 0 ? item.input_p : '-'
                }
                return temp
            })
            // @ts-ignore
            setShowData(tableData)
            //console.log("here : ", tableData)
        }
    },[values]);


    const columns = [
        { title: '이름', field: 'name' },
        { title: '발전량(kWh)', field: 'today_accu_pg' },
        { title: '전압(V)', field: 'v' },
        { title: '전류(A)', field: 'i' },
        { title: '전력(kW)', field: 'p' },
    ] as Column<RowData>[]

    const handleRowClick = (event:any, rowData:any) => {
        // @ts-ignore
        const element1 = <ModalSubhome selectId={rowData.id} selectDate={selectDate}></ModalSubhome>
        // @ts-ignore
        document.getElementById('diagramShowModal').style.display = "block";
        ReactDOM.render(
            element1,
            document.getElementById('diagramShowModal')
        );
    }

    return (
        <ReactTableContainer themeMode={theme}>
            <MaterialTable
                columns={columns}
                data={showData}
                icons={tableIcons}
                options={options}
                parentChildData={(row, rows) => rows.find(a => Number(a.id) === row.sid && Number(a.id) > 0)}
                onRowClick={handleRowClick}
            />
        </ReactTableContainer>
    )
}

export default ReactTable
const ReactTableContainer = styled.div<{themeMode:string}>`
   .MuiPaper-elevation2 {
    box-shadow: none;
   }
   .MuiPopover-root {
     z-index: 9999;
   }
   .MuiPaper-root {
     background-color: ${(props) => props.themeMode==='light'?'white':'#212429'};
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};
   }
   .MuiTableCell-root {
     background-color: ${(props) => props.themeMode==='light'?'white':'#212429'};
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};
   }
   .MuiSvgIcon-root {
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};   
   }
`