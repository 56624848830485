import { ThunkDispatch } from 'redux-thunk'
import types from './actionTypes'

import Cookies from 'universal-cookie/es6'
import instance from '../../api'

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

const SERVICE_PATH = `${API_BASE}/api`

export type SubhomeRead = {
    type: typeof types.SUBHOME_READ
    data: any
}

export type SubhomeDevice = {
    type: typeof types.SUBHOME_DEVICE
    data: any
}

export type SubhomeChart = {
    type: typeof types.SUBHOME_CHART
    data: any
}

export type Actions = SubhomeRead | SubhomeDevice | SubhomeChart

export const subhomeRead = (date: string) => async (dispatch: ThunkDispatch<any, any, SubhomeRead>) => {
    const params = { selectDate: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid_single')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH}/subhome/${selected_sid}`, { params: params })
    dispatch({ type: types.SUBHOME_READ, data: res.data })
}

export const subhomeDevice = (date: string) => async (dispatch: ThunkDispatch<any, any, SubhomeDevice>) => {
    const params = { selectDate: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid_single')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH}/subhomeDevice/${selected_sid}`, { params: params })
    dispatch({
        type: types.SUBHOME_DEVICE,
        data: res.data.sort((a: any, b: any) => (Number.parseInt(a.id) > Number.parseInt(b.id) ? 1 : -1)),
    })
}

export const subhomeChart =
    (date: string, paramType: string) => async (dispatch: ThunkDispatch<any, any, SubhomeChart>) => {
        const params = { selectDate: date, type: paramType }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return
        const res = await instance(`${SERVICE_PATH}/subhomeChart/${selected_sid}`, { params: params })
        dispatch({ type: types.SUBHOME_CHART, data: res.data })
    }
