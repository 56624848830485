import React, {useEffect, useState} from 'react'
import styled from 'styled-components';

import SettingPanel from "../../components/SettingPanel/SettingPanel";
import {IMonthlyEarnType, IMonthlyRentalType, ISettlementType} from "../../types/operation";
import ModalRental from "../../components/ModalRental";
import ModalEarn from "../../components/ModalEarn";

import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/reducers";
import ModalPrice from "../../components/ModalPrice";
import {mySids, settlementBillYn, settlementCrossYn, settlementState} from "../../store/actions/operation";

interface IProps {
    values: ISettlementType[]
    rentalItems: IMonthlyRentalType[]
    earnItems: IMonthlyEarnType[]
    selectSiteId: string
    selectDate: string
}

function RevenueDetailsCard({values, rentalItems, selectSiteId, selectDate, earnItems }: IProps) {
    const [isModalRentalOpen, setIsModalRentalOpen] = useState(false)
    const [isModalEarnOpen, setIsModalEarnOpen] = useState(false)
    const [isModalPriceOpen, setIsModalPriceOpen] = useState(false)
    const [init, setInit] = useState(false)
    const [selectSettlement, setSelectSettlement] = useState<ISettlementType | null>()
    const [earnName, setEarnName] = useState('')
    const dispatch = useDispatch();


    const deviceIndex: any = useSelector((state:State) => state.devices);

    const onModalMonthlyRental = () =>{
        setIsModalRentalOpen(true)
        setInit(true)
    }

    const onModalMonthlyEarn = (name:string) =>{
        setEarnName(name);
        setIsModalEarnOpen(true)
        setInit(true)
    }

    const onModalPrice = () =>{
        setIsModalPriceOpen(true)
        setInit(true)
    }

    /*const updateCrossYn = (yn:boolean) =>{
        if(selectSettlement){
            if(yn){
                selectSettlement.cross_yn = 'Y'
            }else{
                selectSettlement.cross_yn = 'N'
            }
            dispatch(settlementCrossYn(selectSettlement))
        }
    }

    const updateBillYn = (yn:boolean) =>{
        if(selectSettlement){
            if(yn) {
                selectSettlement.bill_yn = 'Y'
            }else{
                selectSettlement.bill_yn = 'N'
            }

            dispatch(settlementBillYn(selectSettlement))
        }
    }*/

    const updateState = (state:string) =>{
        if(selectSettlement){
            selectSettlement.state = state;
            dispatch(settlementState(selectSettlement))
        }
    }

    useEffect(() => {
        if(values.length > 0) {
            setSelectSettlement(values.find((item) => item.site_id === selectSiteId))
        }else{
            setSelectSettlement(null)
        }
    },[values, selectSiteId]);

    return (
        <>
            {selectSettlement && <Detail>
                <SettingPanel title='정산금액' mainClass='cost' bgIcon='icon-ic_1' type='A2' settlementData={selectSettlement} selectSiteId={selectSiteId} onClickMethod={()=>onModalPrice()}/>
                <SettingPanel title='SMP' mainClass='rent' bgIcon='icon-ic_smp' type='A3' earnData={earnItems} selectSiteId={selectSiteId} onClickMethod={()=>onModalMonthlyEarn('SMP')}/>
                <SettingPanel title='REC' mainClass='rent' bgIcon='icon-ic_rec' type='A3' earnData={earnItems} selectSiteId={selectSiteId} onClickMethod={()=>onModalMonthlyEarn('REC')}/>
                <SettingPanel title='지출' mainClass='rent' bgIcon='icon-ic_2' type='A1' tableData={rentalItems} selectSiteId={selectSiteId} onClickMethod={()=>onModalMonthlyRental()}/>
                <SettingPanel title='상태변경' mainClass='status' bgIcon='icon-ic_5' type='D' settlementData={selectSettlement} onClickMethod={(state:string)=>updateState(state)} />

                {isModalEarnOpen && <ModalEarn values={deviceIndex.monthlyEarnData} day={selectDate} modalVisible={isModalEarnOpen} setModalVisible={setIsModalEarnOpen} init={init} setInit={setInit} selectSiteId={selectSiteId} selectName={earnName}></ModalEarn>}
                {isModalRentalOpen && <ModalRental values={deviceIndex.monthlyRentalData} day={selectDate} modalVisible={isModalRentalOpen} setModalVisible={setIsModalRentalOpen} init={init} setInit={setInit} selectSiteId={selectSiteId}></ModalRental>}
                {isModalPriceOpen && <ModalPrice valueData={selectSettlement} modalVisible={isModalPriceOpen} setModalVisible={setIsModalPriceOpen} init={init} setInit={setInit} ></ModalPrice>}

            </Detail>}
        </>
    );
}
export default RevenueDetailsCard

const Detail = styled.div`
  position: relative;
  grid-area: detail;
  @media (max-width: 1440px) {
    transition: all 0.3s;
    visibility: 'visible';
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 450px;
    height: 100vh;
    overflow-y: auto;
    padding: 60px 20px 20px 20px;
    background-color: ${(props) => props.theme.settlementContainerBg};
  }
`

/*{selectSettlement && <SettingPanel title='크로스체크' mainClass='crosscheck' bgIcon='icon-ic_3' type='B' chkValue={selectSettlement.cross_yn} onClickMethod={(yn:string)=>updateCrossYn(yn)} />}
                {selectSettlement && <SettingPanel title='세금계산서' mainClass='tax' bgIcon='icon-ic_4' type='B' radioStr={selectSettlement.bill_date!==''?'요청일 : 매달'+ selectSettlement.bill_date+'일':'요청일 미입력'} chkValue={selectSettlement.bill_yn} onClickMethod={(yn:string)=>updateBillYn(yn)}/>}
                {selectSettlement && <SettingPanel title='상태변경' mainClass='status' bgIcon='icon-ic_5' type='C'  chkValue={selectSettlement.state} onClickMethod={(state:string)=>updateState(state)} />}*/