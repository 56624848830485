import React, {useEffect, useState} from 'react'
import styled from 'styled-components';

import MonthProfitIcon from '../../assets/img/img_1.svg'
import { ISettlementType } from "../../types/operation";
import {
    numberWithCommasNumberType,
} from "../../lib/asyncUtils";

interface IProps {
    values: ISettlementType[]
    selectSiteId: string
    setSelectSiteId?: any
}

const mappingStatusToName = (state:string) => {
    if(state ==='I') return '처리중'
    if(state ==='C') return '크로스체크 완료'
    if(state ==='B') return '세금계산서 발행'
    if(state ==='D') return '정산 완료'
    if(state ==='H') return '보류'
}

function RevenuePanels({ values, selectSiteId, setSelectSiteId }: IProps) {
    const [filter, setFilter] = useState('A')
    const [icnt, setIcnt] = useState(0)
    //const [ccnt, setCcnt] = useState(0)
    //const [bcnt, setBcnt] = useState(0)
    const [dcnt, setDcnt] = useState(0)
    const [hcnt, setHcnt] = useState(0)

    const [smp, setSmp] = useState(0)
    const [rec, setRec] = useState(0)
    //const [runPrice, setRunPrice] = useState(0)
    const [rental, setRental] = useState(0)
    const [total, setTotal] = useState(0)

    const handleFilter = (data:string) =>{
        setFilter(data)
    }

    const calAndChk = () =>{
        let i=0,c=0,b=0,d=0,h=0
        let smp=0,rec=0,run=0,rental=0,total=0
        values.map((item) => {
            if(item.state==='I'){
                i+=1
            }else if(item.state==='C'){
                c+=1
            }else if(item.state==='B'){
                b+=1
            }else if(item.state==='D'){
                d+=1
            }else if(item.state==='H'){
                h+=1
            }

            //계산
            smp += item.smp
            rec += item.rec
            //run += item.run_price
            rental += item.rental
            total += item.total
        })
        setIcnt(i)
        //setCcnt(c)
        //setBcnt(b)
        setDcnt(d)
        setHcnt(h)

        setSmp(smp)
        setRec(rec)
        //setRunPrice(run)
        setRental(rental)
        setTotal(total)
    }


    const handleChange = (settlement:ISettlementType) =>{
        setSelectSiteId(settlement.site_id)
        selectSiteId = settlement.site_id
    }

    //계산, 필터
    useEffect(() => {
        calAndChk()
        if(values.length > 0){
            if(selectSiteId==='0'){
                setSelectSiteId(values[0].site_id)
            }
        }
    }, [values])

    return (
        <>
            <TotalCost >
                <div className='title'>이달의 수익</div>
                <div className='col'>
                    <div className='label'>SMP</div>
                    <div className='data'>{smp!==0?numberWithCommasNumberType(smp)+'원':'-'}</div>
                </div>
                <div className='col'>
                    <div className='label'>REC</div>
                    <div className='data'>{rec!==0?numberWithCommasNumberType(rec)+'원':'-'}</div>
                </div>
                <div className='col rental'>
                    <div className='label'>지출</div>
                    <div className='data'>{rental!==0?'- '+numberWithCommasNumberType(rental)+'원':'-'}</div>
                </div>
                <div className='col total'>
                    <div className='label'>합계</div>
                    <div className='data'>{total!==0?numberWithCommasNumberType(total)+'원':'-'}</div>
                </div>
            </TotalCost>

            <SettlementContainer>
                <TableSort>
                    <div className='radio-container'>
                        <input
                            type='radio'
                            id='all'
                            name='tableSort'
                            value=''
                            defaultChecked
                        />
                        <label htmlFor='all' className='all' onClick={()=>handleFilter('A')}>
                            전체 <span>{values.length}</span>
                        </label>

                        <input type='radio' id='wip' name='tableSort' value='' onClick={()=>handleFilter('I')}/>
                        <label htmlFor='wip' className='wip'>
                            처리중 <span>{icnt}</span>
                        </label>

                        {/*<input
                            type='radio'
                            id='crossCheckComplete'
                            name='tableSort'
                            value=''
                        />
                        <label htmlFor='crossCheckComplete' className='crossCheckComplete' onClick={()=>handleFilter('C')}>
                            크로스체크 완료 <span>{ccnt}</span>
                        </label>

                        <input type='radio' id='taxReceipt' name='tableSort' value=''  onClick={()=>handleFilter('B')}/>
                        <label htmlFor='taxReceipt' className='taxReceipt'>
                            세금계산서 발행 <span>{bcnt}</span>
                        </label>*/}

                        <input type='radio' id='complete' name='tableSort' value=''  onClick={()=>handleFilter('D')}/>
                        <label htmlFor='complete' className='complete'>
                            정산 완료 <span>{dcnt}</span>
                        </label>

                        <input type='radio' id='postpone' name='tableSort' value=''  onClick={()=>handleFilter('H')}/>
                        <label htmlFor='postpone' className='postpone'>
                            보류 <span>{hcnt}</span>
                        </label>
                    </div>
                </TableSort>
                <TableContainer>
                    <table className='settlement-table'>
                        <thead>
                        <tr>
                            <th className='name'>발전소명</th>
                            <th>SMP</th>
                            <th>REC</th>
                            <th>지출</th>
                            <th className='total'>합계</th>
                            <th className='status'>상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {values.map((item, index) => {
                            if(filter === 'A'){
                                return (
                                    <tr key={item.id} className={(selectSiteId === item.site_id) || (selectSiteId==='0' && index===0) ? 'active' : ''} onClick={()=>handleChange(item)}>
                                        <td className='name'>
                                            <div className='name-data'>{item.name}</div>
                                        </td>
                                        <td className='smp'>
                                            <div className='smp-data'>{item.smp!==0?numberWithCommasNumberType(item.smp)+'원':'-'}</div>
                                        </td>
                                        <td className='rec'>{item.rec!==0?numberWithCommasNumberType(item.rec)+'원':'-'}</td>
                                        <td className='rent'>{item.rental!==0?numberWithCommasNumberType(item.rental)+'원':'-'}</td>
                                        <td className='total'>{item.total!==0?numberWithCommasNumberType(item.total)+'원':'-'}</td>
                                        {/*<td className='status'>
                                            {item.cross_yn === 'Y' && <span className='status C'>크로스체크</span>}
                                            {item.bill_yn === 'Y' && <span className='status B'>세금계산서</span>}
                                        </td>*/}
                                        <td className={'status '+item.state}>
                                            {mappingStatusToName(item.state)}
                                        </td>
                                    </tr>
                                )
                            }else{
                                return (item.state===filter &&
                                    <tr key={item.id} className={(selectSiteId === item.site_id) || (selectSiteId==='0' && index===0) ? 'active' : ''} onClick={()=>handleChange(item)}>
                                        <td className='name'>
                                            <div className='name-data'>{item.name}</div>
                                        </td>
                                        <td className='smp'>
                                            <div className='smp-data'>{item.smp!==0?numberWithCommasNumberType(item.smp)+'원':'-'}</div>
                                        </td>
                                        <td className='rec'>{item.rec!==0?numberWithCommasNumberType(item.rec)+'원':'-'}</td>
                                        <td className='rent'>{item.rental!==0?numberWithCommasNumberType(item.rental)+'원':'-'}</td>
                                        <td className='total'>{item.total!==0?numberWithCommasNumberType(item.total)+'원':'-'}</td>
                                        {/*<td className='status'>
                                            {item.cross_yn === 'Y' && <span className='status C'>크로스체크</span>}
                                            {item.bill_yn === 'Y' && <span className='status B'>세금계산서</span>}
                                        </td>*/}
                                        <td className={'status '+item.state}>{mappingStatusToName(item.state)}</td>
                                    </tr>
                                )
                            }
                        })}
                        </tbody>
                    </table>
                </TableContainer>
            </SettlementContainer>
        </>
    )
}

export default RevenuePanels


const SettlementContainer = styled.div`
  grid-area: settlementContainer;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
`

const TotalCost = styled.div`
  grid-area: totalCost;
  background-color: ${(props) => props.theme.panel};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 20px 40px;
  border-radius: 8px;
  white-space: nowrap;
 
  .title {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textBase};
    font-size: 24px;
    padding-right: 40px;
    grid-column: 1/-1;
    margin-bottom: 30px;
    &::before {
      content: ${(props) => `url(${MonthProfitIcon})`};
      font-family: 'icomoon';
      color: #cbd4dc;
      font-size: 30px;
      margin-right: 10px;
      vertical-align: -6px;
    }
  }
  .col {
    &:nth-child(2),
    &:nth-child(3) {
    }
    @media (max-width: 1440px) {
      justify-self: start;
      padding-left: 0;
    }
    &.total {
      justify-self: end;
      padding: 0;
      .data {
        color: ${(props) => props.theme.active};
      }
    }
    &.rental {
      .data {
        color: ${(props) => props.theme.textOrange};
      }
    }
    .label {
      font-size: 14px;
      color: ${(props) => props.theme.textBase2};
      padding-bottom: 10px;
    }
    .data {
      color: ${(props) => props.theme.textBase};
      font-size: 20px;
    }
  }
`

const TableSort = styled.div`
  .radio-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    border: 1px solid ${(props) => props.theme.chartSortBorderColor};
    border-radius: 6px;
    overflow: hidden;
    background-color: ${(props) => props.theme.chartSortRadioBg};
    @media (max-width: 1440px) {
      display: grid;
      grid-template-columns: repeat(3, auto);
    }
    input[type='radio'] {
      display: none;
      & + label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: ${(props) => props.theme.chartSortRadioText};
        padding: 11px 30px 12px 30px;
        cursor: pointer;
        white-space: nowrap;
        span {
          margin-left: 10px;
        }
        &.all {
          color: ${(props) => props.theme.textBase};
          border-right: 1px solid ${(props) => props.theme.settlementSortBorder};
          @media (max-width: 1440px) {
            border-bottom: 1px solid
              ${(props) => props.theme.settlementSortBorder};
          }
        }
        &.wip {
          color: ${(props) => props.theme.textBase2};
          border-right: 1px solid ${(props) => props.theme.settlementSortBorder};
          @media (max-width: 1440px) {
            border-bottom: 1px solid
              ${(props) => props.theme.settlementSortBorder};
          }
        }
        &.crossCheckComplete {
          color: ${(props) => props.theme.textGreen};
          border-right: 1px solid ${(props) => props.theme.settlementSortBorder};
          @media (max-width: 1440px) {
            border-bottom: 1px solid
              ${(props) => props.theme.settlementSortBorder};
            border-right: 0;
          }
        }
        &.taxReceipt {
          color: ${(props) => props.theme.textOrange};
          border-right: 1px solid ${(props) => props.theme.settlementSortBorder};
        }
        &.complete {
          color: ${(props) => props.theme.textPurple};
          border-right: 1px solid ${(props) => props.theme.settlementSortBorder};
          border-bottom: 1px solid ${(props) => props.theme.settlementSortBorder};
        }
        &.postpone {
          color: ${(props) => props.theme.textRed};
        }
      }
      &:checked {
        & + label {
          &.all {
            background-color: ${(props) => props.theme.textBlue};
            color: white;
          }
          &.wip {
            background-color: ${(props) => props.theme.textBase2};
            color: white;
          }
          &.crossCheckComplete {
            background-color: ${(props) => props.theme.textGreen};
            color: white;
          }
          &.taxReceipt {
            background-color: ${(props) => props.theme.textOrange};
            color: white;
          }
          &.complete {
            background-color: ${(props) => props.theme.textPurple};
            color: white;
          }
          &.postpone {
            background-color: ${(props) => props.theme.textRed};
            color: white;
          }
        }
      }
    }
  }
`

const TableContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  .settlement-table {
    border-radius: 8px;
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    text-align: center;
    background-color: ${(props) => props.theme.chartSortRadioBg};
    white-space: nowrap;
    thead {
      color: white;
      background-color: ${(props) => props.theme.settlementTableHeader};
    }
    tr {
      border-bottom: 1px solid ${(props) => props.theme.line};
      cursor: default;
    }
    th,
    td {
      padding: 20px 10px;
      line-height: 1.3em;
      vertical-align: middle;
    }
    th {
      &.name {
        width: 150px;
      }
      &.total {
        @media (max-width: 1440px) {
          display: none;
        }
      }
      &.vat {
        @media (max-width: 1440px) {
          display: none;
        }
      }
      &.status {
        @media (max-width: 1440px) {
          display: none;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover,
         &.active{
          background-color: ${(props) => props.theme.activeTable};
        }
      }
    }
    td {
      text-align: right;
      color: ${(props) => props.theme.textBase};
      &.name {
        white-space: normal;
        .name-data {
          text-align: left;
          width: 100%;
        }
      }
      &.total {
        color: ${(props) => props.theme.titleBlue};
        @media (max-width: 1440px) {
          display: none;
        }
      }
      &.vat {
        @media (max-width: 1440px) {
          display: none;
        }
      }
      &.status {
        text-align: center;
        white-space: normal;
        @media (max-width: 1440px) {
          display: none;
        }
        
          &.I {
            color: ${(props) => props.theme.textBase2};
          }
          &.C {
            color: ${(props) => props.theme.textGreen};
          }
          &.B {
            color: ${(props) => props.theme.textOrange};
          }
          &.D {
            color: ${(props) => props.theme.textPurple};
          }
          &.H {
            color: ${(props) => props.theme.textRed};
          }
        
      }
    }
    
      .C {
        color: ${(props) => props.theme.textGreen};
        display: block;
      }
      .B {
        color: ${(props) => props.theme.textOrange};
        display: block;
      }
      
  }
`
