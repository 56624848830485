import React from 'react'
import { NumberInputContainer } from './NumberInput.style'
import NumberFormat from "react-number-format";

interface IProps {
    name: string
    value: string | number
    handleChangeInput: any
    suffix: string
    disabled?: boolean
    decimalScale?: number
}
function NumberInput({ name, value, handleChangeInput, suffix, disabled, decimalScale }: IProps) {
    return (
        <NumberInputContainer>
            <NumberFormat
                value={value}
                thousandSeparator={true}
                prefix=""
                className="nf-input"
                inputMode="numeric"
                suffix={suffix}
                decimalScale={decimalScale===undefined?0:decimalScale}
                onValueChange={(values) => {
                    const { value } = values
                    return handleChangeInput(name, value)
                }}
                disabled={disabled}
            />
        </NumberInputContainer>
    )
}

export default NumberInput
