import React, {useEffect, useRef, useState} from 'react'
import { InnerContainer } from './Revenue.style'
import type { Moment } from 'moment';
import moment from 'moment';
import TopBar from "../../components/TopBar/TopBar";
import RevenuePanels from "../../templates/Revenue/RevenuePanels";
import RevenueDetailsCard from "../../templates/Revenue/RevenueDetailsCard";
import {
    monthlyEarnRead,
    monthlyRentalRead,
    mySids,
    settlementRead
} from "../../store/actions/operation";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/reducers";
// @ts-ignore
import {useAnalytics} from "../../lib/googleAnalytics";

function RevenuePresenter() {
    useAnalytics('Revenue')
    const [value, setValue] = useState<Moment>(moment(new Date).add(-1,'M'));
    const dispatch = useDispatch();
    const deviceIndex: any = useSelector((state:State) => state.devices);

    const [selectSiteId, setSelectSiteId] = useState('0')
    const [load, setLoad] = useState(true)

    useEffect(() => {
        if(deviceIndex.mySids.length <= 0){
            dispatch(mySids())
        }
        if(load) {
            //정산 데이터 호출
            dispatch(settlementRead(value?.format('yyyy-MM')))

            setLoad(false)
        }

        //월별 SMP, REC
        dispatch(monthlyEarnRead(selectSiteId, value?.format('yyyy-MM')))

        //월별 장비임대비
        dispatch(monthlyRentalRead(selectSiteId, value?.format('yyyy-MM')))

    }, [selectSiteId, value, ])

    const initPage = () =>{

        //정산 데이터 호출
        dispatch(settlementRead(value?.format('yyyy-MM')))

        if(deviceIndex.settlementData.length > 0){
            setSelectSiteId(deviceIndex.settlementData[0].site_id)
        }

        //월별 SMP, REC
        dispatch(monthlyEarnRead(selectSiteId, value?.format('yyyy-MM')))

        //월별 장비임대비
        dispatch(monthlyRentalRead(selectSiteId, value?.format('yyyy-MM')))

    }

    const selectDateParent = (newValue: Moment) => {
        setValue(newValue);
        //정산 데이터 호출
        dispatch(settlementRead(newValue?.format('yyyy-MM')))
    }

    return (
        <>
            <TopBar calendar={true} selectDate={value?.format('yyyy-MM')} type={"D"} selectDateValue={selectDateParent}  timerOff={true} initPage={initPage}  allSid={deviceIndex.mySids}/>
            <InnerContainer>
                <RevenuePanels values={deviceIndex.settlementData} selectSiteId={selectSiteId} setSelectSiteId={setSelectSiteId} />
                <RevenueDetailsCard values={deviceIndex.settlementData} rentalItems={deviceIndex.monthlyRentalData} earnItems={deviceIndex.monthlyEarnData} selectSiteId={selectSiteId} selectDate={value?.format('yyyy-MM')}/>
            </InnerContainer>
        </>
    )
}

export default RevenuePresenter
