import React, { useEffect, useState } from "react";

import Marquee from "react-fast-marquee";
import { IDevicePgType, ISiteInfo } from "../../types/operation";
import { PgTimeContainer } from "./PgTimeBar.style";

interface IProps {
    siteInfo: ISiteInfo[]
    deviceInfo: IDevicePgType[]
}

interface IPgData {
    siteName: string
    pgTime: number
    pgPercent: number
}

function PgTimeBar({siteInfo, deviceInfo}: IProps) {
    const [pgTimeData, setPgTimeData] = useState<IPgData[]>([])

    useEffect(() => {
        let data: IPgData[] = []

        let siteIdList:string[] = []

        deviceInfo.forEach((arr, index, callback) => {
            if (index === callback.findIndex(t => t.site_id === arr.site_id)){
                siteIdList.push(callback[index].site_id)
            }
        });

        siteInfo.filter((item) => siteIdList.includes(item.site_id)).forEach((site: ISiteInfo) => {
            let todayPg: number = 0
            let todayPgPercent: number = 0
            
            deviceInfo.forEach((pg: IDevicePgType) => {
                if (site.site_id == pg.site_id) {
                    todayPg += pg.today_accu_pg
                    todayPgPercent += pg.in_p
                }
            })

            let timeData: IPgData = {
                siteName: site.name,
                pgTime: Math.round(todayPg / Number.parseFloat(site.capacity) * 10) / 10.0,
                pgPercent: Math.round(todayPgPercent / (Number.parseFloat(site.capacity) * 0.7) * 1000) / 10.0
            }

            data.push(timeData)
        })

        setPgTimeData(data)
    }, [siteInfo, deviceInfo])

    return (
        <PgTimeContainer gridArea="pgTime">
            <span className="title">발전시간 ( 가동률 )</span>
            <Marquee className="marqueeText" gradient={false} speed={50}>
                {
                    pgTimeData.length !== 0
                    ? pgTimeData.map((item: IPgData, key: number) => (
                        <span className="text" key={key}>{item.siteName} : <span >{item.pgTime}</span> 시간 ( <span >{item.pgPercent}</span> % )</span>
                    ))
                    : <span className="text">로딩중...</span>
                }
            </Marquee>
        </PgTimeContainer>
    )
}

export default PgTimeBar