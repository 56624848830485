import Icon1 from 'assets/img/ico_1.svg'
import Icon1Active from 'assets/img/ico_1_blue.svg'
import Icon1Dark from 'assets/img/ico_1_dark.svg'
import Icon1DarkActive from 'assets/img/ico_1_dark_active.svg'

import Icon2 from 'assets/img/ico_2.svg'
import Icon2Active from 'assets/img/ico_2_blue.svg'
import Icon2Dark from 'assets/img/ico_2_dark.svg'
import Icon2DarkActive from 'assets/img/ico_2_dark_active.svg'

import Icon3 from 'assets/img/ico_3.svg'
import Icon3Active from 'assets/img/ico_3_blue.svg'
import Icon3Dark from 'assets/img/ico_3_dark.svg'
import Icon3DarkActive from 'assets/img/ico_3_dark_active.svg'

import Icon4 from 'assets/img/ico_4.svg'
import Icon4Active from 'assets/img/ico_4_blue.svg'
import Icon4Dark from 'assets/img/ico_4_dark.svg'
import Icon4DarkActive from 'assets/img/ico_4_dark_active.svg'

import Icon5 from 'assets/img/ico_5.svg'
import Icon5Active from 'assets/img/ico_5_blue.svg'
import Icon5Dark from 'assets/img/ico_5_dark.svg'
import Icon5DarkActive from 'assets/img/ico_5_dark_active.svg'

import Icon6 from 'assets/img/ico_6.svg'
import Icon6Active from 'assets/img/ico_6_blue.svg'
import Icon6Dark from 'assets/img/ico_6_dark.svg'
import Icon6DarkActive from 'assets/img/ico_6_dark_active.svg'

import Icon9 from 'assets/img/ico_9.svg'
import Icon9Active from 'assets/img/ico_9_blue.svg'
import Icon9Dark from 'assets/img/ico_9_dark.svg'
import Icon9DarkActive from 'assets/img/ico_9_dark_active.svg'

import HomeContainer from 'pages/Home/HomeContainer'
import ReportContainer from 'pages/Report/ReportContainer'
import StatusContainer from 'pages/Status/StatusContainer'
import RevenueContainer from 'pages/Revenue/RevenueContainer'
import SubhomeContainer from 'pages/Subhome/SubhomeContainer'
import EstimateContainer from 'pages/Estimate/EstimateContainer'
import SettingsContainer from 'pages/Settings/SettingsContainer'

export interface Routes {
    name: string
    component: React.ComponentType<any>
    path: string
    icon: {
        basic: string
        active: string
    }
}

export const ROUTES: Routes[] = [
    {
        name: 'HOME',
        component: HomeContainer,
        path: '/home',
        icon: {
            basic: Icon1,
            active: Icon1Active,
        },
    },
    {
        name: '예상수익',
        component: EstimateContainer,
        path: '/estimate',
        icon: {
            basic: Icon2,
            active: Icon2Active,
        },
    },
    {
        name: '세부내역',
        component: SubhomeContainer,
        path: '/subhome',
        icon: {
            basic: Icon9,
            active: Icon9Active,
        },
    },
    {
        name: '운영상태',
        component: StatusContainer,
        path: '/status',
        icon: {
            basic: Icon3,
            active: Icon3Active,
        },
    },
    {
        name: '정산관리',
        component: RevenueContainer,
        path: '/revenue',
        icon: {
            basic: Icon4,
            active: Icon4Active,
        },
    },
    {
        name: '리포트',
        component: ReportContainer,
        path: '/report',
        icon: {
            basic: Icon5,
            active: Icon5Active,
        },
    },
    {
        name: '설정',
        component: SettingsContainer,
        path: '/settings',
        icon: {
            basic: Icon6,
            active: Icon6Active,
        },
    },
]

export const ROUTES_DARK: Routes[] = [
    {
        name: 'HOME',
        component: HomeContainer,
        path: '/home',
        icon: {
            basic: Icon1Dark,
            active: Icon1DarkActive,
        },
    },
    {
        name: '예상수익',
        component: EstimateContainer,
        path: '/estimate',
        icon: {
            basic: Icon2Dark,
            active: Icon2DarkActive,
        },
    },
    {
        name: '세부내역',
        component: SubhomeContainer,
        path: '/subhome',
        icon: {
            basic: Icon9Dark,
            active: Icon9DarkActive,
        },
    },
    {
        name: '운영상태',
        component: StatusContainer,
        path: '/status',
        icon: {
            basic: Icon3Dark,
            active: Icon3DarkActive,
        },
    },
    {
        name: '정산관리',
        component: RevenueContainer,
        path: '/revenue',
        icon: {
            basic: Icon4Dark,
            active: Icon4DarkActive,
        },
    },
    {
        name: '리포트',
        component: ReportContainer,
        path: '/report',
        icon: {
            basic: Icon5Dark,
            active: Icon5DarkActive,
        },
    },
    {
        name: '설정',
        component: SettingsContainer,
        path: '/settings',
        icon: {
            basic: Icon6Dark,
            active: Icon6DarkActive,
        },
    },
]
