import React, {useEffect, useState} from 'react'
import styled from 'styled-components';

import SettingPanel from "../../components/SettingPanel/SettingPanel";
import useTheme from "../../lib/useTheme";
import ModalRental from "../../components/ModalRental";
import {IContractType, IRentalType} from "../../types/operation";
import {useSelector} from "react-redux";
import {State} from "../../store/reducers";

interface IProps {
    values: IContractType[]
    rentalItems: IRentalType[]
    selectSiteId: string
}

function SettingDetailsCard({ values, rentalItems, selectSiteId }: IProps) {
    const [theme, onToggle] = useTheme();
    const [isModalRentalOpen, setIsModalRentalOpen] = useState(false)
    const [init, setInit] = useState(false)

    const [cross, setCross] = useState('N')
    const [email, setEmail] = useState('')
    const [bill, setBill] = useState('N')
    const [billDate, setBillDate] = useState('')

    const deviceIndex: any = useSelector((state:State) => state.devices);

    const selectShowItem = (site_id:string)=>{
        values.map((contract:IContractType, index:number)=>{
            if(site_id==='0' && index===0){
                setCross(contract.cross_yn)
                setEmail(contract.email)
                setBill(contract.bill_yn)
                setBillDate(contract.bill_date)
            }else if(contract.site_id === site_id){
                setCross(contract.cross_yn)
                setEmail(contract.email)
                setBill(contract.bill_yn)
                setBillDate(contract.bill_date)
            }
        })
    }

    useEffect(() => {
        if(values.length>0){
            selectShowItem(selectSiteId)
        }

    }, [values, rentalItems, selectSiteId, cross, email, bill, billDate])

    const onModalRental = () =>{
        setIsModalRentalOpen(true)
        setInit(true)
    }

    return (
        <>
            <Detail>
                <SettingPanel title='월별 고정지출' mainClass='rent' bgIcon='icon-ic_2' type='A' tableData={rentalItems} selectSiteId={selectSiteId} onClickMethod={()=>onModalRental()}/>
                {/*<SettingPanel title='크로스체크' mainClass='crosscheck' bgIcon='icon-ic_3' type='B1' radioStr={email!==''?'메일 입력':'메일 미입력'} chkValue={cross} leftStr='연동' rightStr='미연동'/>
                <SettingPanel title='세금계산서' mainClass='tax' bgIcon='icon-ic_4' type='B1' radioStr={billDate!==''?'요청일 : 매달'+ billDate+'일':'요청일 미입력'} chkValue={bill} leftStr='연동' rightStr='미연동'/>
                */}
                <SettingPanel title='테마설정' mainClass='theme' bgIcon='icon-ic_6' type='B' radioStr='' onClickMethod={onToggle} leftStr='라이트모드' rightStr='다크모드' chkValue={theme==='light'?'Y':'N'}/>

                {isModalRentalOpen && <ModalRental values={deviceIndex.rentalData} day={''} modalVisible={isModalRentalOpen} setModalVisible={setIsModalRentalOpen} init={init} setInit={setInit} selectSiteId={selectSiteId}></ModalRental>}
            </Detail>
        </>
    )
}
export default SettingDetailsCard

const Detail = styled.div`
  position: relative;
  /* background-color: gainsboro; */
  grid-area: detail;
  @media (max-width: 1440px) {
    /* display: none; */
    transition: all 0.3s;
    visibility: 'visible';
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 450px;
    height: 100vh;
    overflow-y: auto;
    padding: 60px 20px 20px 20px;
    background-color: ${(props) => props.theme.settlementContainerBg};
  }
`
