import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useRef } from 'react'
import useTheme from "../../lib/useTheme";


interface IProps {
    chartId: string
    chartData: any[]
}
//인버터, 접속반, 스트링
function ShModalChart({ chartId, chartData }: IProps) {
    const [theme] = useTheme(); // 화면모드
    let useData:any[] = chartData

    am4core.useTheme(am4themes_animated)
    
    const chartRef = useRef<am4charts.XYChart | null>(null)

    //useLayoutEffect(() => {
    useEffect(() => {
        const chart = am4core.create(chartId, am4charts.XYChart)
        chart.data = useData
        chart.zoomOutButton.disabled = true
        chart.numberFormatter.numberFormat = "#,###.#"
        chart.paddingLeft = 30

        let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        dateAxis.renderer.grid.template.strokeOpacity = 0
        dateAxis.renderer.labels.template.fillOpacity = 0.5
        dateAxis.renderer.labels.template.fontSize = 12
        dateAxis.renderer.minGridDistance = 150
        dateAxis.cursorTooltipEnabled = false
        dateAxis.dataFields.category = "date"

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.labels.template.fillOpacity = 0.5
        valueAxis.renderer.minGridDistance = 50
        valueAxis.cursorTooltipEnabled = false
        valueAxis.fontSize = 10

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "v";
        series.dataFields.categoryX = "date";
        series.name = "전압";

        var series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "i";
        series1.dataFields.categoryX = "date";
        series1.name = "전류";

        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "p";
        series2.dataFields.categoryX = "date";
        series2.name = "전력";
        
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.text = "{name}";


        //다크모드
        if(theme==='dark') {
            dateAxis.renderer.grid.template.stroke = am4core.color('#fff')
            dateAxis.renderer.labels.template.fill = am4core.color('#fff')
            valueAxis.renderer.grid.template.stroke = am4core.color('#fff')
            valueAxis.renderer.labels.template.fill = am4core.color('#fff')
            chart.legend.labels.template.fill = am4core.color('#fff')
        }
        
        chart.cursor = new am4charts.XYCursor()
        chart.cursor.behavior = 'panX'

        chartRef.current = chart


        return () => {
            chart.dispose()
        }

    }, [chartId, chartData ])

    return <div id={chartId} style={{ width: '100%', height: '300px', minHeight: '250px' }}></div>
}

export default ShModalChart