import React, { useState, useEffect } from 'react'
import { ModalListDiv, BtnEdit, Button, ButtonClose } from './ModalList.style'

import { State } from 'store/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { IAlertType } from 'types/alert'

import { updateAlertProcess, updateAlertComment } from 'store/actions/alerts'
import Pagination from 'react-js-pagination'
import useTheme from 'lib/useTheme'
import Cookies from 'universal-cookie/es6'
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getToken } from 'lib/login'

interface Props {
    modalVisible: boolean
    setModalVisible: any
    useId: number
    useType: string
    init: boolean
    setInit: any
}

const mappingStatusToName: any = {
    A: '알림',
    C: '확인',
    U: '미처리',
    P: '처리완료',
    H: '보류',
}

const mappingStatusToClass: any = {
    A: 'status state1',
    C: 'status state1',
    U: 'status state1',
    P: 'status state2',
    H: 'status state2',
}

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`
const SERVICE_PATH = `${API_BASE}/api/alert`

function ModalList(props: Props) {
    const [theme] = useTheme() // 화면모드
    const dispatch = useDispatch()
    const [listTitle, setListTitle] = useState('')
    const [showItem, setShowItem] = useState<IAlertType>({
        id: 0,
        grp: '',
        site: '',
        device: '',
        type: '',
        title: '',
        content: '',
        comment: '',
        process: '',
        useYn: '',
        writer: '',
        inverter_id: '',
        created_dt: '',
    })

    const [items, setItems] = useState([])
    const [testArr, setTestArr] = useState([])

    const [commentValue, setCommentValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [page, setPage] = useState(1)

    const stateIndex: any = useSelector((state: State) => state.alerts)

    let arr: any = []

    const handleChange = (alert: IAlertType) => {
        setShowItem(alert)
        setCommentValue(alert.comment)
    }

    const handleUpdateProcess = (process: string) => {
        const result = dispatch(updateAlertProcess(showItem.id, process))
        result
            // @ts-ignore
            .then(() => {
                showItem.process = process
                arr = testArr
                arr.map((alert: IAlertType, index: number) => {
                    if (showItem.id === alert.id) {
                        arr[index].process = process
                    }
                })
                loadList()
                setTestArr(arr)
                toast.dark('변경 되었습니다.')
            })
            .catch(() => {
                toast.dark('변경 실패! 다시 시도해주세요.')
            })
    }

    const handleUpdateComment = () => {
        //console.log('comment : ',commentValue)
        const result = dispatch(updateAlertComment(showItem.id, commentValue))
        result
            // @ts-ignore
            .then(() => {
                //showItem.comment = commentValue
                arr = testArr
                arr.map((alert: IAlertType, index: number) => {
                    if (showItem.id === alert.id) {
                        arr[index].comment = commentValue
                    }
                })
                loadList()
                setTestArr(arr)
                toast.dark('수정되었습니다.')
            })
            .catch(() => {
                toast.dark('수정 실패! 다시 시도해주세요.')
            })
    }

    const onChange = (event: any) => {
        const { name, value } = event.target
        if (name === 'comment') {
            setCommentValue(value)
        }
    }

    const handlePageChange = async (page: number) => {
        //axios 페이지 바꿔서 호출
        setCurrentPage(page)

        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid')
        if (!selected_sid) return
        const token = getToken()

        let data = {
            headers: { Authorization: `Bearer ${token}` },
            params: { page: page - 1, grp: 'earn' },
        }
        const res = await axios(`${SERVICE_PATH}Site/${selected_sid}`, data)

        if (res.data.content.length > 0) {
            arr = res.data.content
            loadList()
        }
        arr = []
    }

    const loadList = () => {
        const items = arr.map((alert: IAlertType, index: number) => (
            <div key={alert.id}>
                <input
                    type='radio'
                    id={'' + alert.id}
                    name='plantIssues'
                    value={alert.id}
                    defaultChecked={props.useId === alert.id || (props.useId === 0 && index === 0) ? true : false}
                    onChange={() => handleChange(alert)}
                />
                <label htmlFor={'' + alert.id}>
                    <div className='title'>{alert.title}</div>
                    <div className='subinfo'>
                        <div className='date'>{alert.created_dt.substring(0, 10)}</div>
                        <div className={mappingStatusToClass[alert.process]}>{mappingStatusToName[alert.process]}</div>
                    </div>
                    <div className='summary'>{alert.content}</div>
                </label>
            </div>
        ))

        setItems(items)
    }

    useEffect(() => {
        if (props.init === true) {
            //setArr(stateIndex.alertsAbnormal)
            arr = stateIndex.alerts
            setListTitle('정산내역')
            setPage(stateIndex.alertsPaging)

            setTestArr(arr)

            if (props.useId !== 0) {
                arr.forEach((alert: IAlertType) => {
                    if (props.useId === alert.id) {
                        //selectItem = alert
                        setShowItem(alert)
                        setCommentValue(alert.comment)
                    }
                })
            } else {
                if (arr.length > 0) {
                    setShowItem(arr[0])
                    setCommentValue(arr[0].comment)
                }
            }

            props.setInit(false)
        }

        loadList()
    }, [])

    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                props.setModalVisible(false)
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    if (!props.modalVisible) {
        return null
    }

    return (
        <ModalListDiv>
            <div className='modal'>
                <div className='modal-header'>
                    <div className='sub'>
                        <div className='title'>{listTitle}</div>
                        {/*<BtnNew>작성</BtnNew>*/}
                    </div>
                    <ButtonClose
                        onClick={() => {
                            props.setModalVisible(false)
                        }}
                        themeMode={theme}></ButtonClose>
                </div>
                <div className='modal-body'>
                    <div className='modal-grid'>
                        <div className='col-left'>
                            <div className='plant-issues'>{items}</div>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={10}
                                totalItemsCount={page * 10}
                                pageRangeDisplayed={5}
                                prevPageText={'‹'}
                                nextPageText={'›'}
                                onChange={handlePageChange}
                            />
                        </div>
                        <div className='col-right'>
                            <div className='content'>
                                <div className='content-header'>
                                    <div className='subinfo'>
                                        <div className={mappingStatusToClass[showItem.process]}>
                                            {mappingStatusToName[showItem.process]}
                                        </div>
                                        <div className='date'>{`${showItem.created_dt.substring(
                                            0,
                                            10,
                                        )} ${showItem.created_dt.substring(11, 16)}`}</div>
                                    </div>
                                </div>
                                <div className='content-body'>
                                    <h2>{showItem.title}</h2>
                                    <p>{showItem.content}</p>
                                </div>
                                <div className='content-edit'>
                                    <div className='content-edit-header'>
                                        <h3>처리내용</h3>
                                        <BtnEdit onClick={() => handleUpdateComment()}>수정</BtnEdit>
                                    </div>
                                    <textarea
                                        placeholder=''
                                        name='comment'
                                        onChange={onChange}
                                        value={commentValue}></textarea>
                                </div>
                                <div className='content-action'>
                                    <Button
                                        bg={showItem.process === 'U' ? true : false}
                                        onClick={() => handleUpdateProcess('U')}>
                                        미처리
                                    </Button>
                                    <Button
                                        bg={showItem.process === 'H' ? true : false}
                                        onClick={() => handleUpdateProcess('H')}>
                                        보류
                                    </Button>
                                    <Button
                                        bg={showItem.process === 'P' ? true : false}
                                        onClick={() => handleUpdateProcess('P')}>
                                        처리완료
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1500} />
        </ModalListDiv>
    )
}

export default ModalList
