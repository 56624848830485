import types from '../actions/actionTypes'
import { Actions } from '../actions/operation'
import { State, initialState } from './index'
import { IDeviceType} from '../../types/operation'


const operationReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case types.READ_DEVICES : {
      const temp = action.data
      let g = 0;
      let d = 0;
      let b = 0;
      let s = 0;
      temp.map((item:IDeviceType) => {
        if(item.state === 'G'){
          g++;
        }else if(item.state === 'D'){
          d++;
        }else if(item.state === 'B'){
          b++;
        }else if(item.state === 'S'){
          s++;
        }
      })
      let t = g+d+b+s

      return { ...state, devices: action.data, stateG:g, stateD:d, stateB:b, stateS:s, stateT:t }
    }
    case types.READ_JUNCTIONS : {
      return { ...state, junctions: action.data }
    }
    case types.SELECT_SID : {
      return { ...state, selectSid: action.data }
    }
    case types.MY_SIDS : {
      return { ...state, mySids: action.data }
    }
    case types.TODAY_PG : {
      const temp:any = action.data
      return { ...state, todayPg: temp.todayPg, yesterdayPg: temp.yesterdayPg }
    }
    case types.MONTH_PG : {
      const temp:any = action.data
      return { ...state, monthPg: temp.monthPg, lastPg: temp.lastPg}
    }
    case types.TODAY_PG_GRAPH : {
      return { ...state, todayPgGraph: action.data }
    }
    case types.MONTH_PG_GRAPH : {
      return { ...state, monthPgGraph: action.data }
    }
    case types.TODAY_REVENUE : {
      const temp:any = action.data
      return { ...state, todayRevenue: temp.todayRevenue, yesterdayRevenue: temp.yesterdayRevenue }
    }
    case types.MONTH_REVENUE : {
      const temp:any = action.data
      return { ...state, monthRevenue: temp.monthRevenue, lastMonthRevenue: temp.lastMonthRevenue }
    }
    case types.SMP_DATA : {
      return { ...state, smpData: action.data }
    }
    case types.CONTRACT_DATA : {
      return { ...state, contractData: action.data }
    }
    case types.RUN_PRICE : {
      return { ...state, runPrice: action.data }
    }
    case types.REVENUE_DETAILS : {
      return { ...state, revenueDetails: action.data }
    }
    case types.CONTRACT_UPDATE : {//action.data
      const contractData = [ ...state.contractData ]
      for (let contract of contractData) {
        if (contract.id === action.data.id) {
          contract.rec = action.data.rec
          contract.rec_price = action.data.rec_price
        }
      }
      return { ...state, contractData }
    }
    case types.RENTAL_READ : {
      return { ...state, rentalData: action.data }
    }
    case types.RENTAL_CREATE : {
      const rentalData = [ ...state.rentalData, action.data ]
      return { ...state, rentalData }
    }
    case types.RENTAL_UPDATE : {
      const rentalData = [ ...state.rentalData]
      for (let rental of rentalData) {
        if (rental.id === action.data.id) {
          rental.name = action.data.name
          rental.fix_yn = action.data.fix_yn
          rental.price = action.data.price
        }
      }
      /* //spread 식으로 적용하니 같은 데이트를 호출하는 테이블이 변경이 안되어 직접적 수정
      rentalData.map((rental) =>
        rental.id === action.data.id?{...rental, rental: action.data}:rental
      )}*/
      return { ...state, rentalData }
    }
    case types.RENTAL_DELETE : {
      return { ...state, rentalData: state.rentalData.filter(item=> item.id !== action.data.id) }
    }
    case types.SETTLEMENT_READ : {
      return { ...state, settlementData: action.data }
    }
    case types.SETTLEMENT_PRICE : {
      const settlementData = [ ...state.settlementData]
      for (let settlement of settlementData) {
        if (settlement.id === action.data.id) {
          settlement.smp = action.data.smp
          settlement.rec = action.data.rec
          settlement.run_price = action.data.run_price
          settlement.total = action.data.total
        }
      }
      return { ...state, settlementData }
    }
    case types.SETTLEMENT_CROSSYN : {
      const settlementData = [ ...state.settlementData]
      for (let settlement of settlementData) {
        if (settlement.id === action.data.id) {
          settlement.cross_yn = action.data.cross_yn
        }
      }
      return { ...state, settlementData }
    }
    case types.SETTLEMENT_BILLYN : {
      const settlementData = [ ...state.settlementData]
      for (let settlement of settlementData) {
        if (settlement.id === action.data.id) {
          settlement.bill_yn = action.data.bill_yn
        }
      }
      return { ...state, settlementData }
    }
    case types.SETTLEMENT_STATE : {
      const settlementData = [ ...state.settlementData]
      for (let settlement of settlementData) {
        if (settlement.id === action.data.id) {
          settlement.state = action.data.state
        }
      }
      return { ...state, settlementData }
    }
    case types.MONTHLY_RENTAL_READ : {
      return { ...state, monthlyRentalData: action.data }
    }
    case types.MONTHLY_RENTAL_CREATE : {
      const monthlyRentalData = [ ...state.monthlyRentalData, action.data ]
      return { ...state, monthlyRentalData }
    }
    case types.MONTHLY_RENTAL_UPDATE : {
      const monthlyRentalData = [ ...state.monthlyRentalData]
      for (let rental of monthlyRentalData) {
        if (rental.id === action.data.id) {
          rental.name = action.data.name
          rental.fix_yn = action.data.fix_yn
          rental.price = action.data.price
        }
      }
      return { ...state, monthlyRentalData }
    }
    case types.MONTHLY_RENTAL_DELETE : {
      return { ...state, monthlyRentalData: state.monthlyRentalData.filter(item=> item.id !== action.data.id) }
    }
    case types.MONTHLY_EARN_READ : {
      return { ...state, monthlyEarnData: action.data }
    }
    case types.MONTHLY_EARN_CREATE : {
        const monthlyEarnData = [...state.monthlyEarnData, action.data]
        return {...state, monthlyEarnData}
    }
    case types.MONTHLY_EARN_UPDATE : {
      const monthlyEarnData = [ ...state.monthlyEarnData]
      let index = 0;
      for (let earn of monthlyEarnData) {
        if (earn.id === action.data.id) {
          monthlyEarnData[index] = action.data;
        }
        index++
      }
      return { ...state, monthlyEarnData }
    }
    case types.MONTHLY_EARN_DELETE : {
      return { ...state, monthlyEarnData: state.monthlyEarnData.filter(item=> item.id !== action.data.id) }
    }
    case types.REC_DATA : {
      return { ...state, recData: action.data }
    }
    case types.REPORT_READ : {
      return { ...state, report: action.data }
    }
    default: {
      return state
    }
  }
}

export default operationReducer
