import { useCallback, useEffect, useState } from 'react'

const useTheme = (): [typeof theme, typeof toggleTheme] => {
    const THEME_KEY = `SNGY_PVMS_THEME`

    const getInitialTheme = useCallback(() => {
        let theme = window.localStorage.getItem(THEME_KEY) as 'light' | 'dark' | null

        const INVALID_THEME = theme !== 'light' && theme !== 'dark'

        if (!theme || INVALID_THEME) {
            const { matches } = window.matchMedia('(prefers-color-scheme: dark)')
            theme = matches ? 'dark' : 'light'
        }

        return theme
    }, [THEME_KEY])

    const [theme, setTheme] = useState(getInitialTheme)

    const toggleTheme = useCallback(() => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'))
        window.location.reload()
    }, [])

    useEffect(() => {
        window.localStorage.setItem(THEME_KEY, theme)
    }, [THEME_KEY, theme])

    return [theme, toggleTheme]
}

export default useTheme
