import React, {useEffect, useState} from "react";
import styled from "styled-components";
import MaterialTable, { Column, Query, QueryResult} from "material-table"
import {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import useTheme from "../../lib/useTheme";

const tableIcons = {
    Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ViewColumn {...props} ref={ref}/>)
};

interface IProps {
    values: any
    inverter: number
    selectDate: string
}

//모달에서 사용
function ReactTableModalPg({values, inverter, selectDate}: IProps) {
    const [theme] = useTheme(); // 화면모드
    const options = {
        search: false,
        showTitle: false,
        paging: false,
        toolbar: true,
        draggable: false,
        exportButton: true
    }

    const columns = [
        { title: selectDate+' 시간', field: 'category' },
        { title: '인버터1', field: 'real1' },
    ]
    if(inverter > 1) columns.push({ title: '인버터2', field: 'real2' })
    if(inverter > 2) columns.push({ title: '인버터3', field: 'real3' })
    if(inverter > 3) columns.push({ title: '인버터4', field: 'real4' })

    return (
        <ReactTableContainer themeMode={theme}>
            <MaterialTable
                columns={columns}
                data={values}
                icons={tableIcons}
                options={options}
            />
        </ReactTableContainer>
    )
}

export default ReactTableModalPg
const ReactTableContainer = styled.div<{themeMode:string}>`
   .MuiPaper-elevation2 {
    box-shadow: none;
   }
   .MuiPopover-root {
    z-index: 9999;
   }
   .MuiPaper-root {
     background-color: ${(props) => props.themeMode==='light'?'white':'#212429'};
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};
   }
   .MuiTableCell-root {
     background-color: ${(props) => props.themeMode==='light'?'white':'#212429'};
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};
   }
   .MuiSvgIcon-root {
     color: ${(props) => props.themeMode==='light'?'#3C4046':'#DDE2E5'};   
   }
`