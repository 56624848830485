import axios, {AxiosError} from 'axios'
import {getToken, onAuthorize401Refresh} from '../lib/login'

let isRefreshing = false

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    timeout: 30000,
})

instance.interceptors.request.use((config) => {
    const token = getToken()
    config.headers.Authorization = `Bearer ${token}`
    return config
})

instance.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const err = error as AxiosError
        if (err.response?.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                onAuthorize401Refresh().then(newToken => {
                    isRefreshing = false
                })
            }
        }
        return Promise.reject(error);
    }
)

export default instance
