import styled from 'styled-components'

export const InputContainer = styled.input<{ align:boolean }>`
    display: flex;
    text-align: ${(props) => (
    props.align ? 'right' :  'left'
    )};
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.textBase};
    background-color: ${(props) => props.theme.panel};
    border: 1px solid ${(props) => props.theme.settlementSortBorder};
    border-radius: 5px;
    width: 90%;
    height: 40px;
    margin-top: 10px;
    position: relative;
    font-size: 16px;
    padding: 10px;

}`