import styled from 'styled-components'

export const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'topbar topbar topbar topbar'
        'monthProfit todayProfit todayEnergy monthEnergy'
        'pgTime pgTime pgTime pgTime'
        'status status status energyChart'
        'fault fault fault summary';
    gap: 20px;
    @media (max-width: 1440px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'topbar topbar'
            'monthProfit todayProfit'
            'todayEnergy monthEnergy'
            'pgTime pgTime'
            'energyChart energyChart'
            'status status'
            'fault fault'
            'summary summary';
    }
`
