import React, { useState, useEffect } from 'react'
import { ModalStatusDiv, BtnEdit, Button, ButtonClose, ModalDropdown, BtnNew } from './ModalStatus.style'

import { State } from '../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { IAlertType }  from '../../types/alert'

import { updateAlertProcess, updateAlertComment } from '../../store/actions/alerts'
import Pagination from "react-js-pagination";
import useTheme from "../../lib/useTheme";
import Cookies from "universal-cookie/es6";
import axios from "axios";

import ICRepairDark from '../../assets/img/ic_repair_dark.svg'
import ICRepair from '../../assets/img/ic_repair.svg'
import ICSubsituteDark from '../../assets/img/ic_substitute_dark.svg'
import ICSubsitute from '../../assets/img/ic_substitute.svg'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IInverterType } from '../../types/status';
import { createInverterInfo, deleteInverterInfo, readInverterInfoAll, updateInverterInfo } from '../../store/actions/status';
import classNames from 'classnames';
import { getToken } from '../../lib/login';

interface Props {
  setModalVisible: any
  modalData: {
    site: string
    device: string
    inverter_id: string
    sid: string
  }
  type: string
}

const mappingStatusToName:any = {
  'P': '처리완료',
  'H': '보류',
  'U': '미처리',
}

const mappingStatusToClass:any = {
  'P': 'status state2',
  'H': 'status state2',
  'U': 'status state1',
}

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`
const SERVICE_PATH = `${API_BASE}/api/`

function ModalStatus(props: Props) {
  let statusIndex: any = useSelector((state: State) => state.status);
  let arr: any[] = statusIndex.status;
  
  const [theme] = useTheme(); // 화면모드
  const dispatch = useDispatch();
  const [showItem, setShowItem] = useState<IInverterType>({
      id: 0,
      site: props.modalData.site,
      device: props.modalData.device,
      inverter_id: props.modalData.inverter_id,
      title: '',
      comment: '',
      content: '',
      type: props.type,
      process: '',
      sid: props.modalData.sid,
      created_dt: '',
  })

  const [items, setItems] = useState<any[]>([]);
  const [addType, setAddType] = useState(false);

  const [commentValue, setCommentValue] = useState("")
  const [processValue, setProcessValue] = useState('U');
  const [currentPage, setCurrentPage] = useState(1)
  const [inputTitle, setInputTitle] = useState('기타');
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (arr.length > 0) {
      setShowItem(arr[0])
      setCommentValue(arr[0].comment)
      setProcessValue(arr[0].process)
      setPage(statusIndex.statusPaging)
    } else {
      addInverterInfo()
    }
  }, [arr])

  const handleChange = (alert:IInverterType) =>{
    setShowItem(alert)
    setCommentValue(alert.comment)
    setProcessValue(alert.process)
    setAddType(false)
  }

  const handleUpdate = async () =>{
    let result:any
    if (showItem.content) {
      await dispatch(updateAlertProcess(showItem.id, processValue))
      result = dispatch(updateAlertComment(showItem.id, commentValue))
    } else {
      result = dispatch(updateInverterInfo(showItem.id, commentValue, processValue))
    }
    result.then(() => {
      arr.map((alert: IInverterType, index: number) => {
        if(showItem.id === alert.id){
          arr[index].comment = commentValue
          arr[index].process = processValue
        }
      });
      loadList()
      toast.dark("변경 되었습니다.")
    }).catch(() => {
      toast.dark("변경 실패! 다시 시도해주세요.")
    })
  }

  const handleDelete = () => {
    const result = dispatch(deleteInverterInfo(showItem.id))
    // @ts-ignore
    result.then(() => {
      let newArr = arr.filter(item => showItem.id !== item.id)
      statusIndex.status = newArr;
      arr = newArr;
      loadList()
      toast.dark("삭제 되었습니다.")
    }).catch(() => {
      toast.dark("삭제 실패! 다시 시도해주세요.")
    })
  }

  const handleCreate = () => {
      showItem.comment = commentValue;
      showItem.process = processValue;
      showItem.title = inputTitle;

      let data: any = dispatch(createInverterInfo(showItem));
      data.then((value: any) => {
        showItem.id = value
        arr.unshift(showItem)
        setAddType(false)
        loadList()
        toast.dark("저장 되었습니다.")
      }).catch(() => {
        toast.dark("저장 실패! 다시 시도해주세요.")
      })

  }

  const onChange = (event:any) => { 
    const { name, value } = event.target; 
    if (name === "comment") { 
      setCommentValue(value); 
    } 
  }

  const handlePageChange = async(page:number) => {
    //axios 페이지 바꿔서 호출
    setCurrentPage(page)

    const token = getToken()

    let data = {
      headers: {'Authorization': `Bearer ${token}`},
      params: {"page": (page-1), "size": 10}
    }

    const res = await axios(`${SERVICE_PATH}${props.type=='3'?'inverterMng':'junctionMng'}/${btoa(props.modalData.site)}`, data)

    if(res.data.content.length > 0) {
      arr = res.data.content
      loadList()
    }else{
      arr = []
    }

  }

  const addInverterInfo = () => {
    let now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours();
    const minutes = now.getMinutes();

    setShowItem(current => ({
      id: 0,
      site: props.modalData.site,
      device: props.modalData.device,
      inverter_id: props.modalData.inverter_id,
      title: '',
      comment: '',
      content: '',
      type: props.type,
      process: 'U',
      sid: props.modalData.sid,
      created_dt: `${year}-${month}-${day} ${hour}:${minutes}`
    }))
    setAddType(true)
    setCommentValue('')
    setProcessValue('U')
    setInputTitle('기타')
  }

  const handleChangeFix = () =>{
    let process;

    if(processValue==='U'){
      process='P'
    }else if (processValue === 'P') {
      process='H'
    } else {
        process='U'
    }
    setProcessValue(process)
  }
  const handleChangeFixText = (value: string) => {
      if (value === 'U'){
          return '미처리'
      } else if (value === 'P') {
          return '처리완료'
      } else {
          return '보류'
      }
  }

  const handleChangeTitle = () =>{
    let title;

    if(inputTitle==='기타'){
      title='교체'
    }else if (inputTitle === '교체') {
      title='수리'
    } else {
        title='기타'
    }
    setInputTitle(title)
  }

  const loadList = () =>{
    const items = arr.map((status: IInverterType, index:number) => (
          <div key={status.id}>
            <input type='radio' id={''+status.id} name='plantIssues' value={status.id} defaultChecked={index === 0 ? true : false} onChange={()=>handleChange(status)} onClick={()=>handleChange(status)}/>
            <label htmlFor={''+status.id}>
              <div className='title'>{status.title}</div>
              <div className='subinfo'>
                <div className='date'>{`${status.created_dt.substring(0,10)} ${status.created_dt.substring(11,16)}`}</div>
                <div className={mappingStatusToClass[status.process]} >{mappingStatusToName[status.process]}</div>
              </div>
              <div className='summary'>
                {status.comment}
              </div>
            </label>
          </div>
    ));

    setItems(items)
  }

  useEffect(() => {
    loadList()

    const handleEsc = (event:any) => {
      if (event.keyCode === 27) {
        props.setModalVisible(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

    return (
      <ModalStatusDiv>
        <div className='modal'>
          <div className='modal-header'>
            <div className='sub'>
              <div className='title'>세부사항</div>
              <BtnNew themeMode={theme}  onClick={()=>addInverterInfo()}><i className='icon-pencil'></i>추가</BtnNew>
              <div className='sub-count'>
                <img src={theme==='light'?ICSubsitute:ICSubsituteDark} alt="교체" /><span>교체: {arr.filter(item => item.title === "교체").length}</span>
                <img src={theme==='light'?ICRepair:ICRepairDark} alt="수리" /><span>수리: {arr.filter(item => item.title === "수리").length}</span>
              </div>
            </div>
            <ButtonClose onClick={() => {props.setModalVisible(false)}} themeMode={theme}></ButtonClose>
          </div>
          <div className='modal-body'>
            <div className='modal-grid'>
              <div className='col-left'>
                <div className='plant-issues'>
                  {items}
                </div>
                <Pagination activePage={currentPage} itemsCountPerPage={10} totalItemsCount={page*10} pageRangeDisplayed={5} prevPageText={"‹"} nextPageText={"›"} onChange={handlePageChange} />
              </div>
              <div className='col-right'>
                  <div className='content'>
                    <div className='content-header'>
                      <div className='subinfo'>
                        <div className={mappingStatusToClass[showItem.process]}>{mappingStatusToName[showItem.process]}</div>
                        <div className='date'>{`${showItem.created_dt.substring(0,10)} ${showItem.created_dt.substring(11,16)}`}</div>
                      </div>
                    </div>
                    <div className={classNames('content-body', showItem.content ? "" : 'content-body-none')}>
                      { addType ? 
                        ( 
                          <ModalDropdown themeMode={theme} onClick={()=>handleChangeTitle()}><span>{inputTitle}</span></ModalDropdown>
                        ) : (
                          <>
                            <h2>{showItem.title}</h2>
                            { showItem.content ? <p>{showItem.content}</p> : "" }
                          </>
                        )
                      }
                    </div>

                    <div className='content-edit'>
                      <div className='content-edit-header'>
                        <h3>처리내용</h3>
                      </div>
                      <textarea className={classNames(showItem.content ? "" : 'none')} placeholder='' name="comment" onChange={onChange} value={commentValue}>
                      </textarea>
                        <ModalDropdown themeMode={theme} onClick={()=>handleChangeFix()}>
                            <span>{handleChangeFixText(processValue)}</span>
                        </ModalDropdown>
                    </div>
                    
                    <div className='content-action'>
                      { !addType && <Button bg={false} onClick={() => handleUpdate()}>수정</Button> }
                      { !addType && !showItem.content && <Button bg={false} onClick={() => handleDelete()}>삭제</Button> }
                      { addType && <Button bg={false} onClick={() => handleCreate()}>저장</Button> }
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1500}/>
      </ModalStatusDiv>
    );
  }

  export default ModalStatus;

  