import React, { useState, useEffect } from 'react'
import { ModalListDiv, BtnNew, Button, ButtonClose } from './ModalEarn.style'

import { useDispatch } from 'react-redux';
import Big from 'big.js';

import useTheme from "../../lib/useTheme";
import {IMonthlyEarnType } from "../../types/operation";
import Input from "../Input/Input";
import { numberWithCommas, onlyNumber} from "../../lib/asyncUtils";
import {
  monthlyEarnCreate, monthlyEarnDelete,
  monthlyEarnUpdate,
  settlementRead
} from "../../store/actions/operation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import type { Moment } from 'moment';
import moment from 'moment';
import 'moment/locale/ko';
import locale from 'rc-picker/lib/locale/ko_KR';
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import '../../assets/scss/_cal.scss'
import styled from "styled-components";
import Ic_cal from "../../assets/img/icon_calendar.svg";
import NumberInput from '../NumberInput/NumberInput';

interface Props {
  values: IMonthlyEarnType[]
  modalVisible: boolean
  setModalVisible: any
  init: boolean
  setInit: any
  selectSiteId: string
  day:string
  selectName: string
}

function ModalEarn({values, modalVisible, setModalVisible, init, setInit, selectSiteId, day, selectName }: Props) {
  const [theme] = useTheme(); // 화면모드
  const dispatch = useDispatch();
  const [listTitle, setListTitle] = useState('')
  const [selectDate, setSelectDate] = useState<Moment>(moment(day+moment().format('-DD'),'YYYY-MM-DD'))
  const [showItem, setShowItem] = useState<IMonthlyEarnType>({
    id: 0,
    site_id: '',
    day: day,
    name: selectName,
    price: 0,
    earn_date: selectDate.format('YYYY-MM-DD'),
    bill_yn: 'N',
    org: 0,
    vat: 0,
    base: 0,
    pg: 0,
    rec_weight: 0,
    remain: 0,
    remain_next: 0,
    rec_cnt: 0
  })

  const [updateBtn, setUpdateBtn] = useState(false);
  const [addType, setAddType] = useState(false);//true 추가 상태, false 업데이트 상태

  const addEarn = () => {
    setShowItem({
      id: 0,
      site_id: selectSiteId,
      day:day,
      name: selectName,
      price: 0,
      earn_date: selectDate.format('YYYY-MM-DD'),
      bill_yn: 'N',
      org: 0,
      vat: 0,
      base: 0,
      pg: 0,
      rec_weight: 0,
      remain: 0,
      remain_next: 0,
      rec_cnt: 0
    })
    setAddType(true)
    setSelectDate(moment(selectDate.format('YYYY-MM-DD'),'YYYY-MM-DD'))
  }

  const handleChange = (earn:IMonthlyEarnType) => {
    selectShowItem(earn.id)
  }

  const selectShowItem = (id:number)=>{
    var noSiteData = true
    values.filter(item=> item.name === selectName).map((earn:IMonthlyEarnType, index:number, arr:any)=>{
      if (earn.site_id === selectSiteId ) {
        //console.log(earn, index, arr)
        
        if (id === 0 && index === arr.length - 1) {
          setShowItem(earn)
          setSelectDate(moment(earn.earn_date,'YYYY-MM-DD'))
        } else if (earn.id === id) {
          setShowItem(earn)
          setSelectDate(moment(earn.earn_date,'YYYY-MM-DD'))
        }
        noSiteData = false
      }
      if(noSiteData===true){
        setAddType(true) //추가 모드로 시작
      }
    })
  }

  const handleChangeSmpInput = (name: string, value:number) =>{
    //console.log("name : ",name+" / "+value)

    let tempValue = {
      ...showItem,
      [name]: value
    }

    // @ts-ignore
    if(tempValue.pg === "1" && tempValue.base === 0){

    }else {
      let org = Math.floor(tempValue.pg * tempValue.base)
      let vat = Math.floor(org * 0.1)

      setShowItem({
        ...tempValue,
        org: org,
        vat: Math.floor(org * 0.1),
        price: org + vat
      })
    }

    setUpdateBtn(true)
  }

  const handleChangeRecInput = (name: string, value:number) =>{
    let tempValue = {
      ...showItem,
      [name]: value
    };

    // 입력 받기 and 예외 처리
    let mWh = Big(tempValue.pg === undefined ? 0 : tempValue.pg / 1000)
    let rec_weight = !tempValue.rec_weight || tempValue.rec_weight.toString()[0] === '.' ? Big(0) : Big(tempValue.rec_weight)
    let remain = !tempValue.remain || tempValue.remain.toString()[0] === '.' || tempValue.remain >= 1 ? Big(0) : Big(tempValue.remain)

    // REC 계산
    let tempRec = Math.floor(Number.parseFloat(mWh.mul(rec_weight).add(remain).toString()) * 1000)/1000.0

    // REC, REMAIN, ORG 값 추출
    let rec = Number.parseFloat(tempRec.toString().split(".")[0])
    let org = Math.floor(rec * tempValue.base)
    let remain_next = Number.parseFloat("0."+tempRec.toString().split(".")[1])
    let vat = rec * 110

    setShowItem({
      ...tempValue,
      org: org,
      price: org + (tempValue.vat * 1),
      rec_cnt: rec,
      rec_weight: Number.parseFloat(rec_weight.toString()),
      remain: Number.parseFloat(remain.toString()),
      remain_next: remain_next,
      vat:vat
    })

    setUpdateBtn(true)
  }

  const updateEarn = () =>{
    const result = dispatch(monthlyEarnUpdate(showItem as IMonthlyEarnType))
    // @ts-ignore
    result.then(() => {
      toast.dark("업데이트 되었습니다.")
      callSettlement()
    }).catch(() => {
      toast.dark("업데이트 실패! 다시 시도해주세요.")
    })
  }

  const createEarn = () =>{
    const result = dispatch(monthlyEarnCreate(showItem as IMonthlyEarnType))
    // @ts-ignore
    result.then(() => {
      toast.dark("저장 되었습니다.")
      callSettlement()
      setAddType(false)
    }).catch(() => {
      toast.dark("저장 실패! 다시 시도해주세요.")
    })
  }

  const deleteEarn = () =>{
    const result = dispatch(monthlyEarnDelete(showItem as IMonthlyEarnType))
    // @ts-ignore
    result.then(() => {
      toast.dark("삭제 되었습니다.")
      callSettlement()
    }).catch(() => {
      toast.dark("삭제 실패! 다시 시도해주세요.")
    })
  }
  const callSettlement = () =>{
    if(day!==''){
      //정산 데이터 호출
      const tick = setTimeout(() => {
        dispatch(settlementRead(day))
      }, 1000);
    }
  }

  const onChange = (newValue: Moment | null, formatString?: string) => {
    //console.log('Change:', newValue?.format('yyyy-MM-DD'));
    if(newValue !== null){
      showItem.earn_date = newValue.format('yyyy-MM-DD')
      setSelectDate(moment(newValue.format('yyyy-MM-DD')))
    }
  }

  const disabledDate = (current: Moment) => {
    return current <= selectDate.startOf("month") || current >= selectDate.endOf("month");
  }

  useEffect(() => {
    if(init === true){
       setListTitle(selectName)
       setInit(false)

      if(values.filter(item=> item.name === selectName).length <= 0){
        setAddType(true) //추가 모드로 시작
       }else{
        selectShowItem(0)
       }
    }
    showItem.site_id = selectSiteId

  },[selectDate]);

  useEffect(() => {
    const handleEsc = (event:any) => {
      if (event.keyCode === 27) {
        setModalVisible(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

    if(!modalVisible){
      return null;
    }

    return (
      <ModalListDiv>
        <div className='modal'>
          <div className='modal-header'>
            <div className='sub'>
              <div className='title'>{listTitle}</div>
              <BtnNew themeMode={theme}  onClick={()=>addEarn()}><i className='icon-pencil'></i>추가</BtnNew>
            </div>
            <ButtonClose onClick={() => {setModalVisible(false)}} themeMode={theme}></ButtonClose>
          </div>
          <div className='modal-body'>
            <div className='modal-grid'>
              <div className='col-left'>
                <div className='plant-issues'>
                  {values.filter(item=> item.name ===selectName).slice(0).reverse().map((earn: IMonthlyEarnType, index:number) => {
                    if (earn.site_id === selectSiteId)
                      return (
                          <div key={index}>
                            <input type='radio' id={'' + earn.id} name='plantIssues' value={earn.id}
                                   defaultChecked={index === 0 ? true : false} onChange={() => handleChange(earn)}/>
                            <label htmlFor={'' + earn.id}>
                              <div className='title'>{numberWithCommas('' + earn.price, 0) + ' 원'}</div>
                              <div className='summary'>
                                {earn.earn_date}
                              </div>
                            </label>
                          </div>
                      )
                  })}
                </div>
              </div>
              <div className='col-right'>
                  <div className='content'>
                    <div className='content-body'>
                      <div className='body-grid'>
                        <div className='grid-sub'>
                          <div className='edit_title'>사용일자</div>
                          <ButtonCal><Picker<Moment> generateConfig={momentGenerateConfig} disabledDate={disabledDate} locale={locale} picker={"date"} onChange={onChange} defaultValue={selectDate}/></ButtonCal>
                        </div>
                      </div>
                      { selectName === "SMP" && (
                      <>
                        {(showItem.pg !== 1 && showItem.base !== 0) &&
                        <div className='body-grid-2'>
                          <div className='grid-sub'>
                            <div className='edit_title'>발전량</div>
                            <NumberInput name='pg' value={showItem.pg} handleChangeInput={handleChangeSmpInput}
                                         suffix=" kWh"/>
                          </div>
                          <div className='grid-sub'>
                            <div className='edit_title'>SMP 기준가격</div>
                            <NumberInput name='base' value={showItem.base} handleChangeInput={handleChangeSmpInput}
                                         suffix=" 원" decimalScale={3}/>
                          </div>
                        </div>
                        }
                      <div className='body-grid-2'>
                        <div className='grid-sub'>
                          <div className='edit_title'>공급가액</div>
                          <NumberInput name='org' value={showItem.org} handleChangeInput={handleChangeSmpInput} suffix=" 원" disabled={true}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>부가세</div>
                          <NumberInput name='vat' value={showItem.vat} handleChangeInput={handleChangeSmpInput} suffix=" 원" disabled={true}/>
                        </div>
                      </div>
                      </>
                      )}
                      { selectName === "REC" && (
                      <>
                      <div className='body-grid-4'>
                        <div className='grid-sub'>
                          <div className='edit_title'>발전량</div>
                          <NumberInput name='pg' value={showItem.pg} handleChangeInput={handleChangeRecInput} suffix=" kWh"/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC 가중치</div>
                          <NumberInput name='rec_weight' value={showItem.rec_weight} handleChangeInput={handleChangeRecInput} suffix=" 배" decimalScale={2}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>전월 REC</div>
                          <NumberInput name='remain' value={showItem.remain} handleChangeInput={handleChangeRecInput} suffix="" decimalScale={3}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>이월 REC</div>
                          <NumberInput name='remain_next' value={showItem.remain_next} handleChangeInput={handleChangeRecInput} suffix="" decimalScale={3} disabled={true}/>
                        </div>
                      </div>
                      <div className='body-formula'>
                        <p>
                          {showItem.pg / 1000} MWh (발전량) × {showItem.rec_weight} (가중치) + {showItem.remain} (전월 REC) = {showItem.rec_cnt}.{(showItem.remain_next+"").split(".")[1]} (REC)
                        </p>
                      </div>
                      <div className='body-grid-4'>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC</div>
                          <NumberInput name='rec_cnt' value={showItem.rec_cnt} handleChangeInput={handleChangeRecInput} suffix=" 개" disabled={true}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC 기준가격</div>
                          <NumberInput name='base' value={showItem.base} handleChangeInput={handleChangeRecInput} suffix=" 원"/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>공급가액</div>
                          <NumberInput name='org' value={showItem.org} handleChangeInput={handleChangeRecInput} suffix=" 원" disabled={true}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>부가세</div>
                          <NumberInput name='vat' value={showItem.vat} handleChangeInput={handleChangeRecInput} suffix=" 원" disabled={true}/>
                        </div>
                      </div>
                      <div className='body-formula'>
                        <p>
                          부가세 : 한국에너지공단 REC 발급수수료 + 전력거래서 수수료
                        </p>
                      </div>
                      </>
                      )}
                      <div className='body-grid-2'>
                          <div className='grid-sub input-width'>
                            <div className='edit_title'>합계</div>
                            <NumberInput name='price' value={showItem.price} handleChangeInput={(name:string, value:number)=>true} suffix=" 원" disabled={true}/>
                          </div>
                      </div>
                    </div>

                    <div className='content-action'>
                      {!addType &&
                        <Button bg={false} onClick={()=>deleteEarn()}>삭제</Button>
                      }
                      {!addType &&
                        <Button bg={updateBtn} onClick={()=>updateEarn()}>수정</Button>
                      }
                      {addType &&
                        <Button bg={updateBtn} onClick={()=>createEarn()}>저장</Button>
                      }
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1500}/>
      </ModalListDiv>
    );
  }

  export default ModalEarn;


const ButtonCal = styled.button`
  width: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 36px;
  padding: 0 15px;
  color: ${(props) => props.theme.grayBtnText};
  border-radius: 50vh;
  margin: 5px 0px 20px 0px;
  background-color: ${(props) => props.theme.grayBtn};
  &:hover {
    background-color: ${(props) => props.theme.grayBtn_hover};
  }
  background-image: url(${Ic_cal});
  background-position: 110px center;
  background-repeat: no-repeat; 
`