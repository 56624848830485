export const LightTheme = {
    textBase: '#3C4046',
    textBase2: '#676F7A',
    textBlue: '#3376DB',
    textGreen: '#03A93C',
    textOrange: '#FB6232',
    textPurple: '#886DD3',
    textRed: '#EF1212',
    mainBase: '#EFF3F9',
    mainBlue: '#3376DB',
    subBase: '#ECEFF2',

    grayBtn: '#A4B2B8',
    grayBtn_hover: '#3376DB',
    grayBtnText: 'white',

    chartSortRadioBg: 'white',
    chartSortRadioText: '#3376DB',
    chartSortBorderColor: '#3376DB',

    panel: 'white',
    panelValue: '#111',
    panelSubLabel: '#888',

    guageColor: '#95fff9',
    guageSleepColor: '#ebedf1',

    line: '#F1F1F1',
    settlementSortBorder: '#CCD9DE',
    settlementTableHeader: '#9DA8B9',
    settlementContainerBg: '#D8E0E5',

    tableTopBorder: '#516581',
    tableHeader: '#FAFCFD',
    tableHeaderColor: '#222',
    titleBlue: '#0B51AF',
    active: '#0B51AF',

    roundBtnColor: '#A4B2B8',
    darkLight: '#3376DB',
    tableTxt: '#676F7A',
    modalHeader: '#e6eaec',
    modalChecked: '#28589f',
    modalSummary: '#b0d2f1',

    lightBlack: '#676F7A',
    editBtn: '#EFF3F9',
    modalBtn: '#A4B2B8',
    navLogoBg: '#F1F5F6',
    activeTable: '#e6eaec',

    bannerBg: '#FBF3E3',
    bannerBorder: 'white',
    bannerTitle: '#2F3441',
    bannerList: '#0D384E',
    whiteBase2: 'white',
    topBg: '#3376DB',
    topColor: 'white',
    sleepTxt: '#9DA8B9',
}

export const DarkTheme = {
    textBase: '#DDE2E5',
    textBase2: '#9DA8B9',
    textBlue: '#3376DB',
    textGreen: '#38D06C',
    textOrange: '#FB8832',
    textPurple: '#886DD3',
    textRed: '#FC4040',
    mainBase: '#292E34',
    mainBlue: '#3376DB',
    subBase: '#ECEFF2',

    grayBtn: '#9DA8B9',
    grayBtn_hover: '#3376DB',
    grayBtnText: '#000000',

    chartSortRadioBg: '#212429',
    chartSortRadioText: '#DDE2E5',
    chartSortBorderColor: '#4A545E',

    panel: '#212429',
    panelValue: '#DDE2E5',
    panelSubLabel: '#9DA8B9',

    guageColor: '#416BAC',
    guageSleepColor: '#ebedf1',

    line: '#323941',
    settlementSortBorder: '#4A545E',
    settlementTableHeader: '#161A20',
    settlementContainerBg: '#161A20',

    tableTopBorder: '#202234',
    tableHeader: '#292E34',
    tableHeaderText: '#9DA8B9',
    titleBlue: '#DDE2E5',
    active: '#46ACDF',

    roundBtnColor: '#111',
    darkLight: '#DDE2E5',
    tableTxt: '#ECEFF2',
    modalHeader: '#161A20',
    modalChecked: '#28589f',
    modalSummary: '#b0d2f1',

    lightBlack: '#000',
    editBtn: '#9DA8B9',
    modalBtn: '#9DA8B9',
    navLogoBg: '#272C35',
    activeTable: '#0E2E5F',

    bannerBg: '#37546F',
    bannerBorder: '#212429',
    bannerTitle: 'white',
    bannerList: '#B8D7E7',
    whiteBase2: '#9DA8B9',
    topBg: '#46ACDF',
    topColor: '#111111',
    sleepTxt: '#9DA8B9',
}
