import { AxiosError } from 'axios'
import { AnyAction, Dispatch } from 'redux'

export const createPromiseThunk = (type: string, promiseCreator: Function) => {
    const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`]

    return <P>(params?: P) =>
        async (dispatch: Dispatch) => {
            dispatch({ type })
            try {
                const payload = await promiseCreator(params)
                const data = payload.data

                if (!data.is_success) {
                    throw new UserException(data.result_code, data.result_message)
                }

                dispatch({
                    type: SUCCESS,
                    payload: payload.data.items,
                })
            } catch (err) {
                dispatch({
                    type: ERROR,
                    payload: err,
                })
            }
        }
}

export const handleAsyncActions = (type: string, key: string) => {
    const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`]

    return <S>(state: S, action: AnyAction) => {
        switch (action.type) {
            case type:
                return {
                    ...state,
                    [key]: reducerUtils.loading(),
                }
            case SUCCESS:
                return {
                    ...state,
                    [key]: reducerUtils.success(action.payload),
                }
            case ERROR:
                return {
                    ...state,
                    [key]: reducerUtils.error(action.payload),
                }
            default:
                return state
        }
    }
}

export interface reducerType<D> {
    loading: boolean
    data: D
    error: AxiosError | null
}

export const reducerUtils = {
    initial: (initialData = null) => ({
        loading: false,
        data: initialData,
        error: null,
    }),
    loading: (prevState = null) => ({
        loading: true,
        data: prevState,
        error: null,
    }),
    success: <D>(payload: D) => ({
        loading: false,
        data: payload,
        error: null,
    }),
    error: (error: AxiosError) => ({
        loading: false,
        data: null,
        error,
    }),
}

class UserException extends Error {
    statusCode: number
    constructor(statusCode: number, message?: string) {
        super(message)
        this.name = 'UserException'
        this.statusCode = statusCode
    }
}

export function isEmail(email:string) {
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
}

export function numberWithCommas(x: string, deci?:number) {
    let parts = parseFloat(x).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if(deci && parts.length > 1){
        parts[1] = parts[1].substring(0,deci);
    }
    return parts.join('.');
}

export function numberWithUnCommas(x: string) {
    var cn = x.replace(/,/g, "");
    var temp = parseFloat(cn);
    return temp
}

export const onlyNumber = (str:string) => {
    return str.replace(/(^0+)/, "").replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1").replace(/(\.\d{2}).+/g, '$1');
}

export function numberWithCommasNumberType(x: number) {
    try {
        let parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if(parts.length > 1){
            parts[1] = parts[1].substring(0,1)
        }
        return parts.join('.');
    }catch (e){
        return 0
    }
}

export function percent(c:number, t:number ) {
    var temp = c/t * 100;
    const cn = temp.toFixed(0).toString();
    if(cn == 'NaN'){
        return 0
    }else {
        return cn
    }
}

export function iconPercent(p:number ) {
    if(p >= 50){
        let temp = 110-p;
        return temp.toFixed(0).toString()
    }else{
        let temp = 90-p;
        return temp.toFixed(0).toString()
    }
}

export function upDown(c:string ) {
    // @ts-ignore
    var x= c*1;
    if(Math.sign(x)>=0){
        return 'up'
    }else {
        return 'down'
    }
}

export function numberToKorean(number:any){
    var inputNumber  = number < 0 ? false : number;
    var unitWords    = ['', '만', '억', '조', '경'];
    var splitUnit    = 10000;
    var splitCount   = unitWords.length;
    var resultArray  = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++){
        var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0){
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++){
        if(!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString+'원';
}