import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import Table from '../../components/Table/Table'


interface IProps {
    values: {
        title: string
        content: string
        created_dt: string
        process: 'A' | 'C' | 'U' | 'P' | 'H'
        id: number
        type: string
    }[]
}

const header = [
    { className: 'location', name: '인버터명', key: 'title' },
    { className: 'content', name: '내용', key: 'content' },
    { className: 'date', name: '일자', key: 'created_dt' },
    { className: 'status', name: '처리여부', key: 'process' },
]
const mappingStatusToName = {
    'A': '알림',
    'C': '확인',
    'U': '미처리',
    'P': '처리완료',
    'H': '보류',
}

function FaultDetectionCard({ values }: IProps) {
    const type: string = 'abnormal'

    const faultBody = values.map((value) => ({
        title: { content: value.title },
        content: { content: value.content, className: 'content' },
        created_dt: { content:`${value.created_dt.substring(0,10)} ${value.created_dt.substring(11,16)}`},
        process: { content: mappingStatusToName[value.process], className: 'check-'+value.process },
        id: {content:value.id},
        type: {content:type}
    }))

    return (
        <Card girdArea="fault" title="이상감지" onClickMore={values.length>0?type:''}>
            <TableContainer>
                <Table header={header} body={faultBody} />
                {values.length < 1 &&
                    <div className="summary-content-no">
                        내용이 없습니다.
                    </div>
                }
            </TableContainer>
        </Card>
    )
}

export default FaultDetectionCard

const TableContainer = styled.div`
    overflow: auto;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    table {
        text-align: center;
        width: 100%;
        min-width: 900px;
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};
        font-size: 14px;

        color: ${(props) => props.theme.textBase2};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 0;
            border-bottom: 1px solid ${(props) => props.theme.line};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.check-A {
                color: #fb6232;
            }
            &.check-C {
                color: #fb6232;
            }
            &.check-U {
                color: #03a93c;
            }
            &.check-P {
                color: #03a93c;
            }
            &.check-H {
                color: #03a93c;
            }
        }
    }
    .title {
        width: 165px;
    }
    .location {
        width: 165px;
    }
    .content {
        width: 500px;
        max-width: 500px;
    }
    .date {
        width: 200px;
    }
    .status {
        width: 160px;
    }
    .summary-content-no {
        flex: 1 0 182px;
        font-size: 15px;
        color: ${(props) => props.theme.panelSubLabel};
        line-height: 3em;
        padding-left: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        cursor: default;
    }
`
