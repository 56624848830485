import React from 'react'
import SummaryCard, {Summary} from '../Common/SummaryCard'
import {INodataType} from "../../types/status";

interface IProps {
    values: INodataType[]
}
function BrokenListCard({ values }: IProps) {
    const nodataArr:Summary[] = values.map((value) => ({
        type: 'replacement',
        title: value.name,
        content: value.state+'. 10분 이상',
        process: '',
        id: value.id
    }))
    return <SummaryCard summaryList={nodataArr} title="통신불가" onClickMore={''} noClick={true}/>
}

export default BrokenListCard
