import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import StatusPanel from '../../components/StatusPanel/StatusPanel'

import StateGoodFace from '../../assets/img/img_State.svg'
import StateNormalFace from '../../assets/img/img_State2.svg'
import StateBadFace from '../../assets/img/img_State3.svg'
import StateGoodFaceDark from '../../assets/img/img_State_dark_1.svg'
import StateNormalFaceDark from '../../assets/img/img_State_dark_2.svg'
import StateBadFaceDark from '../../assets/img/img_State_dark_3.svg'
import StateSleepFace from '../../assets/img/img_State4.svg'

import { iconPercent } from '../../lib/asyncUtils';
import useTheme from "../../lib/useTheme";


interface StatusProps {
    number: number
    percent: any
    warningNum?: number
}

interface IProps {
    values: {
        [index: string]: any
        totalNum: number
        good: StatusProps
        normal: StatusProps
        bad: StatusProps
        sleep: StatusProps
    }
}
function StatusPanels({ values }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <Card title="상태" girdArea="status" onClickMore="" homeUrl="status">
            <StatusGrid>
                {['good', 'normal', 'bad', 'sleep'].map((status) => (
                    <StatusPanel
                        key={status}
                        gauge={iconPercent(values[status].percent)}
                        name={StatusConstantInfo[status].name}
                        utilizationRate={StatusConstantInfo[status].utilizationRate}
                        statusIcon={theme==='light'?StatusConstantInfo[status].statusIcon:StatusConstantInfo[status].darkIcon}
                        number={values[status].number}
                        percent={values[status].percent}
                        totalNum={values.totalNum}
                        warningNum={values[status].warningNum}
                    />
                ))}
            </StatusGrid>
        </Card>
    )
}

export default StatusPanels

interface StatusConstant {
    name: string
    gauge: number
    utilizationRate: string
    statusIcon: string
    darkIcon: string
}
const StatusConstantInfo: { [index: string]: StatusConstant } = {
    good: {
        name: '좋음',
        gauge: 25,
        utilizationRate: '70%-100%',
        statusIcon: StateGoodFace,
        darkIcon: StateGoodFaceDark
    },
    normal: {
        name: '보통',
        gauge: 50,
        utilizationRate: '30%-70%',
        statusIcon: StateNormalFace,
        darkIcon: StateNormalFaceDark
    },
    bad: {
        name: '나쁨',
        gauge: 75,
        utilizationRate: '0%-30%',
        statusIcon: StateBadFace,
        darkIcon: StateBadFaceDark
    },
    sleep: {
        name: '중지',
        gauge: 75,
        utilizationRate: '-',
        statusIcon: StateSleepFace,
        darkIcon: StateSleepFace
    },
}
const StatusGrid = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 1180px) {
        grid-template-columns: 1fr;
    }
`
