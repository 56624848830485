import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie/es6'
import { NODE_ENV, NODE_ENV_TYPE } from 'constant/common'

const useCookie = (key: string, initVal?: string): [typeof getCookie, typeof setCookie, typeof removeCookie] => {
    const cookies = new Cookies()

    let COOKIE_KEY = key
    let domain: string | undefined = '.sngy.io'

    /* change key & domain by environment */
    if (NODE_ENV === NODE_ENV_TYPE.DEV) {
        COOKIE_KEY += '_DEV'
    } else if (NODE_ENV === NODE_ENV_TYPE.LOCAL) {
        COOKIE_KEY += '_LOCAL'
        domain = undefined
    }

    /* read cookie */
    const getCookie: (options?: CookieGetOptions) => string | null = (options) => {
        return cookies.get(COOKIE_KEY, options) || initVal || null
    }

    /* create, update cookie */
    const setCookie: (value: any, options?: CookieSetOptions) => void = (value, options) => {
        cookies.set(COOKIE_KEY, value, { domain, ...options })
    }

    /* delete cookie */
    const removeCookie: (options?: CookieSetOptions) => void = (options) => {
        cookies.remove(COOKIE_KEY, { domain, ...options })
    }

    return [getCookie, setCookie, removeCookie]
}

export default useCookie
