import styled from 'styled-components'
import { Container } from '../Card/Card.style'

export const ContractContainer = styled(Container)<{ cursor: string }>`
position: relative;
grid-area: contract;
background-color: ${(props) => props.theme.panel};
border-radius: 8px;
padding: 30px 30px;
overflow-y: hidden;
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      display: contents;
      font-size: 24px;
      color: ${(props) => props.theme.textBase};
      i {
        font-size: 24px;
      }
    }
}
.table-container {
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    overflow-y: auto;
    height: calc(100% - 40px);
    white-space: nowrap;
    table {
        text-align: center;
        width: 100%;
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};
        font-size: 12px;
        color: ${(props) => props.theme.textBase2};
        th {
            padding: 8px;
            border-bottom: 1px solid ${(props) => props.theme.line};
        }
    }
    tbody {
        font-size: 12px;
        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 8px;
            border-bottom: 1px solid ${(props) => props.theme.line};
            vertical-align: middle;
            line-height: 1.3em;
            cursor: ${(props) => props.cursor==='Y'? 'pointer':'auto'};
        }
        tr {
            ${(props) => props.cursor==='Y'? '&:hover,':''}
            &.active {
                background-color: ${(props) => props.theme.activeTable};
            }
        }
    }

}
`