import { combineReducers } from 'redux'
import common from '../actions/common'
import { IAlertType }  from '../../types/alert'
import {
    IDeviceType,
    ISiteInfo,
    IGraphData,
    ISmp,
    IContractType,
    IEstimate,
    IRentalType,
    ISettlementType,
    IMonthlyRentalType,
    IRec, IMonthlyEarnType
} from '../../types/operation'

import alerts from './alerts'
import devices from './operation'
import subhome from './subhome'
import status from './status'
import {IGraphShCate} from "../../types/subhome";
import {IInverterType, INodataType} from '../../types/status'

export type State = {
    alerts : IAlertType[]
    alertsAbnormal : IAlertType[]
    alertsReplacement : IAlertType[]
    devices : IDeviceType[]
    junctions : IDeviceType[]
    selectSid : string
    selectSidCnt : number
    mySids : ISiteInfo[]
    todayPg : string
    yesterdayPg : string
    monthPg : string
    lastPg : string
    todayPgGraph : IGraphData[]
    monthPgGraph : IGraphData[]
    stateG : number
    stateD : number
    stateB : number
    stateS : number
    stateT : number
    alertsPaging : number
    abnormalPaging : number
    mainPaging : number
    replacementCnt : number
    todayRevenue : string
    yesterdayRevenue : string
    monthRevenue : string
    lastMonthRevenue : string
    smpData: ISmp[]
    initContract: IContractType
    contractData: IContractType[]
    runPrice : number
    revenueDetails : IEstimate[]
    rentalData: IRentalType[]
    settlementData: ISettlementType[]
    monthlyRentalData: IMonthlyRentalType[]
    monthlyEarnData: IMonthlyEarnType[]
    recData: IRec[],
    subhome: any,
    subhomeDevice: any,
    subhomeChart: IGraphShCate[],
    report: any,
    status: IInverterType[]
    statusAll: IInverterType[]
    statusAll_j: IInverterType[]
    statusPaging: number
    nodataDevice: INodataType[]
  }
  
export const initialState: State = {
    alerts : [],
    alertsAbnormal : [],
    alertsReplacement : [],
    devices : [],
    junctions: [],
    selectSid : '',
    selectSidCnt : 0,
    mySids : [],
    todayPg : '0',
    yesterdayPg :'0',
    monthPg : '0',
    lastPg : '0',
    todayPgGraph :[],
    monthPgGraph : [],
    stateG : 0,
    stateD : 0,
    stateB : 0,
    stateS : 0,
    stateT : 0,
    alertsPaging : 0,
    abnormalPaging : 0,
    mainPaging : 0,
    replacementCnt : 0,
    todayRevenue : '0',
    yesterdayRevenue : '0',
    monthRevenue : '0',
    lastMonthRevenue : '0',
    smpData : [],
    initContract: {
        id: 0,
        site_id: '',
        name: '',
        smp: 0,
        rec: 0,
        com: '',
        run_volume: '',
        run_price: 0,
        cross_yn: '',
        email: '',
        bill_yn: '',
        bill_date: '',
        bill_num: '',
        sngy_yn: '',
        use_yn: '',
        created_dt: '',
        rec_yn: '',
        rec_price: 0
    },
    contractData : [],
    runPrice : 0,
    revenueDetails : [],
    rentalData : [],
    settlementData : [],
    monthlyRentalData : [],
    monthlyEarnData: [],
    recData : [],
    subhome: {},
    subhomeDevice: {},
    subhomeChart: [],
    report: {
        amount: [],
        time: []
    },
    status: [],
    statusAll: [],
    statusAll_j: [],
    statusPaging : 0,
    nodataDevice: [],
}

const rootReducer = combineReducers({
    common, alerts, devices, subhome, status
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
