import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon8 from '../../assets/img/ico_8.svg'
import Icon8Active from '../../assets/img/ico_8_blue.svg'

export const Nav = styled.nav`
    background-color: ${(props) => props.theme.panel};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    padding: 30px 0 50px 0;
    transition: width 0.5s;
    z-index: 1;
    &:hover {
        width: 200px;
        padding: 30px 0 50px 0;
        .synergy-symbol {
          opacity: 0;
          visibility: hidden;
        }
        .contact {
          opacity: 1;
          visibility: visible;
        }
        .menu-list,
        .logout {
            li {
                width: 100px;
            }
        }
        .pvms-logo {
            max-width: 70px;
        }
    }
    .upper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pvms-logo {
        transition: max-width 0.5s;
        max-width: 50px;
        img {
            width: 100%;
        }
    }

    .menu-list {
        position: absolute;
        top: 130px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        /* margin-top: 80px; */
        li {
            display: flex;
            align-items: center;
            width: 24px;
            height: 28px;
            cursor: pointer;
            overflow: hidden;
            transition: width 0.5s, transform 0.3s;
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            &:hover {
                transform: translateX(5px);
            }
        }
    }
    .synergy-symbol {
        transition: all 0.5s;
        position: absolute;
        left: 40px;
        transform: translateX(-50%);
        bottom: 120px;
        opacity: 1;
        visibility: visible;
        @media screen and (max-height: 900px) {
            display: none;
        }
    }
    .contact {
        transition: all 0.5s;
        position: absolute;
        left: 100px;
        transform: translateX(-50%);
        bottom: 120px;
        width: 130px;
        height: 140px;
        border-radius: 40px;
        background-color: ${(props) => props.theme.navLogoBg};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        line-height: 1.4em;
        color: ${(props) => props.theme.textBase};
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        .synergy-logo {
          margin-bottom: 10px;
          margin-left: 20px;
        }
        .tel {
          color: ${(props) => props.theme.textBase};
        }
        a{
        color: inherit;
            text-align: center;
        }
        @media screen and (max-height: 900px) {
            display: none;
        }
    }
      
    .logout {
        li {
            display: flex;
            align-items: center;
            width: 24px;
            height: 28px;
            cursor: pointer;
            overflow: hidden;
            transition: width 0.5s, transform 0.3s;
            &:hover {
                transform: translateX(5px);
            }
            a {
                position: relative;
                white-space: nowrap;
                width: 100%;
                color: ${(props) => props.theme.panelValue};
                padding-left: 34px;
                &:hover {
                    color: ${(props) => props.theme.active};
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;

                    width: 24px;
                    height: 24px;
                    padding-bottom: 4px;
                }
                &.m8 {
                    &::before {
                        background-image: url(${Icon8});
                        background-repeat: no-repeat;
                        background-size: 24px 24px;
                    }
                    &:hover,
                    &.active {
                        color: ${(props) => props.theme.active};
                        &::before {
                            background-image: url(${Icon8Active});
                        }
                    }
                }
            }
        }
    }
`
export const MenuItem = styled(Link)<{ icon: string; icon_active: string }>`
    position: relative;
    white-space: nowrap;
    width: 100%;
    color: ${(props) => props.theme.textBase};

    padding-left: 34px;
    &:hover {
        color: ${(props) => props.theme.textBlue};
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;

        width: 24px;
        height: 24px;
        padding-bottom: 4px;
    }
    background-repeat: no-repeat;
    background-size: 24px 24px;
    &::before {
        background-image: ${(props) => `url(${props.icon})`};

        background-repeat: no-repeat;
        background-size: 24px 24px;
    }
    &:hover,
    &.active {
        color: ${(props) => props.theme.active};
        &::before {
            background-image: ${(props) => `url(${props.icon_active})`};
            
        }
    }
`
