import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'

import {IGraphShCate} from "../../types/subhome";
import ShChart from "../../components/Chart/ShChart";

interface IProps {
    setChartType:any
    values: IGraphShCate[]
    sendType: string
}

function SubhomeChartCard({ setChartType, values, sendType }: IProps) {

    const handleInputChange = (type: any) => {
        setChartType(type)
    }

    return (
        <Card
            title="발전량"
            girdArea="subhomeChart"
            onClickMore=""
            header={
                <ChartSort>
                    <input type="radio" id="daily" name="chartSort" value="H" onChange={e => handleInputChange("H")}
                           defaultChecked={"H" === sendType}/>
                    <label htmlFor="daily">시간</label>

                    <input type="radio" id="monthly" name="chartSort" value="D"  onChange={e => handleInputChange("D")}
                           defaultChecked={"D" === sendType}/>
                    <label htmlFor="monthly">일</label>
                </ChartSort>
            }
        >
            <ChartConatiner>
                <ShChart chartId="ShChart" chartData={values} toolStr={sendType==='H'?'시':sendType==='D'?'일':'월'}/>
            </ChartConatiner>
        </Card>
    )
}

export default SubhomeChartCard

const ChartConatiner = styled.div`
    @media (max-width: 1440px) {
        height: 250px;
    }
    height: 92%;
    min-height: 400px;
`

const ChartSort = styled.div`
    display: flex;
    margin: 0 0 0 auto;
    align-items: center;
    border: 1px solid ${(props) => props.theme.textBlue};
    border-radius: 6px;

    input[type='radio'] {
        display: none;
    & + label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: ${(props) => props.theme.textBlue};
            padding: 7px 0;
            width: 50px;
            cursor: pointer;
        }
    &:checked {
        & + label {
                background-color: ${(props) => props.theme.textBlue};
                color: white;
            }
        }
    }
}`