import styled from 'styled-components'

export const InnerContainer = styled.div`
    display: grid;
      grid-template-columns: 1fr 1fr 1fr 390px;
      grid-template-rows: 0px 260px minmax(400px, auto) auto;
      grid-template-areas:
        'topbar topbar topbar topbar'
        'totalCost totalCost totalCost detail'
        'settlementContainer settlementContainer settlementContainer detail'
        'settlementContainer settlementContainer settlementContainer detail'
        'settlementContainer settlementContainer settlementContainer detail'
        'settlementContainer settlementContainer settlementContainer detail';
      gap: 20px;
      @media (max-width: 1440px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          'topbar'
          'totalCost'
          'detail'
          'settlementContainer';
      }
`
