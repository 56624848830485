import styled from 'styled-components'

export const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0px 170px minmax(400px, auto) auto;
  grid-template-areas:
    'topbar topbar topbar'
    'monthProfit todayProfit contract'
    'energyChart energyChart contract'
    'smpTable smpTable recChart'
    'smpTable smpTable banner';
  gap: 20px;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'topbar topbar'
      'monthProfit todayProfit'
      'energyChart energyChart'
      'smpTable smpTable'
      'contract contract'
      'recChart banner';
  }
`
