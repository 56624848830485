import React, {useState} from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import InverterSubInfo from '../../components/Status/InverterSubInfo'
import Table, {TableBody} from '../../components/Table/Table'

import ICGood from '../../assets/img/ic_good.svg'
import ICGoodDark from '../../assets/img/ic_good_dark.svg'
import ICStandard from '../../assets/img/ic_standard.svg'
import ICBad from '../../assets/img/ic_bad.svg'

import {numberWithCommas, numberWithCommasNumberType} from '../../lib/asyncUtils'
import useTheme from "../../lib/useTheme";
import ModalStatus from '../../components/ModalStatus'
import { useDispatch, useSelector } from 'react-redux'
import { readInverterInfo } from '../../store/actions/status'
import { IInverterType } from '../../types/status'
import { State } from '../../store/reducers'

interface InverterStatusFull {
    name: string
    state: string
    capacity: string
    isBroken?: boolean
    input_v: number
    input_i: number
    dc: string
    output_v_ab: number
    output_v_bc: number
    output_v_ac: number
    output_i_a: number
    output_i_b: number
    output_i_c: number
    ac: string
    today_accu_pg: number
    total_accu_pg: number
    today_accu_pg_time: number
    site_id: string
    id: string
    uuid: string
    sid: string
}

interface IProps {
    values: InverterStatusFull[]
    subInfo: {
        name: string
        goodNum: number
        normalNum: number
        badNum: number
    }
    info: IInverterType[]
}
const mappingStatusToIcon: { [index: string]: string } = {
    G: ICGood,
    D: ICStandard,
    B: ICBad,
}
const mappingStatusToIconDark: { [index: string]: string } = {
    G: ICGoodDark,
    D: ICStandard,
    B: ICBad,
}

function InverterStatusCard({ values, subInfo, info }: IProps) {

    const [checkType, setCheckType] = useState("G");
    const [modalVisible, setModalVisible] = useState(false);
    const [theme] = useTheme(); // 화면모드
    const [modalData, setModalData] = useState({
        site: '',
        device: '',
        inverter_id: '',
        sid: ''
    })
    const dispatch = useDispatch();

    const listCount = (value: InverterStatusFull) => {
        return info.filter(item => item.site === value.site_id && item.inverter_id+"" === value.id).length;
    }

    const [first, second, third] = makeThridPart(
        values.sort((a: InverterStatusFull, b: InverterStatusFull) => a.name > b.name ? 1: -1).map((value) => ({
            name: {
                content: (
                    <>
                        {value.name}
                        {value.isBroken && <em>(교체필요)</em>}
                    </>
                ),
            },
            state: { content: value.state===null || value.state==='S'?'중지':<img src={theme==='light'?mappingStatusToIcon[value.state]:mappingStatusToIconDark[value.state]} alt={value.state} /> },
            capacity: { content: value.capacity==='NaN' || value.capacity===null?'-' : value.capacity==='Infinity' || Number(value.capacity) >= 100 ? '100' : value.capacity },
            status: { 
                content: <button onClick={() => modalView(value.site_id, value.id, value.uuid, value.sid)}>세부사항
                    { listCount(value) === 0 ? '' : <span>{listCount(value)}</span> }
                </button> },
            
        }))
    )

    const [longPart] = makeLongPart(
        values.sort((a: InverterStatusFull, b: InverterStatusFull) => a.name > b.name ? 1: -1).map((value) => ({
            name: {
                content: (
                    <>
                        {value.name}
                        {value.isBroken && <em>(교체필요)</em>}
                    </>
                ),
            },
            state: { content: value.state===null || value.state==='S'?'중지':<img src={theme==='light'?mappingStatusToIcon[value.state]:mappingStatusToIconDark[value.state]} alt={value.state} /> },
            capacity: { content: value.capacity==='NaN' || value.capacity===null ? '-' : value.capacity==='Infinity' || Number(value.capacity) >= 100 ? '100' : value.capacity },
            input_v: { content: numberWithCommasNumberType(value.input_v) },
            input_i: { content: numberWithCommasNumberType(value.input_i) },
            dc: { content: value.dc==='NaN' || value.dc===null?'-' : numberWithCommas(value.dc,1) },
            output_v_ab: { content: numberWithCommasNumberType(value.output_v_ab) },
            output_v_bc: { content: numberWithCommasNumberType(value.output_v_bc) },
            output_v_ac: { content: numberWithCommasNumberType(value.output_v_ac) },
            output_i_a: { content: numberWithCommasNumberType(value.output_i_a) },
            output_i_b: { content: numberWithCommasNumberType(value.output_i_b) },
            output_i_c: { content: numberWithCommasNumberType(value.output_i_c) },
            ac: { content: value.ac==='NaN' || value.ac===null?'-' : numberWithCommas(value.ac,1) },
            today_accu_pg: {content: numberWithCommasNumberType(value.today_accu_pg), className: 'blue-bord'},
            total_accu_pg: { content: numberWithCommasNumberType(value.total_accu_pg) },
            status: { 
                content: <button onClick={() => modalView(value.site_id, value.id, value.uuid, value.sid)}>세부사항
                    { listCount(value) === 0 ? '' : <span>{listCount(value)}</span> }
                </button> },
            /*today_accu_pg_time: { content: numberWithCommasNumberType(value.today_accu_pg_time), className: 'blue-bord' },*/
        }))
    )

    const modalView = async (site: string, inverter_id: string, device: string, sid: string) => {
        await dispatch(readInverterInfo(site, inverter_id));
        setModalData({
            site,
            device,
            inverter_id,
            sid
        })
        setModalVisible(true);
    }

    return (
        <Card title="인버터 상태" girdArea="fault" onClickMore="" header="">
            <InverterSubInfo
                name={subInfo.name}
                goodNum={subInfo.goodNum}
                normalNum={subInfo.normalNum}
                badNum={subInfo.badNum}
                checkType={checkType}
                setCheckType={setCheckType}
            />
            {checkType==='G' && <TableContainer>
                <Table header={header} body={first} clickAble={false}/>
                <Table header={header} body={second} clickAble={false} />
                <Table header={header} body={third} clickAble={false} />
            </TableContainer>}
            {checkType==='L' && <TableLongContainer height={values.length}>
                <Table header={headerLong} body={longPart} clickAble={false} />
            </TableLongContainer>}
            { modalVisible && <ModalStatus setModalVisible={setModalVisible} modalData={modalData} type="3" ></ModalStatus> }
        </Card>
    )
}
const header = [
    { name: '이름', key: 'name' },
    { name: '상태', key: 'state' },
    { name: '가동율', key: 'capacity' },
    { name: '세부사항', key: 'status'},
]
const headerLong = [
    { name: '이름', key: 'name' },
    { name: '상태', key: 'state' },
    { name: '가동율', key: 'capacity' },
    { name: '입력전압(V)', key: 'input_v' },
    { name: '입력전류(A)', key: 'input_i' },
    { name: '입력전력(kW)', key: 'dc' },
    { name: 'R상전압(V)', key: 'output_v_ab' },
    { name: 'S상전압(V)', key: 'output_v_bc' },
    { name: 'T상전압(V)', key: 'output_v_ac' },
    { name: 'R상전류(A)', key: 'output_i_a' },
    { name: 'S상전류(A)', key: 'output_i_b' },
    { name: 'T상전류(A)', key: 'output_i_c' },
    { name: '전력(kW)', key: 'ac' },
    { name: '금일발전량(kWh)', key: 'today_accu_pg' },
    { name: '누적발전량(kWh)', key: 'total_accu_pg' },
    { name: '세부사항', key: 'status' },
    /*{ name: '금일발전시간', key: 'today_accu_pg_time' },*/
]
const makeLongPart = (arr: TableBody[]) => {
    const newArr = arr.concat(
        Array()
            .fill(undefined)
            .map((_) => ({
                name: { content: '-' },
                state: { content: '-' },
                capacity: { content: '-' },
                input_v: { content: '-' },
                input_i: { content: '-' },
                dc: { content: '-' },
                output_v_ab: { content: '-' },
                output_v_bc: { content: '-' },
                output_v_ac: { content: '-' },
                output_i_a: { content: '-' },
                output_i_b: { content: '-' },
                output_i_c: { content: '-' },
                ac: { content: '-' },
                today_accu_pg: {content: '-'},
                total_accu_pg: { content: '-' },
                today_accu_pg_time: { content: '-' },
                total_accu_pg_time: { content: '-' },
                status: {content: '-'},
            }))
    )
    const longPart = newArr
    return [longPart]
}

const makeThridPart = (arr: TableBody[]) => {
    let tempLength = arr.length
    let len = tempLength % 3
    if(len === 1) len = 2
    if(len === 2) len = 1

    const newArr = arr.concat(
        Array(len)
            .fill(undefined)
            .map((_) => ({
                name: { content: '-' },
                state: { content: '-' },
                capacity: { content: '-' },
                status: {content: '-'},
            }))
    )
    let firstPart: TableBody[] = [], secondPart: TableBody[] = [], thirdPart: TableBody[] = [];
    newArr.forEach((element, index) => {
        if(index % 3 === 0){
            firstPart.push(element)
        }else if(index % 3 === 1){
            secondPart.push(element)
        }else {
            thirdPart.push(element)
        }
    })

    return [firstPart, secondPart, thirdPart]
}

export default InverterStatusCard

const TableContainer = styled.div`
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    overflow-x: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    white-space: nowrap;
    width: 100%;

    @media (max-width: 1320px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    @media (max-width: 960px) {
        grid-template-columns: 1fr;
    }

    table {
        text-align: center;
        width: 100%;
        &.middle-table {
            border-left: 1px solid ${(props) => props.theme.mainBase};
            border-right: 1px solid ${(props) => props.theme.mainBase};
        }
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};

        font-size: 14px;
       
        color: ${(props) => props.theme.panelSubLabel};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;
        tr {
            height: 50px;
        }

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 0;
            border-bottom: 1px solid ${(props) => props.theme.line};
            vertical-align: middle;
            &.inverter-status {
                width: 130px;
            }
            &.inverter-rate {
                width: 130px;
            }
            &.check-complete {
                color: #fb6232;
            }
            &.work-complete {
                color: #03a93c;
            }
            &.blue-bord {
                color: #03a93c;
            }
            em {
                font-size: 13px;
                color: #ef1212;
            }
            img {
                display: inline;
            }
            button {
                position: relative;
                margin: 0 auto;
                padding: 6px 8px;
                border-radius: 4px;
                background-color: ${(props) => props.theme.mainBase};
                color: ${(props) => props.theme.tableTxt};

                span {
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    width: 16px;
                    height: 16px;
                    background-color: #03a93c;
                    color: #fff;
                    border-radius: 8px;
                    line-height: 16px;
                }
            }
            button:hover {
                color: #fff;
                background-color: #2c58bb;
            }
        }
    }
`

const TableLongContainer = styled.div< { height: number } >`
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    overflow-x: auto;
    display: grid;
    grid-template-columns: 1fr;
    white-space: nowrap;
    width: 100%;
    height: calc(55px + 50px * ${(props) => props.height});

    div {
        position: relative;
    }
    table {
        position: absolute;
        text-align: center;
        width: 100%;
        min-width: 1067px;
        &.middle-table {
            border-left: 1px solid ${(props) => props.theme.mainBase};
            border-right: 1px solid ${(props) => props.theme.mainBase};
        }
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};

        font-size: 14px;
       
        color: ${(props) => props.theme.panelSubLabel};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;
        tr {
            height: 50px;
        }

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 0;
            border-bottom: 1px solid ${(props) => props.theme.line};
            vertical-align: middle;
            &.inverter-status {
                width: 130px;
            }
            &.inverter-rate {
                width: 130px;
            }
            &.check-complete {
                color: #fb6232;
            }
            &.work-complete {
                color: #03a93c;
            }
            &.blue-bord {
                color: #03a93c;
            }
            em {
                font-size: 13px;
                color: #ef1212;
            }
            img {
                display: inline;
            }
            button {
                position: relative;
                margin: 0 auto;
                padding: 6px 8px;
                border-radius: 4px;
                background-color: ${(props) => props.theme.mainBase};
                color: ${(props) => props.theme.tableTxt};

                span {
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    width: 16px;
                    height: 16px;
                    background-color: #03a93c;
                    color: #fff;
                    border-radius: 8px;
                    line-height: 16px;
                }
            }
            button:hover {
                color: #fff;
                background-color: #2c58bb;
            }
        }
    }
`
