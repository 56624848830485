import React, {useEffect, useState} from 'react'
import {DiagramContainer, DiagramSummary} from './Diagram.style'
import createEngine, {
    DiagramModel,
    DefaultPortModel,
} from '@projectstorm/react-diagrams'
import {CanvasWidget} from '@projectstorm/react-canvas-core'
import {AdvancedLinkFactory, AdvancedPortModel, DiagramState} from "../../lib/diagramUtil"
import {customNodeModel} from "../../lib/customNodeModel"
import Marquee from "react-fast-marquee";

interface IProps {
    values: any
    selectDate: string
}

interface IAbnormalData {
    msg: string
}

function Diagram({values, selectDate}: IProps) {
    const [diagramEngine, setDiagramEngine] = useState<any>()
    const [abnormalData, setAbnormalData] = useState<IAbnormalData[]>([])

    useEffect(() => {
        let data: IAbnormalData[] = []
        // @ts-ignore
        let findClass = document.getElementsByClassName('css-18f0176')
        if(findClass.length > 0){
            document.getElementsByClassName('css-18f0176')[0].scrollTop = 0
        }
        const hours = new Date().getHours();
        
        const ports: { link: (arg0: DefaultPortModel) => any; }[] | DefaultPortModel[] = []
        var engine = createEngine();
        engine.getLinkFactories().registerFactory(new AdvancedLinkFactory())
        engine.getStateMachine().pushState(new DiagramState())
        var model = new DiagramModel();

        if(values.length > 0) {
            let checkInverter = 0
            let xAdd = 390
            let xStringAdd = 0

            let stringSum = 0;
            let stringCount = 0;
            values.map((item: any) => {
                if(item.state === 'S'){
                    stringSum += item.input_p;
                    stringCount++;
                }
            })
            let stringAvg = stringSum / stringCount;

            values.map((item: any, index:number) => {
                if(item.state === 'T'){
                    let node = new customNodeModel({name: '발전소',
                        color: 'rgb(0,150,228)', value:item.id, date:selectDate})
                    node.setPosition(100, 20)
                    // @ts-ignore
                    let port = node.addPort(new AdvancedPortModel(false, 'A', '발전 : '+item.today_accu_pg))
                    ports.push(port)
                    model.addAll(node)
                }else if(item.state === 'I'){
                    checkInverter++
                    let node = new customNodeModel({
                        name: item.name,
                        color: 'rgb(0,150,228)', value:item.id, date:selectDate
                    })
                    
                    let id = item.id.substr(0,1)
                    node.setPosition(100 + (id - 1) * xAdd, 120)
                    
                    // @ts-ignore
                    let port = node.addPort(new AdvancedPortModel(false, 'B', '발전 : '+item.today_accu_pg))
                    // @ts-ignore
                    node.addOutPort('전압 : '+item.input_v.toFixed(1))
                    // @ts-ignore
                    node.addOutPort('전류 : '+item.input_i.toFixed(1))
                    // @ts-ignore
                    node.addOutPort('전력 : '+item.input_p.toFixed(1))
                    let link = ports[0].link(port)
                    ports.push(port)
                    model.addAll(node, link)
                }else if(item.state === 'J') {
                    let node = new customNodeModel({name:item.name, color:'rgb(0,204,226)', value:item.id, date:selectDate})
                    // @ts-ignore
                    let port = node.addPort(new AdvancedPortModel(false, 'C', '전압 : '+item.input_v.toFixed(1)))
                    // @ts-ignore
                    node.addOutPort('전류 : '+item.input_i.toFixed(1))
                    // @ts-ignore
                    node.addOutPort('전력 : '+item.input_p.toFixed(1))

                    let id = parseInt(item.id.substr(0,1));
                    node.setPosition(100 + (xAdd * (id - 1)), 300);
                    
                    let link = ports[Number(item.id.substr(0,1))].link(port)
                    ports.push(port)
                    model.addAll(node, link)
                }else if(item.state === 'S') {
                    //console.log(item.id.substr(-1))
                    //let node = new DefaultNodeModel(item.name, 'rgb(108,250,205)');
                    let color;
                    
                    if ( item.input_i.toFixed(1) < 1 && item.input_p.toFixed(1) < 1) {
                        color = 'rgb(200,200,200)';
                        if(8 < hours && hours < 18) {
                            const temp: IAbnormalData = {
                                msg: `접속반${item.id.substr(1, 1)}-스트링${item.id.substr(2)} 발전 중지`
                            }
                            data.push(temp)
                        }
                    } else if ( item.input_p < stringAvg * 0.3 ) {
                        color = 'rgb(251,98,50)';
                        if(8 < hours && hours < 18) {
                            const temp: IAbnormalData = {
                                msg: `접속반${item.id.substr(1, 1)}-스트링${item.id.substr(2)} 평균이하 발전`
                            }
                            data.push(temp)
                        }
                    } else {
                        color = 'rgb(108,250,205)';
                    }
                    let node = new customNodeModel({name:item.name, color:color, value:item.id, date:selectDate});
                    
                    // @ts-ignore
                    let port = node.addInPort('전압 : '+item.input_v.toFixed(1))
                    // @ts-ignore
                    node.addInPort('전류 : '+item.input_i.toFixed(1))
                    // @ts-ignore
                    node.addInPort('전력 : '+item.input_p.toFixed(1))

                    let id = item.id.substr(0,1)
                    xStringAdd = xAdd * (id - 1)

                    id = item.id.substr(2)

                    let x = id % 2 === 0 ? 280 : 50
                    let y = 450 + 150 * ( Math.round(id / 2) - 1 )
                    node.setPosition(x + xStringAdd, y)

                    let link = ports[Number(item.id.substr(0,1))+checkInverter].link(port)
                    ports.push(port)
                    if ( item.input_i.toFixed(1) < 1 && item.input_p.toFixed(1) < 1) {
                        model.addAll(node)
                    } else {
                        model.addAll(node, link)
                    }
                }

            })
        }
        engine.setModel(model)
        setAbnormalData(data)

        setDiagramEngine(engine)
    },[values]);

    return (
        <DiagramContainer>
            <DiagramSummary>
                <div className="status">
                    <div className="good">스트링 정상</div>
                    <div className="bad">평균이하</div>
                    <div className="stop">미발전</div>
                </div>
                <Marquee className="marqueeText" gradient={false} speed={50}>
                    {abnormalData.length !== 0
                        ? abnormalData.map((item: IAbnormalData, key: number) => (
                            <span className="text"
                                  key={key}>{item.msg}</span>
                        ))
                        : <span className="text">이상없음(오전 9시 ~ 오후 5시 감시)</span>
                    }
                </Marquee>
            </DiagramSummary>
            {diagramEngine && <CanvasWidget engine={diagramEngine}  />}
        </DiagramContainer>
    )
}

export default Diagram
