import instance from 'api'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie/es6'
import useTheme from 'lib/useTheme'
import { IGraphShCate } from 'types/subhome'

import ShModalChart from '../Chart/ShModalChart'
import ShModalChartPg from '../Chart/ShModalChartPg'
import ReactTableModal from '../MaterialTable/ReactTableModal'
import ReactTableModalPg from '../MaterialTable/ReactTableModalPg'
import { ModalSubhomeDiv, ButtonClose } from './ModalSubhome.style'

// const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

interface Props {
    selectId: string
    selectDate: string
}
// 해당 페이지는 redux가 아닌 api 데이터 호출로 제작 필요
// 이유는 렌더링을 root에 한 것이 아닌 따로 diagram을 렌더링 하였기에 전역 사용 불가
function ModalSubhome(props: Props) {
    const [theme] = useTheme() // 화면모드
    const [showTitle, setShowTitle] = useState<string>('')
    const [deviceData, setDeviceData] = useState<any>()
    const [graphData, setGraphData] = useState<IGraphShCate[]>([])
    const [inverterCnt, setInverterCnt] = useState<number>(1)

    useEffect(() => {
        // @ts-ignore
        let findClass = document.getElementsByClassName('modal-body')
        if (findClass.length > 0) {
            document.getElementsByClassName('modal-body')[0].scrollTop = 0
        }
        if (props.selectId === '0') {
            getPgGraph(props.selectDate)
            setShowTitle('발전소')
        } else if (props.selectId.length === 1) {
            //inverter
            getPgInverter(props.selectDate)
            setShowTitle(`인버터 ${props.selectId.substr(-1)}`)
        } else if (props.selectId.length === 2) {
            //junction
            getPgJunction(props.selectDate)
            setShowTitle(`접속반 ${props.selectId.substr(-1)}`)
        } else {
            //string
            getPgJunction(props.selectDate)
            setShowTitle(`접속반 ${props.selectId.substr(1, 1)} - 스트링 ${props.selectId.substr(2)}`)
        }

        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                closeBtn()
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [props])

    const closeBtn = () => {
        // @ts-ignore
        document.getElementById('diagramShowModal').style.display = 'none'
    }

    const getPgGraph = async (paramDate: string) => {
        const params = { selectDate: paramDate }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return
        const res = await instance(`/api/subhomeModalGraph/${selected_sid}`, { params: params })
        setGraphData(res.data.graph)
        setInverterCnt(res.data.inverter)
    }

    const getPgInverter = async (paramDate: string) => {
        const params = { selectDate: paramDate, slaveId: props.selectId }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return
        const res = await instance(`/api/subhomeInverter/${selected_sid}`, { params: params })
        setDeviceData(res.data)
    }

    const getPgJunction = async (paramDate: string) => {
        const params = { selectDate: paramDate, slaveId: props.selectId }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return
        const res = await instance(`/api/subhomeJunction/${selected_sid}`, { params: params })
        setDeviceData(res.data)
    }

    return (
        <ModalSubhomeDiv themeMode={theme}>
            <div className='modal'>
                <div className='modal-header'>
                    <div className='sub'>{showTitle}</div>
                    <ButtonClose onClick={() => closeBtn()} themeMode={theme}></ButtonClose>
                </div>
                <div className='modal-body'>
                    {props.selectId === '0' && (
                        <ShModalChartPg
                            chartId='ShModalChart'
                            chartData={graphData}
                            inverter={inverterCnt}></ShModalChartPg>
                    )}
                    {props.selectId === '0' && (
                        <ReactTableModalPg values={graphData} inverter={inverterCnt} selectDate={props.selectDate} />
                    )}
                    {props.selectId !== '0' && (
                        <ShModalChart chartId='ShModalChart' chartData={deviceData}></ShModalChart>
                    )}
                    {props.selectId !== '0' && <ReactTableModal values={deviceData} />}
                </div>
            </div>
        </ModalSubhomeDiv>
    )
}

export default ModalSubhome
