import styled from 'styled-components'

export const Container = styled.div`
  grid-area: reportPanel;
  margin-top: 20px;
`

export const ChartAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChartSort = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.mainBlue};
    border-radius: 6px;
    overflow: hidden;

    input[type='radio'] {
        display: none;
        & + label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: ${(props) => props.theme.titleBlue};
            padding: 7px 0;
            width: 50px;
            cursor: pointer;
            background-color: ${(props) => props.theme.chartSortRadioBg};
            &.middle {
                border-left: 1px solid ${(props) => props.theme.mainBlue};
                border-right: 1px solid ${(props) => props.theme.mainBlue};
            }
        }
        &:checked {
            & + label {
                    background-color: ${(props) => props.theme.mainBlue};
                    color: white;
            }
        }
    }
    
}`

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 36px;
  padding: 0 15px;
  color: ${(props) => props.theme.grayBtnText};
  border-radius: 3px;
  margin: 0 2px;
  background-color: ${(props) => props.theme.grayBtn};
  &:hover {
    background-color: ${(props) => props.theme.grayBtn_hover};
  }
  span {
    display: flex;
    align-items: center;
  }
  .icon {
    margin-right: 10px;
  }
`