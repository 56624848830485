import React from 'react'

import useTheme from "../../lib/useTheme";
import Card from "../../components/Card/Card";
import ReactTable from "../../components/MaterialTable/ReactTable";

interface IProps {
    values: any
    selectDate:string
}

function SubhomeTable({ values, selectDate }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <Card title="연결 상태" girdArea="subhomeTable" onClickMore="" header="">
            <ReactTable values={values} selectDate={selectDate}/>
        </Card>
    )
}

export default SubhomeTable
