import Swal from 'sweetalert2'
import Cookies from 'universal-cookie/es6'
import jwtDecode from 'jwt-decode'
import useCookie from 'hooks/useCookie'
import queryString from 'query-string'
import { Location } from 'history'
import { fetchAccessToken, getSid as getSiteIdAPI, fetchRefreshToken } from 'api/Login'

/*
1. accessToken(SNGY_TOKEN)이 있는 경우
    1-1. scope에 'PVMS'가 있는 경우(decodeToken)
        ---> 로그인 성공!
    1-2. scope에 'PVMS'가 없는 경우
        ---> 서비스 접근 권한이 없는 것으로 확인
        ---> 로그아웃 처리

2. accessToken(SNGY_TOKEN)이 없는 경우
    2-1. 현재 URI에 code가 있는 경우(query-string)
        ---> 로그인을 완료하여 auth에서 redirect 된 것으로 확인
        ---> PVMS OAuth Login api를 호출하여 code와 token을 교환, cookie에 저장
    2-2. 현재 URI에 code가 없는 경우
        ---> 최초 로그인으로 확인
        ---> auth로 redirect, 로그인 진행
            ---> (2-1)의 로직이 실행됨
*/

const getCookie = useCookie
const [getAccessToken, setAccessToken, removeAccessToken] = getCookie('SNGY_TOKEN')

export const onAuthorize = async (location: Location) => {
    const accessToken = getToken()
    const { code } = queryString.parse(location.search)

    try {
        if (!accessToken) {
            /* FEMS OAuth Login (code를 통해 access_token을 발급받는다.) */
            if (code && location.pathname === '/login') {
                const res = await fetchAccessToken(code as string)
                /* 발급받은 access_token을 쿠키에 저장하고, sid를 불러온다. */
                setAccessToken(res.data.token)
                await getSiteIdAPI(res.data.token)
                return true
            }

            /* accessToken(SNGY_TOKEN)과 code가 모두 없을 경우 로그인 페이지로 redirect 한다. (최초 로그인) */
            fetchCode()
            return false
        }

        const scope = [...decodeToken(accessToken).scope]
        if (scope.includes('PVMS')) return true

        Swal.fire({
            icon: 'warning',
            title: '서비스 접근 권한이 없습니다.',
            text: '로그인 페이지로 돌아갑니다. 관리자에게 문의해주세요.',
            confirmButtonText: '확인',
        }).then(async () => {
            await onLogout()
        })
        return false
    } catch (err: any) {
        /* 로그인 과정 중 에러가 발생하면 accessToken 삭제 및 Logout 처리를 진행하고 로그인 페이지로 redirect 한다. */
        Swal.fire({
            icon: 'error',
            title: '로그인 중 문제가 발생하였습니다.',
            text: '로그인 페이지로 돌아갑니다. 관리자에게 문의해주세요.',
        }).then(async () => {
            await onLogout()
        })
    }
}

export const getToken = () => {
    return getAccessToken() || null
}

export const setSid = async (sid: string) => {
    const cookies = new Cookies()
    cookies.set('sid', sid)
    cookies.set('selected_sid', sid)
}

export const fetchCode = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}/login&response_type=code`
}

export const decodeToken = (token: string) => {
    const decoded: { authorities: string[]; user_name: string; scope: string[] } = jwtDecode(token)
    return { authority: decoded.authorities[0], username: decoded.user_name, scope: decoded.scope }
}

export const onLogout = async () => {
    const cookies = new Cookies()
    cookies.remove('sid')
    cookies.remove('selected_sid')
    removeAccessToken()
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`
}

// export const onAuthorize401Error = () => {
//     removeAccessToken()
//     fetchCode()
// }

export const onAuthorize401Refresh = async () => {
    try {
        const res = await fetchRefreshToken()
        setAccessToken(res.data.token)
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        onLogout()
    }
}
