import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import { IGraphDataCate} from "../../types/operation"
import ReChart from "../../components/Chart/ReChart"

interface IProps {
    type: string
    sendChartData: IGraphDataCate[]
}
function ReportChartCard({type, sendChartData}:IProps) {

    return (
        <Card
            title="발전량"
            girdArea="energyChart"
            onClickMore=""
        >
            <ChartConatiner>
                <ReChart chartId="reportChart" chartData={sendChartData} toolStr={type==='H'?'시':type==='D'?'일':'월'}/>
            </ChartConatiner>
        </Card>
    )
}

export default ReportChartCard

const ChartConatiner = styled.div`
    min-height: 400px;
    height: 92%;
`

