import styled from 'styled-components'

export const DiagramContainer = styled.div`
    height: 100%;
    width: 100%;
    min-width: 1600px;
    min-height: 1100px;
    display: grid;
    padding-bottom: 60px;
    grid-template-rows: 30px auto;
    .node div {
        font-size: 18px;
        padding: 2px;
        max-width: 150px;
    }
    .css-18f0176 {
        cursor: default;
        overflow: auto;
    }
    .css-11bc0l7 {
        display: none;
    }
    .css-12q0bj3 {
        transform: translate(0px, 0px) scale(1) !important;

        .node {
            cursor: pointer;
        }
    }
`

export const DiagramSummary = styled.div`
    height: 20px;
    width: 100%;
    line-height: 20px;
    display: flex;    
    
    .title {
        margin-left: 50px;
        padding: 0px;
        font-size: 18px;
        width: 100px;
        color: ${(props) => props.theme.textBase};
        border-right: 1px solid ${(props) => props.theme.subBase};
    }

    .marqueeText {
        margin-left: 50px;
        cursor: default;
        width: 100%;
        max-width: 700px;
        .marquee {
            font-size: 18px;
            .text {
                margin-right: 100px;
                color: ${(props) => (props.theme.textBase)};
                
                span {
                    color: ${(props) => (props.theme.textOrange)};
                }
            } 
        }
    }
    
    .status {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0 30px;
        font-size: 17px;
        white-space: nowrap;
        .good {
            position: relative;
            color: #6CFACD;
        }
        .bad {
            position: relative;
            color: #FB6232;
        }
        .stop {
            position: relative;
            color: #C8C8C8;
            padding-right: 34px;
        }
    }
    
`
