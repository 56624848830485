import styled from 'styled-components'

export const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'topbar topbar'
        'contract detail'
        'contract detail'
        'contract detail';
    gap: 20px;    
`

