import React, { useEffect, useState} from 'react'
import { InnerContainer } from './Settings.style'
import TopBar from "../../components/TopBar/TopBar";
import {contractData, mySids, rentalRead} from "../../store/actions/operation";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/reducers";
import ContractTable from "../../components/Contract/Contract";
import SettingDetailsCard from "../../templates/Settings/SettingDetailsCard";
// @ts-ignore
import {useAnalytics} from "../../lib/googleAnalytics";

function SettingsPresenter() {
    useAnalytics('Settings')
    const dispatch = useDispatch();
    const deviceIndex: any = useSelector((state:State) => state.devices);

    const [selectSiteId, setSelectSiteId] = useState('0')
    const [load, setLoad] = useState(true)

    useEffect(() => {
        if(deviceIndex.mySids.length <= 0){
            dispatch(mySids())
        }
        if(load) {
            dispatch(contractData())
            setLoad(false)
        }
        //장비임대비
        dispatch(rentalRead(selectSiteId))

    }, [selectSiteId])

    const initPage = () =>{

        dispatch(contractData())

        if(deviceIndex.contractData.length > 0){
            setSelectSiteId(deviceIndex.contractData[0].site_id)
        }
    }

    return (
        <><TopBar timerOff={true} initPage={initPage}  allSid={deviceIndex.mySids}/>
            <InnerContainer>
                <ContractTable values={deviceIndex.contractData} clickEdit={true} selectSiteId={selectSiteId} setSelectSiteId={setSelectSiteId} clickAble='Y'/>
                <SettingDetailsCard values={deviceIndex.contractData} rentalItems={deviceIndex.rentalData} selectSiteId={selectSiteId}/>
            </InnerContainer>
        </>
    )
}

export default SettingsPresenter
