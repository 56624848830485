import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import errorImg from 'assets/img/common/error.svg'

function NotFound() {
    return (
        <MiscWrapper>
            <div className='misc-inner p-2 p-sm-3'>
                <div className='text-center'>
                    <h2 className='mb-1'>페이지가 없거나, 데이터 호출 중 문제가 발생하였습니다.️</h2>
                    <h3 className='mb-2'>홈으로 이동하시면 다시 서비스가 동작합니다.</h3>
                    <div className='text-center'>
                        <Link to='/'>
                            <Button className='btn-sm-block mb-2'>홈으로 이동</Button>
                        </Link>
                    </div>
                    <img className='img-fluid' src={errorImg} alt='Error page' />
                </div>
            </div>
        </MiscWrapper>
    )
}

export default NotFound

const Button = styled.button`
    background-color: rgb(18, 54, 147);
    color: white;
    font-size: 16px;
    border-radius: 3px;
    margin-bottom: 1rem !important;
    display: inline-block;
    text-align: center;
    padding: 0.786rem 1.5rem;
    font-weight: 500;
    &:hover {
        background-color: rgb(18, 54, 147, 0.8);
    }
`

const MiscWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;

    .text-center {
        text-align: center !important;
    }

    h2,
    h3 {
        font-size: 1.4em;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: #5e5873;
    }
    .mb-1 {
        margin-bottom: 1rem !important;
    }
    .mb-2 {
        margin-bottom: 1.5rem !important;
    }
`
