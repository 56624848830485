import React from 'react'
import { MainContainer } from './MainPanel.style'
import CountUp from 'react-countup';
import {numberWithUnCommas} from "../../lib/asyncUtils";

interface IProps {
    title: string
    gridArea: string
    bgIcon: string
    pastCompared: string
    valueCurrent: string
    valuePast: string
    unit: string
    bgColor?: string
    upDown: 'up' | 'down' | 'no'
}
function MainPanel({ gridArea, title, bgIcon, bgColor, pastCompared, unit, valueCurrent, valuePast, upDown }: IProps) {
    return (
        <MainContainer gridArea={gridArea} backIcon={bgIcon} bgColor={bgColor}>
            <div className="title small">{title}</div>
            <div className="value">
                {bgColor && <CountUp className='cnt' start={numberWithUnCommas(valueCurrent)*0.95} end={numberWithUnCommas(valueCurrent)} duration={2.75} separator="," decimals={1}/>}
                {!bgColor && valueCurrent}
                <span>{unit}</span>
            </div>
            <dl className={upDown}>
                <dt>{pastCompared}</dt>
                <dd>
                    {valuePast}
                    {pastCompared==='전력' ? '%':valuePast!==''? unit:''}
                </dd>
            </dl>
        </MainContainer>
    )
}

export default MainPanel
