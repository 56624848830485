import types from './actionTypes'
import Cookies from 'universal-cookie/es6'
import instance from 'api'
import { IAlertType } from 'types/alert'
import { ThunkDispatch } from 'redux-thunk'

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

const SERVICE_PATH = `${API_BASE}/api/alert`

export type CreateAlert = {
    type: typeof types.CREATE_ALERT
    data: IAlertType
}

export type ReadAlerts = {
    type: typeof types.READ_ALERT
    data: IAlertType[]
    paging: number
}

export type UpdateAlert = {
    type: typeof types.UPDATE_ALERT
    data: { id: IAlertType['id']; title: IAlertType['title'] }
}

export type ReadAlertsAbnormal = {
    type: typeof types.READ_ALERT_ABNORMAL
    data: IAlertType[]
    paging: number
}

export type ReadAlertsReplacement = {
    type: typeof types.READ_ALERT_REPLACEMENT
    data: IAlertType[]
    total: number
    paging: number
}

export type UpdateAlertProcess = {
    type: typeof types.UPDATE_ALERT_PROCESS
}

export type UpdateAlertComment = {
    type: typeof types.UPDATE_ALERT_COMMENT
}

export type Actions =
    | CreateAlert
    | ReadAlerts
    | UpdateAlert
    | ReadAlertsAbnormal
    | ReadAlertsReplacement
    | UpdateAlertProcess
    | UpdateAlertComment

export const createAlert = (data: IAlertType) => async (dispatch: ThunkDispatch<any, any, CreateAlert>) => {
    await instance.post(`${SERVICE_PATH}`, data)
    dispatch({ type: types.CREATE_ALERT, data })
}

export const readAlerts = (page: number) => async (dispatch: ThunkDispatch<any, any, ReadAlerts>) => {
    const params = { page: page, grp: 'earn' }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH}Site/${selected_sid}`, { params: params })
    //console.log("readAlert : ",res.data.content);
    dispatch({ type: types.READ_ALERT, data: res.data.content, paging: res.data.total_pages })
}

export const updateAlert = (data: UpdateAlert['data']) => async (dispatch: ThunkDispatch<any, any, UpdateAlert>) => {
    await instance.put(`${SERVICE_PATH}/${data.id}`, data)
    dispatch({ type: types.UPDATE_ALERT, data })
}

export const readAlertsAbnormal = (page: number) => async (dispatch: ThunkDispatch<any, any, ReadAlertsAbnormal>) => {
    const params = { page: page, grp: 'dash' }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH}Site/${selected_sid}`, { params: params })
    //console.log("readAlertAbnormal : ",res.data.content);
    dispatch({ type: types.READ_ALERT_ABNORMAL, data: res.data.content, paging: res.data.total_pages })
}

export const readAlertsReplacement =
    (page: number, type: string) => async (dispatch: ThunkDispatch<any, any, ReadAlertsReplacement>) => {
        const params = { page: page, grp: 'alert', type: type }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid')
        if (!selected_sid) return
        const res = await instance(`${SERVICE_PATH}Site/${selected_sid}`, { params: params })
        dispatch({
            type: types.READ_ALERT_REPLACEMENT,
            data: res.data.content,
            total: res.data.content
                .filter((item: any) => item.process != 'P')
                .reduce((pre: number) => {
                    return pre + 1
                }, 0),
            paging: res.data.total_pages,
        })
    }

export const updateAlertProcess =
    (id: number, process: string) => async (dispatch: ThunkDispatch<any, any, UpdateAlertProcess>) => {
        const params = { process: process }
        const res = await instance.put(`${SERVICE_PATH}Process/${id}`, params)
        dispatch({ type: types.UPDATE_ALERT_PROCESS, data: res.data })
    }

export const updateAlertComment =
    (id: number, comment: string) => async (dispatch: ThunkDispatch<any, any, UpdateAlertComment>) => {
        const params = { comment: comment }
        const res = await instance.put(`${SERVICE_PATH}Comment/${id}`, params)
        dispatch({ type: types.UPDATE_ALERT_COMMENT, data: res.data })
    }
