import React, { useLayoutEffect, useState } from 'react'
import { RootState } from 'store/reducers'
import { setMyInfo } from 'store/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import { decodeToken, getToken, onAuthorize } from 'lib/login'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import 'assets/scss/styles.scss'
import WaitPage from 'pages/Exception/WaitPage'
import LoadingPage from 'pages/Exception/LoadingPage'
import DefaultLayout from 'layout/DefaultLayout'

function App() {
    const location = useLocation()
    const dispatch = useDispatch()
    const myInfo = useSelector((state: RootState) => state.common.myInfo)
    const [use, setUse] = useState(true)

    useLayoutEffect(() => {
        const init = async () => {
            const result = await onAuthorize(location)
            if (result) {
                const token = getToken()
                if (token) {
                    dispatch(setMyInfo(decodeToken(token)))
                }
            } else {
                setUse(false)
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if (!use) {
        return <WaitPage />
    }

    if (!myInfo) {
        return <LoadingPage />
    }
    return (
        <Switch>
            <Route exact path='/' render={() => <Redirect to='/home' />} />
            <Route exact path='/login' render={() => <Redirect to='/home' />} />
            <DefaultLayout />
        </Switch>
    )
}

export default App
