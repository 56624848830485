import React from 'react'
import MainPanel from '../../components/MainPanel/MainPanel'
import MonthProfitIcon from '../../assets/img/img_1.svg'
import TodayProfitIcon from '../../assets/img/img_2.svg'
import TodayEnergyIcon from '../../assets/img/img_3.svg'
import MonthlyEnergyIcon from '../../assets/img/img_4.svg'

import MonthProfitIconDark from '../../assets/img/img_1_dark.svg'
import TodayProfitIconDark from '../../assets/img/img_2_dark.svg'
import TodayEnergyIconDark from '../../assets/img/img_3_dark.svg'
import MonthlyEnergyIconDark from '../../assets/img/img_4_dark.svg'
import useTheme from "../../lib/useTheme";

interface ValueType {
    current: string
    past: string
    upDown: 'up' | 'down'
}
interface IProps {
    values: {
        [index: string]: ValueType
        monthProfit: ValueType
        todayProfit: ValueType
        todayEnergy: ValueType
        monthEnergy: ValueType
    }
}

function SubhomePanels({ values }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <>
            {theme==='light' &&
                MainConstantInfo.map((data) => (
                <MainPanel
                    key={data.gridArea}
                    gridArea={data.gridArea}
                    valueCurrent={values[data.gridArea].current}
                    valuePast={values[data.gridArea].past}
                    upDown={values[data.gridArea].upDown}
                    bgIcon={data.bgIcon}
                    title={data.title}
                    unit={data.unit}
                    pastCompared={data.pastCompared}
                    bgColor={data.bgColor}
                />
            ))}
            {theme==='dark' &&
            MainConstantInfoDark.map((data) => (
                <MainPanel
                    key={data.gridArea}
                    gridArea={data.gridArea}
                    valueCurrent={values[data.gridArea].current}
                    valuePast={values[data.gridArea].past}
                    upDown={values[data.gridArea].upDown}
                    bgIcon={data.bgIcon}
                    title={data.title}
                    unit={data.unit}
                    pastCompared={data.pastCompared}
                    bgColor={data.bgColor}
                />
            ))}
        </>
    )
}

const MainConstantInfo = [
    {
        gridArea: 'monthProfit',
        title: '검색일 발전시간',
        unit: '시간',
        pastCompared: '전일 동시간 대비',
        bgIcon: MonthProfitIcon,
    },
    {
        gridArea: 'todayProfit',
        title: '현재 가동률',
        unit: 'kW',
        pastCompared: '전력',
        bgIcon: TodayProfitIcon,
    },
    {
        gridArea: 'todayEnergy',
        title: '전일 발전량',
        unit: 'kWh',
        pastCompared: '전전일 동시간 대비',
        bgIcon: TodayEnergyIcon,
    },
    {
        gridArea: 'monthEnergy',
        title: '검색일 발전량',
        unit: 'kWh',
        pastCompared: '전일 동시간 대비',
        bgIcon: MonthlyEnergyIcon,
        bgColor: '#2c58bb',
    },
]


const MainConstantInfoDark = [
    {
        gridArea: 'monthProfit',
        title: '검색일 발전시간',
        unit: '시간',
        pastCompared: '전일 동시간 대비',
        bgIcon: MonthProfitIconDark,
    },
   {
        gridArea: 'todayProfit',
        title: '현재 가동률',
        unit: 'kW',
        unitSub: '%',
        pastCompared: '전력',
        bgIcon: TodayProfitIconDark
    },
    {
        gridArea: 'todayEnergy',
        title: '전일 발전량',
        unit: 'kWh',
        pastCompared: '전전일 동시간 대비',
        bgIcon: TodayEnergyIconDark
    },
    {
        gridArea: 'monthEnergy',
        title: '검색일 발전량',
        unit: 'kWh',
        pastCompared: '전일 동시간 대비',
        bgIcon: MonthlyEnergyIconDark,
        bgColor: '#2c58bb',
    },
]
export default SubhomePanels
