import React, {useState} from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'

import {IRec} from "../../types/operation";
import RecChart from "../../components/Chart/RecChart";

interface IProps {
    values: IRec[]
}

function RecChartCard({ values }: IProps) {
    const [recType, setRecType] = useState<string>("L");

    const handleRecChange = (type: any) => {
        setRecType(type)
    }

    return (
        <Card
            title="REC"
            girdArea="recChart"
            onClickMore=""
            header={
                <ChartSort>
                    <input type="radio" id="rec1" name="chartSortRec" value="L" onChange={e => handleRecChange("L")}
                           defaultChecked={"L" === recType}/>
                    <label htmlFor="rec1">종가</label>

                    <input type="radio" id="rec2" name="chartSortRec" value="H"  onChange={e => handleRecChange("H")}
                           defaultChecked={"H" === recType}/>
                    <label htmlFor="rec2">고저</label>
                </ChartSort>
            }
        >
            <ChartConatiner>
                <RecChart chartId="recChart" chartData={values} lineType={recType}/>
            </ChartConatiner>
        </Card>
    )
}

export default RecChartCard

const ChartConatiner = styled.div`
    @media (max-width: 1440px) {
        height: 250px;
    }
    height: 92%;
    min-height: 300px;
`

const ChartSort = styled.div`
    display: flex;
    margin: 0 0 0 auto;
    align-items: center;
    border: 1px solid ${(props) => props.theme.textBlue};
    border-radius: 6px;

    input[type='radio'] {
        display: none;
    & + label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: ${(props) => props.theme.textBlue};
            padding: 7px 0;
            width: 50px;
            cursor: pointer;
        }
    &:checked {
        & + label {
                background-color: ${(props) => props.theme.textBlue};
                color: white;
            }
        }
    }
}`