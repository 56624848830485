import styled from 'styled-components'
import UpIcon from '../../assets/img/ic_up.svg'
import DownIcon from '../../assets/img/ic_down.svg'
import YelloIconUp from '../../assets/img/ic_up_yellow.svg'
import YelloIconDown from '../../assets/img/ic_down_yellow.svg'
import { Container } from '../Card/Card.style'

export const MainContainer = styled(Container)<{ gridArea: string; backIcon: string; bgColor?: string }>`
    background-color: ${(props) => (props.bgColor ? `${props.bgColor}` : props.theme.panel)};

    &::after {
        @media (max-width: 1024px) {
            display: none;
        }
        content: '';
        position: absolute;
        right: 20px;
        bottom: 20px;
        background: ${(props) => `url(${props.backIcon}) no-repeat`};
        background-size: 60px 60px;
        width: 60px;
        height: 60px;
    }

    .title {
        color: ${(props) => (props.bgColor ? `white` :  props.theme.textBase)};
    }
    .value {
        font-size: 38px;
        color: ${(props) => (props.bgColor ? `white` : props.theme.panelValue)};
        span {
            font-size: 24px;
        }
        .cnt {
            font-size: 38px;
        }
    }
    dl {
        display: flex;
        align-items: center;

        margin-top: 16px;
        dt {
            font-size: 15px;
            color: ${(props) => (props.bgColor ? `white` : '#888888')};

            margin-right: 10px;
        }
        dd {
            position: relative;
            font-size: 17px;
            padding-bottom: 2px;
            color: ${(props) => (props.bgColor ? `#fee100` : '')};

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -24px;
                background-size: 14px 16px;
                width: 14px;
                height: 16px;
                padding-bottom: 6px;
            }
        }
        &.up {
            dd {
                color: ${(props) => (props.bgColor ? '' : props.theme.textRed)};

                &::after {
                    background: ${(props) => (props.bgColor ? `url(${YelloIconUp})` : `url(${UpIcon})`)} no-repeat;
                }
            }
        }
        &.down {
            dd {
                color: ${(props) => (props.bgColor ? '' : props.theme.active)};

                &::after {
                    background: ${(props) => (props.bgColor ? `url(${YelloIconDown})` : `url(${DownIcon})`)} no-repeat;
                }
            }
        }
    }
`
