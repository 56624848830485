import React from 'react'
import styled from 'styled-components'

import ICGood from '../../assets/img/ic_good.svg'
import ICGoodDark from '../../assets/img/ic_good_dark.svg'
import ICStandard from '../../assets/img/ic_standard.svg'
import ICBad from '../../assets/img/ic_bad.svg'
import ICGrid from '../../assets/img/view_grid.svg'
import ICList from '../../assets/img/view_list.svg'
import ICGridA from '../../assets/img/view_grid_a.svg'
import ICListA from '../../assets/img/view_list_a.svg'
import ICGridH from '../../assets/img/view_grid_h.svg'
import ICListH from '../../assets/img/view_list_h.svg'

import ICGridDark from '../../assets/img/view_grid_dark.svg'
import ICListDark from '../../assets/img/view_list_dark.svg'
import ICGridADark from '../../assets/img/view_grid_a_dark.svg'
import ICListADark from '../../assets/img/view_list_a_dark.svg'
import ICGridHDark from '../../assets/img/view_grid_h_dark.svg'
import ICListHDark from '../../assets/img/view_list_h_dark.svg'

import useTheme from "../../lib/useTheme";

interface IProps {
    name: string
    goodNum: number
    normalNum: number
    badNum: number
    checkType: string
    setCheckType: any
}
function InverterSubInfo({ name, goodNum, normalNum, badNum, checkType, setCheckType }: IProps) {
    const [theme] = useTheme(); // 화면모드

    const handleTypeChange = (type: string) => {
        setCheckType(type);
    };

    return (
        <InverterSubInfoContainer themeMode={theme}>
            <div className="inverter-name">
                <div className="view-type">
                    <div className={checkType === 'G' ? 'view-grid active' : 'view-grid'} onClick={()=>handleTypeChange('G')}></div>
                    <div className={checkType === 'L' ? 'view-list active' : 'view-list'} onClick={()=>handleTypeChange('L')}></div>
                </div>
            </div>
            <div className="inverter-status">
                <div className="good">좋음: {goodNum}</div>
                <div className="standard">보통: {normalNum}</div>
                <div className="bad">나쁨: {badNum}</div>
            </div>
        </InverterSubInfoContainer>
    )
}

export default InverterSubInfo

const InverterSubInfoContainer = styled.div<{themeMode:string}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #3c4046;
    padding: 10px 0 30px 0;
    .inverter-name {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    em {
        position: relative;
        font-size: 18px;
        color: #0b51af;
        padding: 0 10px 0 20px;

        &::before {
            content: '•';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            padding-right: 10px;
            font-size: 32px;
        }
    }
    .inverter-name {
        .view-type{
            display: inherit;
        }
        .view-grid {
            position: relative;
            color: #3376db;
            padding-left: 34px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: url(${(props)=> props.themeMode==='light'?ICGrid:ICGridDark}) no-repeat;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                padding-bottom: 2px;
            }
            &:hover {
                &::before {
                    background-image: url(${(props)=> props.themeMode==='light'?ICGridH:ICGridHDark});
                }
            }
            &.active {
                color: ${(props) => props.theme.textBlue};
                &::before {
                    background-image: url(${(props)=> props.themeMode==='light'?ICGridA:ICGridADark});
                }
            }
        }
        .view-list {
            position: relative;
            color: #3376db;
            padding-left: 34px;
            margin-left: 5px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: url(${(props)=> props.themeMode==='light'?ICList:ICListDark}) no-repeat;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                padding-bottom: 2px;
            }
            &:hover {
                &::before {
                    background-image: url(${(props)=> props.themeMode==='light'?ICListH:ICListHDark});
                }
            }
            &.active {
                color: ${(props) => props.theme.textBlue};
                &::before {
                    background-image: url(${(props)=> props.themeMode==='light'?ICListA:ICListADark});
                }
            }
        }
    }
    .inverter-status {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0 50px;
        font-size: 17px;
        white-space: nowrap;
        .good {
            position: relative;
            color: ${(props) => props.theme.active};
            padding-left: 34px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: url(${(props)=> props.themeMode==='light'?ICGood:ICGoodDark}) no-repeat;
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
                padding-bottom: 2px;
            }
        }
        .standard {
            position: relative;
            color: #03a93c;
            padding-left: 34px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: url(${ICStandard}) no-repeat;
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
                padding-bottom: 2px;
            }
        }
        .bad {
            position: relative;
            color: #ef1212;
            padding-left: 34px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background: url(${ICBad}) no-repeat;
                background-size: 24px 24px;
                width: 24px;
                height: 24px;
                padding-bottom: 2px;
            }
        }
    }
`
