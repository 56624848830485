import axios from 'axios'
import instance from '.'
import { setSid } from 'lib/login'

/* fetch access token  */
export const fetchAccessToken = async (code: string) => {
    const res = await axios.get('/api/user/login', {
        params: { code, redirect_uri: `${window.location.origin}/login` },
    })
    return res
}

/* fetch get sid token  */
export const getSid = async (token: string) => {
    const params = { token: token }
    const res = await axios.post('/api/user/mySiteInfo', {}, { params })
    setSid(res.data)
}

export const getPayload = (token: string) => {
    // A JWT has 3 parts separated by '.'
    // The middle part is a base64 encoded JSON
    // decode the base64
    return atob(token.split('.')[1])
}

export const fetchRefreshToken = async () => {
    const res = await instance.get('/api/user/refresh')
    return res
}
