import React, { useEffect, useMemo, useState } from 'react'
import StatusPanels from '../../templates/Common/StatusPanels'
import BrokenListCard from '../../templates/Status/BrokenListCard'
import InverterStatusCard from '../../templates/Status/InverterStatusCard'
import { InnerContainer } from './Status.style'

import { State } from '../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { readAlertsReplacement } from '../../store/actions/alerts'
import {mySids, readDevices, readJunctions} from '../../store/actions/operation'
import { percent } from '../../lib/asyncUtils';

import useInterval from '../../lib/useInterval'
import TopBar from "../../components/TopBar/TopBar";
import JunctionStatusCard from "../../templates/Status/JunctionStatusCard";
// @ts-ignore
import {useAnalytics} from "../../lib/googleAnalytics";
import {readInverterInfoAll, readJunctionInfoAll, readNodataDevice} from '../../store/actions/status'


function StatusPresenter() {
    useAnalytics('Stauts')
    const dispatch = useDispatch();
    const stateIndex: any = useSelector((state:State) => state.alerts);

    const deviceIndex: any = useSelector((state:State) => state.devices);
    
    const statusIndex: any = useSelector((state:State) => state.status);

    useEffect(() => {
        if(deviceIndex.mySids.length <= 0){
            dispatch(mySids())
        }
        //교체필요
        //dispatch(readAlertsReplacement(0,'replacement'))

        //디바이스 정보
        dispatch(readDevices())

        //접속반 정보
        dispatch(readJunctions())

        //인버터 전체 정보
        dispatch(readInverterInfoAll())

        //접속반 전체 정보
        dispatch(readJunctionInfoAll())

        //접속반, 인버터 데이터 미전송 정보
        dispatch(readNodataDevice())

    },[]);

    const initPage = () =>{
        //교체필요
        //dispatch(readAlertsReplacement(0,'replacement'));

        //디바이스 정보
        dispatch(readDevices())

        //접속반 정보
        dispatch(readJunctions())

        //인버터 전체 정보
        dispatch(readInverterInfoAll())

        //접속반 전체 정보
        dispatch(readJunctionInfoAll())

        //접속반, 인버터 데이터 미전송 정보
        dispatch(readNodataDevice())
    }

    // Dynamic delay
    const [delay, setDelay] = useState<number>(60000)
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(true)

    useInterval(
        () => {
          //교체필요
            //dispatch(readAlertsReplacement(0,'replacement'));

            //디바이스 정보
            dispatch(readDevices())

            //접속반 정보
            dispatch(readJunctions())

            //인버터 전체 정보
            dispatch(readInverterInfoAll())

            //접속반 전체 정보
            dispatch(readJunctionInfoAll())

            //접속반, 인버터 데이터 미전송 정보
            dispatch(readNodataDevice())
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    return (
        <><TopBar initPage={initPage}  allSid={deviceIndex.mySids}/>
        <InnerContainer>
            <StatusPanels
                values={{
                    totalNum: deviceIndex.stateT,
                    good: { number: deviceIndex.stateG, percent: percent(deviceIndex.stateG,deviceIndex.stateT)},
                    normal: { number: deviceIndex.stateD, percent: percent(deviceIndex.stateD,deviceIndex.stateT) },
                    bad: { number: deviceIndex.stateB, percent: percent(deviceIndex.stateB,deviceIndex.stateT), warningNum: stateIndex.replacementCnt },
                    sleep: { number: deviceIndex.stateS, percent: percent(deviceIndex.stateS,deviceIndex.stateT) },
                }}
            />
            <BrokenListCard
                values={statusIndex.nodataDevice}
            />
            <InverterStatusCard
                subInfo={{ name: '발전소명', goodNum: deviceIndex.stateG, normalNum: deviceIndex.stateD, badNum: deviceIndex.stateB }}
                values={deviceIndex.devices}
                info={statusIndex.statusAll}
            />
            <JunctionStatusCard
                values={deviceIndex.junctions}
                info={statusIndex.statusAll_j}
            />
        </InnerContainer>
            </>
    )
}

export default StatusPresenter


