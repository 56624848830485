import React, { useEffect, useState } from 'react'
import { InnerContainer } from './Report.style'
import Cookies from 'universal-cookie/es6'
import type { Moment } from 'moment'
import moment from 'moment'
import ReportPanel from '../../components/ReportPanel/ReportPanel'
import ReportChart from '../../templates/Report/ReportChart'
import TopBar from '../../components/TopBar/TopBar'
import ReportDetailsCard from '../../templates/Report/ReportDetailsCard'
import { IGraphDataCate } from '../../types/operation'
import { mySids, reportRead, reportReset } from '../../store/actions/operation'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../store/reducers'
// @ts-ignore
import { useAnalytics } from '../../lib/googleAnalytics'

const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

function ReportPresenter() {
    useAnalytics('Report')
    const [value, setValue] = useState<Moment>(moment(new Date()))
    const [type, setType] = useState<string>('H')

    const dispatch = useDispatch()
    const deviceIndex: any = useSelector((state: State) => state.devices)

    const selectDateParent = (newValue: Moment) => {
        setValue(newValue)
        //dispatch(reportRead(newValue?.format('yyyy-MM-DD'), type))
    }

    const reloadChart = (data: any) => {
        const chartData: IGraphDataCate[] = []

        for (const temp of data) {
            if (type === 'H') {
                chartData.push({ category: '0', real: temp.d0 })
                chartData.push({ category: '1', real: temp.d1 })
                chartData.push({ category: '2', real: temp.d2 })
                chartData.push({ category: '3', real: temp.d3 })
                chartData.push({ category: '4', real: temp.d4 })
                chartData.push({ category: '5', real: temp.d5 })
                chartData.push({ category: '6', real: temp.d6 })
                chartData.push({ category: '7', real: temp.d7 })
                chartData.push({ category: '8', real: temp.d8 })
                chartData.push({ category: '9', real: temp.d9 })
                chartData.push({ category: '10', real: temp.d10 })
                chartData.push({ category: '11', real: temp.d11 })
                chartData.push({ category: '12', real: temp.d12 })
                chartData.push({ category: '13', real: temp.d13 })
                chartData.push({ category: '14', real: temp.d14 })
                chartData.push({ category: '15', real: temp.d15 })
                chartData.push({ category: '16', real: temp.d16 })
                chartData.push({ category: '17', real: temp.d17 })
                chartData.push({ category: '18', real: temp.d18 })
                chartData.push({ category: '19', real: temp.d19 })
                chartData.push({ category: '20', real: temp.d20 })
                chartData.push({ category: '21', real: temp.d21 })
                chartData.push({ category: '22', real: temp.d22 })
                chartData.push({ category: '23', real: temp.d23 })
            } else if (type === 'D') {
                chartData.push({ category: '1', real: temp.d1 })
                chartData.push({ category: '2', real: temp.d2 })
                chartData.push({ category: '3', real: temp.d3 })
                chartData.push({ category: '4', real: temp.d4 })
                chartData.push({ category: '5', real: temp.d5 })
                chartData.push({ category: '6', real: temp.d6 })
                chartData.push({ category: '7', real: temp.d7 })
                chartData.push({ category: '8', real: temp.d8 })
                chartData.push({ category: '9', real: temp.d9 })
                chartData.push({ category: '10', real: temp.d10 })
                chartData.push({ category: '11', real: temp.d11 })
                chartData.push({ category: '12', real: temp.d12 })
                chartData.push({ category: '13', real: temp.d13 })
                chartData.push({ category: '14', real: temp.d14 })
                chartData.push({ category: '15', real: temp.d15 })
                chartData.push({ category: '16', real: temp.d16 })
                chartData.push({ category: '17', real: temp.d17 })
                chartData.push({ category: '18', real: temp.d18 })
                chartData.push({ category: '19', real: temp.d19 })
                chartData.push({ category: '20', real: temp.d20 })
                chartData.push({ category: '21', real: temp.d21 })
                chartData.push({ category: '22', real: temp.d22 })
                chartData.push({ category: '23', real: temp.d23 })
                chartData.push({ category: '24', real: temp.d24 })
                chartData.push({ category: '25', real: temp.d25 })
                chartData.push({ category: '26', real: temp.d26 })
                chartData.push({ category: '27', real: temp.d27 })
                chartData.push({ category: '28', real: temp.d28 })
                if (temp.d29 !== undefined) {
                    chartData.push({ category: '29', real: temp.d29 })
                }
                if (temp.d30 !== undefined) {
                    chartData.push({ category: '30', real: temp.d30 })
                }
                if (temp.d31 !== undefined) {
                    chartData.push({ category: '31', real: temp.d31 })
                }
            } else if (type === 'M') {
                chartData.push({ category: '1', real: temp.d1 })
                chartData.push({ category: '2', real: temp.d2 })
                chartData.push({ category: '3', real: temp.d3 })
                chartData.push({ category: '4', real: temp.d4 })
                chartData.push({ category: '5', real: temp.d5 })
                chartData.push({ category: '6', real: temp.d6 })
                chartData.push({ category: '7', real: temp.d7 })
                chartData.push({ category: '8', real: temp.d8 })
                chartData.push({ category: '9', real: temp.d9 })
                chartData.push({ category: '10', real: temp.d10 })
                chartData.push({ category: '11', real: temp.d11 })
                chartData.push({ category: '12', real: temp.d12 })
            }
        }

        //시간,일,월 합치기
        let mergeSumChart = {}
        chartData.forEach((d: any) => {
            if (mergeSumChart.hasOwnProperty(d.category)) {
                // @ts-ignore
                mergeSumChart[d.category] = mergeSumChart[d.category] + d.real
            } else {
                // @ts-ignore
                mergeSumChart[d.category] = d.real
            }
        })

        const resultData: IGraphDataCate[] = []
        for (const [key, value] of Object.entries(mergeSumChart)) {
            resultData.push({ category: key, real: value } as IGraphDataCate)
        }
        return resultData
    }

    useEffect(() => {
        if (deviceIndex.mySids.length <= 0) {
            dispatch(mySids())
        }
        dispatch(reportReset())
        dispatch(reportRead(value?.format('yyyy-MM-DD'), type))
    }, [value])

    const initPage = () => {
        dispatch(reportRead(value?.format('yyyy-MM-DD'), type))
    }

    const handleExcelDown = async () => {
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return

        let link = document.createElement('a')
        link.href = `${API_BASE}/api/reportPgExcel/${selected_sid}?type=${type}&selectDate=${value?.format(
            'yyyy-MM-DD',
        )}`
        link.click()
    }

    const changeType = async (type: string) => {
        // the callback. Use a better name
        setType(type)
        dispatch(reportReset())
        dispatch(reportRead(value?.format('yyyy-MM-DD'), type))
    }

    return (
        <>
            <TopBar
                initPage={initPage}
                searchOn={true}
                allSid={deviceIndex.mySids}
                calendar={true}
                selectDate={
                    type === 'H'
                        ? value?.format('yyyy-MM-DD')
                        : type === 'D'
                        ? value?.format('yyyy-MM')
                        : value?.format('yyyy')
                }
                type={type}
                timerOff={true}
                selectDateValue={selectDateParent}
            />
            <InnerContainer>
                <ReportPanel type={type} sendDataToParent={changeType} sendExcelDownload={handleExcelDown} />
                <ReportChart type={type} sendChartData={reloadChart(deviceIndex.report.amount)} />

                <ReportDetailsCard
                    girdArea='reportAmountDetailsCard'
                    title='발전량(kWh)'
                    values={deviceIndex.report.amount}
                    /*values2={deviceIndex.report.time}*/ type={type}
                />
                {type !== 'H' ? (
                    <ReportDetailsCard
                        girdArea='reportTimeDetailsCard'
                        title='발전시간'
                        values={deviceIndex.report.time}
                        /*values2={deviceIndex.report.time}*/ type={type}
                    />
                ) : (
                    ''
                )}
            </InnerContainer>
        </>
    )
}

export default ReportPresenter
