import { ThunkDispatch } from 'redux-thunk'
import types from './actionTypes'
import {
    IContractType,
    IDeviceType,
    IGraphData,
    IMonthlyEarnType,
    IMonthlyRentalType,
    IRentalType,
    ISettlementType,
    ISiteInfo,
} from '../../types/operation'

import Cookies from 'universal-cookie/es6'
import instance from '../../api'
const API_BASE = `${process.env.REACT_APP_SERVER_BASE_URL}`

const SERVICE_PATH = `${API_BASE}/api/devices`

export type ReadDevices = {
    type: typeof types.READ_DEVICES
    data: IDeviceType[]
}

export type ReadJunctions = {
    type: typeof types.READ_JUNCTIONS
    data: IDeviceType[]
}

export type SelectSid = {
    type: typeof types.SELECT_SID
    data: string
}

export type MySids = {
    type: typeof types.MY_SIDS
    data: ISiteInfo[]
}

export type TodayPg = {
    type: typeof types.TODAY_PG
    data: string
}

export type MonthPg = {
    type: typeof types.MONTH_PG
    data: string
}

export type TodayPgGraph = {
    type: typeof types.TODAY_PG_GRAPH
    data: IGraphData
}

export type MonthPgGraph = {
    type: typeof types.MONTH_PG_GRAPH
    data: IGraphData
}

export type TodayRevenue = {
    type: typeof types.TODAY_REVENUE
    data: string
}

export type MonthRevenue = {
    type: typeof types.MONTH_REVENUE
    data: string
}

export type SmpData = {
    type: typeof types.SMP_DATA
    data: string
}

export type ContractData = {
    type: typeof types.CONTRACT_DATA
    data: IContractType[]
}

export type RunPrice = {
    type: typeof types.RUN_PRICE
    data: string
}

export type RevenueDetails = {
    type: typeof types.REVENUE_DETAILS
    data: string
}

export type ContractUpdate = {
    type: typeof types.CONTRACT_UPDATE
    data: IContractType
}

export type RentalRead = {
    type: typeof types.RENTAL_READ
    data: IRentalType[]
}

export type RentalCreate = {
    type: typeof types.RENTAL_CREATE
    data: IRentalType
}

export type RentalUpdate = {
    type: typeof types.RENTAL_UPDATE
    data: IRentalType
}

export type RentalDelete = {
    type: typeof types.RENTAL_DELETE
    data: IRentalType
}

export type SettlementRead = {
    type: typeof types.SETTLEMENT_READ
    data: ISettlementType[]
}

export type SettlementPrice = {
    type: typeof types.SETTLEMENT_PRICE
    data: ISettlementType
}

export type SettlementCrossYn = {
    type: typeof types.SETTLEMENT_CROSSYN
    data: ISettlementType
}

export type SettlementBillYn = {
    type: typeof types.SETTLEMENT_BILLYN
    data: ISettlementType
}

export type SettlementState = {
    type: typeof types.SETTLEMENT_STATE
    data: ISettlementType
}

export type MonthlyRentalRead = {
    type: typeof types.MONTHLY_RENTAL_READ
    data: IMonthlyRentalType[]
}

export type MonthlyRentalCreate = {
    type: typeof types.MONTHLY_RENTAL_CREATE
    data: IMonthlyRentalType
}

export type MonthlyRentalUpdate = {
    type: typeof types.MONTHLY_RENTAL_UPDATE
    data: IMonthlyRentalType
}

export type MonthlyRentalDelete = {
    type: typeof types.MONTHLY_RENTAL_DELETE
    data: IMonthlyRentalType
}

export type MonthlyEarnRead = {
    type: typeof types.MONTHLY_EARN_READ
    data: IMonthlyEarnType[]
}

export type MonthlyEarnCreate = {
    type: typeof types.MONTHLY_EARN_CREATE
    data: IMonthlyEarnType
}

export type MonthlyEarnUpdate = {
    type: typeof types.MONTHLY_EARN_UPDATE
    data: IMonthlyEarnType
}

export type MonthlyEarnDelete = {
    type: typeof types.MONTHLY_EARN_DELETE
    data: IMonthlyEarnType
}

export type RecData = {
    type: typeof types.REC_DATA
    data: string
}

export type ReportRead = {
    type: typeof types.REPORT_READ
    data: any
}

export type Actions =
    | ReadDevices
    | SelectSid
    | MySids
    | TodayPg
    | MonthPg
    | TodayPgGraph
    | MonthPgGraph
    | TodayRevenue
    | MonthRevenue
    | SmpData
    | ContractData
    | RunPrice
    | RevenueDetails
    | ContractUpdate
    | RentalRead
    | RentalCreate
    | RentalUpdate
    | RentalDelete
    | SettlementRead
    | MonthlyRentalRead
    | MonthlyRentalCreate
    | MonthlyRentalUpdate
    | MonthlyRentalDelete
    | SettlementPrice
    | SettlementCrossYn
    | SettlementBillYn
    | SettlementState
    | RecData
    | MonthlyEarnRead
    | MonthlyEarnCreate
    | MonthlyEarnUpdate
    | MonthlyEarnDelete
    | ReadJunctions
    | ReportRead

export const readDevices = () => async (dispatch: ThunkDispatch<any, any, ReadDevices>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${SERVICE_PATH}/${selected_sid}`)
    //console.log('readDevices : ',res.data)
    dispatch({ type: types.READ_DEVICES, data: res.data })
}

export const readJunctions = () => async (dispatch: ThunkDispatch<any, any, ReadJunctions>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/junctions/${selected_sid}`)
    //console.log('readJunctions : ',res.data)
    dispatch({ type: types.READ_JUNCTIONS, data: res.data })
}

export const selectSid = (sid: string) => async (dispatch: ThunkDispatch<any, any, SelectSid>) => {
    const cookies = new Cookies()
    cookies.set('selected_sid', sid)
    //console.log('select : ',sid)
    dispatch({ type: types.SELECT_SID, data: sid })
}

export const mySids = () => async (dispatch: ThunkDispatch<any, any, MySids>) => {
    const cookies = new Cookies()
    const sid = cookies.get('sid')
    if (!sid) return
    const res = await instance(`${API_BASE}/api/user/mySiteInfo/${sid}`)
    //console.log("read site info : ",res.data);
    dispatch({ type: types.MY_SIDS, data: res.data })
}

export const todayPg = (date: string) => async (dispatch: ThunkDispatch<any, any, TodayPg>) => {
    const params = { today: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/todayPg/${selected_sid}`, { params: params })
    //console.log('todayPg : ',res.data);
    dispatch({ type: types.TODAY_PG, data: res.data })
}

export const monthPg = (date: string) => async (dispatch: ThunkDispatch<any, any, MonthPg>) => {
    const params = { month: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/monthPg/${selected_sid}`, { params: params })
    //console.log('monthPg : ',res.data);
    dispatch({ type: types.MONTH_PG, data: res.data })
}

export const todayPgGraph = (date: string) => async (dispatch: ThunkDispatch<any, any, TodayPgGraph>) => {
    const params = { today: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/todayPgGraph/${selected_sid}`, { params: params })
    //console.log('todaygraph : ',res.data)
    dispatch({ type: types.TODAY_PG_GRAPH, data: res.data })
}

export const monthPgGraph = (date: string) => async (dispatch: ThunkDispatch<any, any, MonthPgGraph>) => {
    const params = { month: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/monthPgGraph/${selected_sid}`, { params: params })
    //console.log('monthPgGraph : ',res.data)
    dispatch({ type: types.MONTH_PG_GRAPH, data: res.data })
}

export const todayRevenue = (date: string) => async (dispatch: ThunkDispatch<any, any, TodayRevenue>) => {
    const params = { today: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/todayRevenue/${selected_sid}`, { params })
    //console.log('todayRevenue : ',res.data);
    dispatch({ type: types.TODAY_REVENUE, data: res.data })
}

export const monthRevenue = (date: string) => async (dispatch: ThunkDispatch<any, any, MonthRevenue>) => {
    const params = { month: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/monthRevenue/${selected_sid}`, { params })
    //console.log('monthRevenue : ',res.data);
    dispatch({ type: types.MONTH_REVENUE, data: res.data })
}

export const smpData = () => async (dispatch: ThunkDispatch<any, any, SmpData>) => {
    const res = await instance(`${API_BASE}/api/todaySmp`)
    //console.log("smp : ",res.data);
    dispatch({ type: types.SMP_DATA, data: res.data })
}

export const contractData = () => async (dispatch: ThunkDispatch<any, any, ContractData>) => {
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/contractSite/${selected_sid}`)
    //console.log("contractData : ",res.data);
    dispatch({ type: types.CONTRACT_DATA, data: res.data })
}

export const runPrice = (date: string) => async (dispatch: ThunkDispatch<any, any, RunPrice>) => {
    const params = { today: date }
    const cookies = new Cookies()
    const sid = cookies.get('sid')
    if (!sid) return
    const res = await instance(`${API_BASE}/api/monthProfit/${sid}`, { params })
    //console.log("runPrice : ",res.data.allRunPrice);
    dispatch({ type: types.RUN_PRICE, data: res.data.allRunPrice })
}

export const revenueDetails =
    (date: string, type: string) => async (dispatch: ThunkDispatch<any, any, RevenueDetails>) => {
        const params = { selectDate: date, type: type }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid')
        if (!selected_sid) return
        const res = await instance(`${API_BASE}/api/revenueDetails/${selected_sid}`, { params })
        //console.log('revenueDetails : ',res.data);
        dispatch({ type: types.REVENUE_DETAILS, data: res.data })
    }

export const contractUpdate = (data: IContractType) => async (dispatch: ThunkDispatch<any, any, ContractUpdate>) => {
    const res = await instance.put(`${API_BASE}/api/contract/${data.id}`, data)
    //console.log('contractUpdate : ',res.data)
    dispatch({ type: types.CONTRACT_UPDATE, data })
}

export const rentalRead = (siteId: string) => async (dispatch: ThunkDispatch<any, any, RentalRead>) => {
    const res = await instance(`${API_BASE}/api/rentalSite/${btoa(siteId)}`)
    //console.log("rentalRead : ",res.data);
    dispatch({ type: types.RENTAL_READ, data: res.data })
}

export const rentalCreate = (data: IRentalType) => async (dispatch: ThunkDispatch<any, any, RentalCreate>) => {
    const res = await instance.post(`${API_BASE}/api/rental`, data)
    //console.log('rentalCreate : ',res.data)
    data.id = res.data
    dispatch({ type: types.RENTAL_CREATE, data })
}

export const rentalUpdate = (data: IRentalType) => async (dispatch: ThunkDispatch<any, any, RentalUpdate>) => {
    const res = await instance.put(`${API_BASE}/api/rental/${data.id}`, data)
    //console.log('rentalUpdate : ',res.data)
    dispatch({ type: types.RENTAL_UPDATE, data })
}

export const rentalDelete = (data: IRentalType) => async (dispatch: ThunkDispatch<any, any, RentalDelete>) => {
    const res = await instance.delete(`${API_BASE}/api/rental/${data.id}`)
    //console.log('rentalDelete : ',res.data)
    dispatch({ type: types.RENTAL_DELETE, data })
}

export const settlementRead = (date: string) => async (dispatch: ThunkDispatch<any, any, SettlementRead>) => {
    const params = { month: date }
    const cookies = new Cookies()
    const selected_sid = cookies.get('selected_sid')
    if (!selected_sid) return
    const res = await instance(`${API_BASE}/api/settlementSite/${selected_sid}`, { params })
    //console.log("settlement Read : ",res.data);
    dispatch({ type: types.SETTLEMENT_READ, data: res.data })
}

export const settlementPrice =
    (data: ISettlementType) => async (dispatch: ThunkDispatch<any, any, SettlementPrice>) => {
        const res = await instance.put(`${API_BASE}/api/settlementPrice/${data.id}`, data)
        //console.log('settlementPrice : ',res.data)
        dispatch({ type: types.SETTLEMENT_PRICE, data })
    }

export const settlementCrossYn =
    (data: ISettlementType) => async (dispatch: ThunkDispatch<any, any, SettlementCrossYn>) => {
        const res = await instance.put(`${API_BASE}/api/settlementCrossYn/${data.id}`, data)
        //console.log('settlementPrice : ',res.data)
        dispatch({ type: types.SETTLEMENT_CROSSYN, data })
    }

export const settlementBillYn =
    (data: ISettlementType) => async (dispatch: ThunkDispatch<any, any, SettlementBillYn>) => {
        const res = await instance.put(`${API_BASE}/api/settlementBillYn/${data.id}`, data)
        //console.log('settlementPrice : ',res.data)
        dispatch({ type: types.SETTLEMENT_BILLYN, data })
    }

export const settlementState =
    (data: ISettlementType) => async (dispatch: ThunkDispatch<any, any, SettlementState>) => {
        const res = await instance.put(`${API_BASE}/api/settlementState/${data.id}`, data)
        //console.log('settlementPrice : ',res.data)
        dispatch({ type: types.SETTLEMENT_STATE, data })
    }

export const monthlyRentalRead =
    (siteId: string, date: string) => async (dispatch: ThunkDispatch<any, any, MonthlyRentalRead>) => {
        const params = { month: date }
        const res = await instance(`${API_BASE}/api/monthlyRental/${btoa(siteId)}`, { params })
        //console.log("monthlyRentalRead : ",res.data);
        dispatch({ type: types.MONTHLY_RENTAL_READ, data: res.data })
    }

export const monthlyRentalCreate =
    (data: IMonthlyRentalType) => async (dispatch: ThunkDispatch<any, any, MonthlyRentalCreate>) => {
        const res = await instance.post(`${API_BASE}/api/monthlyRental`, data)
        //console.log('monthlyRentalCreate : ',res.data)
        data.id = res.data
        dispatch({ type: types.MONTHLY_RENTAL_CREATE, data })
    }

export const monthlyRentalUpdate =
    (data: IMonthlyRentalType) => async (dispatch: ThunkDispatch<any, any, MonthlyRentalUpdate>) => {
        const res = await instance.put(`${API_BASE}/api/monthlyRental/${data.id}`, data)
        //console.log('rentalUpdate : ',res.data)
        dispatch({ type: types.MONTHLY_RENTAL_UPDATE, data })
    }

export const monthlyRentalDelete =
    (data: IMonthlyRentalType) => async (dispatch: ThunkDispatch<any, any, MonthlyRentalDelete>) => {
        const res = await instance.delete(`${API_BASE}/api/monthlyRental/${data.id}`, { data })
        //console.log('rentalDelete : ',res.data)
        dispatch({ type: types.MONTHLY_RENTAL_DELETE, data })
    }

export const monthlyEarnRead =
    (siteId: string, date: string) => async (dispatch: ThunkDispatch<any, any, MonthlyEarnRead>) => {
        const params = { month: date }
        const res = await instance(`${API_BASE}/api/monthlyEarn/${btoa(siteId)}`, { params })
        //console.log("monthlyEarnRead : ",res.data);
        dispatch({ type: types.MONTHLY_EARN_READ, data: res.data })
    }

export const monthlyEarnCreate =
    (data: IMonthlyEarnType) => async (dispatch: ThunkDispatch<any, any, MonthlyEarnCreate>) => {
        const res = await instance.post(`${API_BASE}/api/monthlyEarn`, data)
        //console.log('monthlyRentalCreate : ',res.data)
        data.id = res.data
        dispatch({ type: types.MONTHLY_EARN_CREATE, data })
    }

export const monthlyEarnUpdate =
    (data: IMonthlyEarnType) => async (dispatch: ThunkDispatch<any, any, MonthlyEarnUpdate>) => {
        const res = await instance.put(`${API_BASE}/api/monthlyEarn/${data.id}`, data)
        //console.log('rentalUpdate : ',res.data)
        dispatch({ type: types.MONTHLY_EARN_UPDATE, data })
    }

export const monthlyEarnDelete =
    (data: IMonthlyEarnType) => async (dispatch: ThunkDispatch<any, any, MonthlyEarnDelete>) => {
        const res = await instance.delete(`${API_BASE}/api/monthlyEarn/${data.id}`, { data })
        //console.log('rentalDelete : ',res.data)
        dispatch({ type: types.MONTHLY_EARN_DELETE, data })
    }

export const recData = () => async (dispatch: ThunkDispatch<any, any, RecData>) => {
    const res = await instance(`${API_BASE}/api/getRec`)
    //console.log("rec : ",res.data);
    dispatch({ type: types.REC_DATA, data: res.data })
}

export const reportRead =
    (date: string, paramType: string) => async (dispatch: ThunkDispatch<any, any, ReportRead>) => {
        const params = { selectDate: date, type: paramType }
        const cookies = new Cookies()
        const selected_sid = cookies.get('selected_sid_single')
        if (!selected_sid) return
        const res = await instance(`${API_BASE}/api/reportPg/${selected_sid}`, { params })
        //console.log("reportRead : ",res.data);
        dispatch({ type: types.REPORT_READ, data: res.data })
    }
export const reportReset = () => async (dispatch: ThunkDispatch<any, any, ReportRead>) => {
    dispatch({ type: types.REPORT_READ, data: { amount: [], time: [] } })
}
