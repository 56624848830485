import React, {useEffect, useState} from 'react';
import { ContractContainer } from './Contract.style'
import {IContractType } from "../../types/operation";
import {
    numberWithCommas,
} from "../../lib/asyncUtils";
import {BtnEdit} from "../SettingPanel/SettingPanel.style";
import useTheme from "../../lib/useTheme";
import ModalContract from "../ModalContract";

interface IProps {
    values: IContractType[]
    clickEdit: boolean
    selectSiteId: string
    setSelectSiteId?: any
    clickAble: string
}
function ContractTable({ values, clickEdit, selectSiteId, setSelectSiteId, clickAble }: IProps) {
    const [theme] = useTheme(); // 화면모드
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [init, setInit] = useState(false);
    const onModalContract = () =>{
        setIsModalOpen(true)
        setInit(true)
    }

    const handleChange = (contract:IContractType) =>{
        setSelectSiteId(contract.site_id)
        selectSiteId = contract.site_id
        //console.log('handle : ',contract.site_id)
    }

    useEffect(() => {
        if(values.length > 0){
            if(selectSiteId==='0'){
                //console.log('change site_id : '+values[0].site_id)
                setSelectSiteId(values[0].site_id)
            }
        }
    }, [values])

    return (
        <ContractContainer gridArea='contract' cursor={clickAble}>
            <div className='header'>
                <div className='title'>
                    계약내용
                </div>
                {clickEdit && <BtnEdit themeMode={theme} onClick={()=>onModalContract()}>
                    <i className='icon-pencil'></i>수정
                </BtnEdit>}
            </div>
            <div className='table-container'>
                <table>
                    <thead>
                    <tr>
                        <th className='name'>발전소명</th>
                        <th className='rec'>REC 가중치</th>
                        <th className='rec_price'>REC 기준가격</th>
                    </tr>
                    </thead>
                    <tbody>
                    {values.sort((a: IContractType, b: IContractType) => a.site_id > b.site_id ? 1: -1).map((item, index) => {
                        if(clickAble==='Y')
                        return (
                            <tr key={item.id} className={(selectSiteId === item.site_id) || (selectSiteId==='0' && index===0) ? 'active' : ''} onClick={()=>handleChange(item)}>
                                <td className='name' >{item.name}</td>
                                <td>{item.rec}</td>
                                <td>{numberWithCommas(''+item.rec_price,0)}</td>

                                {/*{item.com ==='Y' && <td>
                                    {item.run_volume} <br /> {numberToKorean(item.run_price)}
                                </td>}
                                {item.com ==='N' && <td>
                                    자사
                                </td>}*/}
                            </tr>
                        );
                        if(clickAble==='N')
                            return (
                                <tr key={item.id} className={(selectSiteId === item.site_id) || (selectSiteId==='0' && index===0) ? 'active' : ''} >
                                    <td className='name' >{item.name}</td>
                                    <td>{item.rec}</td>
                                    <td>{numberWithCommas(''+item.rec_price,0)}</td>
                                    {/*{item.com ==='Y' && <td>
                                        {item.run_volume} <br /> {numberToKorean(item.run_price)}
                                    </td>}
                                    {item.com ==='N' && <td>
                                        자사
                                    </td>}*/}
                                </tr>
                            );
                    })}
                    </tbody>
                </table>
            </div>
            {isModalOpen && <ModalContract modalVisible={isModalOpen} setModalVisible={setIsModalOpen} init={init} setInit={setInit}></ModalContract>}
        </ContractContainer>
    )
}

export default ContractTable;