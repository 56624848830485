import 'assets/scss/_cal.scss'

import 'moment/locale/ko'
import moment from 'moment'
import type { Moment } from 'moment'

import 'rc-picker/assets/index.css'
import Picker from 'rc-picker'
import locale from 'rc-picker/lib/locale/ko_KR'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'

import 'react-selection-box/dist/index.css'
import Selection, { IOptions } from 'react-selection-box'

import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Clock from 'react-live-clock'
import Cookies from 'universal-cookie/es6'
import useTheme from 'lib/useTheme'
import { Base64 } from 'js-base64'
import { ISiteInfo } from 'types/operation'
import { numberWithCommas } from 'lib/asyncUtils'

import { TopBarDiv, SelectDropdown, SelectDropdownSingle, Button, Capacity } from './TopBar.style'

interface IProps {
    calendar?: boolean
    selectDate?: string
    type?: string
    timerOff?: boolean
    selectDateValue?: any
    initPage: any
    searchOn?: boolean
    allSid: ISiteInfo[]
}

function TopBar({ calendar, selectDate, type, timerOff, selectDateValue, initPage, searchOn = false, allSid }: IProps) {
    const [theme] = useTheme() // 화면모드
    const [capacity, setCapacity] = useState(0)
    const [useOptions, setUseOptions] = useState<IOptions[]>([])
    const ref = useRef(false)
    const cookies = useMemo(() => new Cookies(), [])
    const selected_sid = cookies.get('selected_sid')
    const sid_decode = selected_sid && Base64.decode(selected_sid)

    const selected_sid_single = cookies.get('selected_sid_single')
    const sid_single_decode = selected_sid_single && Base64.decode(selected_sid_single)

    useEffect(() => {
        let options: IOptions[] = []
        if (searchOn) {
            if (sid_single_decode) {
                options = allSid
                    .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                    .map((site: ISiteInfo, index) => ({
                        label: site.name,
                        value: site.site_id,
                        checked: sid_single_decode.indexOf(site.site_id) >= 0 ? true : false,
                    }))
            } else {
                options = allSid
                    .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                    .map((site: ISiteInfo, index) => ({
                        label: site.name,
                        value: site.site_id,
                        checked: index === 0 ? true : false,
                    }))
                const sid_single_encode = Base64.encode(options.find((item) => item.checked === true)?.value as string)
                cookies.set('selected_sid_single', sid_single_encode)
            }
        } else {
            options = allSid
                .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                .map((site: ISiteInfo) => ({
                    label: site.name,
                    value: site.site_id,
                    checked: sid_decode?.indexOf(site.site_id) >= 0 ? true : false,
                }))
        }
        setUseOptions(options)
    }, [allSid, cookies, searchOn, sid_decode, sid_single_decode])

    const renewCapacity = useCallback(() => {
        if (allSid.length > 0) {
            if (typeof sid_single_decode !== 'boolean') {
                setCapacity(
                    allSid
                        .filter((item) =>
                            searchOn === false
                                ? sid_decode?.indexOf(item.site_id) >= 0
                                : sid_single_decode?.indexOf(item.site_id) >= 0,
                        )
                        .reduce((pre: number, item) => {
                            return pre + Number.parseFloat(item.capacity)
                        }, 0),
                )
            } else {
                if (searchOn) {
                    setCapacity(
                        // @ts-ignore
                        allSid
                            .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                            .find((_, index) => index === 0).capacity,
                    )
                } else {
                    setCapacity(
                        allSid
                            .filter((item) => sid_decode?.indexOf(item.site_id) >= 0)
                            .reduce((pre: number, item) => {
                                return pre + Number.parseFloat(item.capacity)
                            }, 0),
                    )
                }
            }
        }
    }, [allSid, searchOn, sid_decode, sid_single_decode])

    useEffect(() => {
        if (!ref.current) {
            ref.current = true
            return
        }
        renewCapacity()
        // Do whatever you want to do when likes state changes
    }, [renewCapacity])

    const onChange = (newValue: Moment | null) => {
        selectDateValue(newValue)
    }

    const disabledDate = (current: Moment) => {
        return current > moment().startOf('day')
    }

    const changeSid = (sid: any) => {
        if (sid) {
            let nowSelect = sid.value.join()
            if (nowSelect.length > 1) {
                const sid_encode = Base64.encode(nowSelect)
                cookies.set('selected_sid', sid_encode)

                setUseOptions(
                    allSid
                        .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                        .map((site: ISiteInfo) => ({
                            label: site.name,
                            value: site.site_id,
                            checked: nowSelect.indexOf(site.site_id) >= 0 ? true : false,
                        })),
                )
                initPage()
            }
        }
    }

    const changeSingleSid = (sid: any) => {
        if (sid) {
            let nowSelect = sid.value
            if (nowSelect !== '') {
                const sid_encode = Base64.encode(nowSelect)
                cookies.set('selected_sid_single', sid_encode)

                setUseOptions(
                    allSid
                        .sort((a: ISiteInfo, b: ISiteInfo) => (a.site_id > b.site_id ? 1 : -1))
                        .map((site: ISiteInfo) => ({
                            label: site.name,
                            value: site.site_id,
                            checked: nowSelect.indexOf(site.site_id) >= 0 ? true : false,
                        })),
                )
                initPage()
            }
        }
    }

    return (
        <TopBarDiv>
            <div className='plant'>
                {!searchOn && (
                    <SelectDropdown themeMode={theme}>
                        <Selection
                            type='multiple'
                            name='pv'
                            label='발전소'
                            options={useOptions}
                            onChange={changeSid}
                            searchable={true}
                            cleanable={false}
                            loading={false}
                            disabled={false}
                            rtl={false}
                            countSelectedInCaption={2}
                            translates={{
                                placeholder: {
                                    min: 'min',
                                    max: 'max',
                                    search: '검색',
                                },
                                button: {
                                    cancel: '취소',
                                    apply: '조회',
                                },
                            }}
                        />
                    </SelectDropdown>
                )}
                {searchOn && (
                    <SelectDropdownSingle themeMode={theme}>
                        <Selection
                            type='single'
                            name='pv'
                            label='발전소'
                            options={useOptions}
                            onChange={changeSingleSid}
                            searchable={true}
                            cleanable={false}
                            loading={false}
                            disabled={false}
                            rtl={false}
                            translates={{
                                placeholder: {
                                    min: 'min',
                                    max: 'max',
                                    search: '검색',
                                },
                            }}
                        />
                    </SelectDropdownSingle>
                )}
                {calendar && (
                    <Button>
                        <Picker<Moment>
                            generateConfig={momentGenerateConfig}
                            disabledDate={disabledDate}
                            locale={locale}
                            picker={type === 'H' ? 'date' : type === 'D' ? 'month' : 'year'}
                            onChange={onChange}
                            defaultValue={moment(selectDate, 'YYYY-MM-DD')}
                        />
                    </Button>
                )}
                <Capacity>
                    총 설비용량 : <span>{numberWithCommas('' + capacity, 3)}</span> kWp
                </Capacity>
            </div>
            {!timerOff && (
                <div className='time'>
                    <Clock format='YYYY.MM.DD HH:mm:ss' ticking={true} />
                </div>
            )}
        </TopBarDiv>
    )
}

export default React.memo(TopBar)
