import React, { useState, useEffect } from 'react'
import { ModalListDiv, Button, ButtonClose, ModalDropdown } from './ModalContract.style'

import { State } from '../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';

import useTheme from "../../lib/useTheme";
import {IContractType, ISiteInfo} from "../../types/operation";
import {contractUpdate } from "../../store/actions/operation";
import {isEmail, numberWithCommas, onlyNumber} from "../../lib/asyncUtils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from "../Input/Input";
import NumberInput from "../NumberInput/NumberInput";
import {number} from "@amcharts/amcharts4/core";

interface Props {
  modalVisible: boolean
  setModalVisible: any
  init: boolean
  setInit: any
}

function ModalContract(props: Props) {

  const [theme] = useTheme(); // 화면모드
  const dispatch = useDispatch();
  const [listTitle, setListTitle] = useState('')
  const [showItem, setShowItem] = useState<IContractType>({
    id: 0,
    site_id: '',
    name: '',
    smp: 0,
    rec: 0,
    com: '',
    run_volume: '',
    run_price: 0,
    cross_yn: '',
    email: '',
    bill_yn: '',
    bill_date: '',
    bill_num: '',
    sngy_yn: '',
    use_yn: '',
    created_dt: '',
    rec_yn: '',
    rec_price: 0
  })

  const [items, setItems] = useState([]);
  //const [useCom, setUseCom] = useState('');
  const [updateBtn, setUpdateBtn] = useState(false);

  const deviceIndex: any = useSelector((state:State) => state.devices);

  const handleChange = (site:ISiteInfo) =>{
    selectShowItem(site.site_id)
  }

  const selectShowItem = (site_id:string)=>{
    deviceIndex.contractData.map((contract:IContractType, index:number)=>{
      if(site_id==='0' && index===0){
        setShowItem(contract)
        //setUseCom(contract.com)
      }else if(contract.site_id === site_id){
        setShowItem(contract)
        //setUseCom(contract.com)
      }
    })
  }

  const loadList = () =>{
    const items = deviceIndex.mySids.sort((a: ISiteInfo, b: ISiteInfo) => a.site_id > b.site_id ? 1: -1).map((site: ISiteInfo, index:number) => (
          <div key={index}>
            <input type='radio' id={''+site.site_id} name='plantIssues' value={site.site_id} defaultChecked={index === 0 ? true : false} onChange={()=>handleChange(site)}/>
            <label htmlFor={''+site.site_id}>
              <div className='title'>{site.name}</div>
              <div className='summary'>
                {site.address}
              </div>
            </label>
          </div>
    ));
    setItems(items)
  }

  const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value } = e.target
    let tempValue:any = value
    /*if(name==='smp' || name==='rec'|| name==='rec_price'|| name==='run_price'|| name==='bill_date'){
      tempValue = onlyNumber(value)
    }
    if(name==='rec_price'){
      tempValue*=1
    }*/

    setShowItem({
      ...showItem,
      [name]: tempValue
    })
    setUpdateBtn(true)
  }

  const handleChangeNumberInput = (name:string, value:number) => {
    setShowItem({
      ...showItem,
      [name]: value
    })
    setUpdateBtn(true)
  }

  /*const handleChangeCom = () =>{
    if(showItem.com==='Y'){
      showItem.com='N'
    }else{
      showItem.com='Y'
    }
    setUseCom(showItem.com)
  }*/

  const updateContract = () =>{
    //console.log(showItem.email+'update Contract : ',showItem.email==='')
    if(!isEmail(showItem.email) && showItem.email!==''){
      toast.error("이메일이 양식과 다릅니다.")
    }else{
      const result = dispatch(contractUpdate(showItem))
      // @ts-ignore
      result.then(() => {
        toast.dark("업데이트 되었습니다.")
      }).catch(() => {
        toast.dark("업데이트 실패! 다시 시도해주세요.")
      })
    }
  }

  useEffect(() => {
    if(props.init === true){
       setListTitle('계약내용')
       props.setInit(false)
    }
    selectShowItem('0')
    loadList()

  },[]);//useCom

  useEffect(() => {
    const handleEsc = (event:any) => {
      if (event.keyCode === 27) {
        props.setModalVisible(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

    if(!props.modalVisible){
      return null;
    }

    return (
      <ModalListDiv>
        <div className='modal'>
          <div className='modal-header'>
            <div className='sub'>
              <div className='title'>{listTitle}</div>
            </div>
            <ButtonClose onClick={() => {props.setModalVisible(false)}} themeMode={theme}></ButtonClose>
          </div>
          <div className='modal-body'>
            <div className='modal-grid'>
              <div className='col-left'>
                <div className='plant-issues'>
                  {items}
                </div>
              </div>
              <div className='col-right'>
                  <div className='content'>
                    <div className='content-body'>
                      <h2>{showItem.name}</h2>
                      <div className='body-grid-2'>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC 가중치</div>
                          <NumberInput name='rec' value={showItem.rec} handleChangeInput={handleChangeNumberInput} suffix=" 배" decimalScale={2}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>REC 기준가격</div>
                          <NumberInput name='rec_price' value={showItem.rec_price} handleChangeInput={handleChangeNumberInput} suffix=" 원"/>
                        </div>
                      </div>
                      {/*<div className='body-grid-2'>
                        <div className='grid-sub'>
                          <div className='edit_title'>자사/타사</div>
                          <ModalDropdown themeMode={theme} onClick={()=>handleChangeCom()}>
                            <span>{useCom ==='Y'?'타사':'자사'}</span>
                          </ModalDropdown>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>SMP</div>
                          <Input type='text' name='smp' value={showItem.smp} handleChangeInput={handleChangeInput}/>
                        </div>
                      </div>
                      {useCom ==='Y' &&
                        <div className='body-grid-2 '>
                          <div className='grid-sub'>
                            <div className='edit_title'>계약용량(예: 100 MWh당)</div>
                            <Input type='text' name='run_volume' value={showItem.run_volume} handleChangeInput={handleChangeInput}/>
                          </div>
                          <div className='grid-sub'>
                            <div className='edit_title'>운영비(단위: 원)</div>
                            <Input type='text' name='run_price' value={numberWithCommas(''+showItem.run_price, 0)} handleChangeInput={handleChangeInput} max={13}/>
                          </div>
                        </div>
                      }*/}
                      <div className='body-grid-2 line'>
                        <div className='grid-sub'>
                          <div className='edit_title'>이메일 (세금계산서용)</div>
                          <Input type='text' name='email' value={showItem.email} handleChangeInput={handleChangeInput}/>
                        </div>
                        <div className='grid-sub'>
                          <div className='edit_title'>사업자번호 (세금계산서용)</div>
                          <Input type='text' name='bill_num' value={showItem.bill_num} handleChangeInput={handleChangeInput} max={12}/>
                        </div>
                      </div>

                      <div className='body-grid-3'>
                        <div className='grid-sub'>
                          <div className='edit_title'>세금계산서 요청일</div>
                          <Input type='text' name='bill_date' value={showItem.bill_date} handleChangeInput={handleChangeInput} max={2}/>
                        </div>
                      </div>

                    </div>

                    <div className='content-action'>
                      <Button bg={updateBtn} onClick={()=>updateContract()}>저장</Button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1500}/>
      </ModalListDiv>
    );
  }

  export default ModalContract;

  