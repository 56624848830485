import React, {useState} from 'react'
import Card from "../../components/Card/Card";
import Table from "../../components/Table/Table";
import styled from "styled-components";
import { numberWithCommasNumberType } from '../../lib/asyncUtils'
import {IContractType} from "../../types/operation";

interface valueType {
    name: string
    site: string
    type: string
    d0: number
    d1: number
    d2: number
    d3: number
    d4: number
    d5: number
    d6: number
    d7: number
    d8: number
    d9: number
    d10: number
    d11: number
    d12: number
    d13: number
    d14: number
    d15: number
    d16: number
    d17: number
    d18: number
    d19: number    
    d20: number
    d21: number
    d22: number
    d23: number
    d24: number
    d25: number
    d26: number
    d27: number
    d28: number
    d29: number
    d30: number
    d31: number
}

interface IProps {
    type: string
    values: valueType[]
    // values2: valueType[]
    girdArea: string
    title: string
}

function ReportDetailsCard({ values, /*values2,*/ type, girdArea, title }: IProps) {
    let header = [
        { className: 'name', name: '발전소', key: 'name' }
    ];
    if(type==='H'){
        for(let i=0; i<=23; i++){
            header.push({ className: 'd', name: i+'시', key: 'd'+i })
        }
    }else if(type==='D'){
        for(let i=1; i<=28; i++){
            header.push({ className: 'd', name: i+'일', key: 'd'+i })
        }
        if(values.length !== 0) {
        if(values[0].d29 !== undefined){
            header.push({ className: 'd', name: '29일', key: 'd29' })
        }
        if(values[0].d30 !== undefined){
            header.push({ className: 'd', name: '30일', key: 'd30' })
        }
        if(values[0].d31 !== undefined){
            header.push({ className: 'd', name: '31일', key: 'd31' })
        }
        }
    }else if(type==='M'){
        for(let i=1; i<=12; i++){
            header.push({ className: 'd', name: i+'월', key: 'd'+i })
        }
    }

    const sortValeus = (values: valueType[]) => values.sort((a, b) => a.site > b.site ? 1: -1).map((value) => ({
        name: { content: value.name },
        d0: { content: numberWithCommasNumberType(value.d0) },
        d1: { content: numberWithCommasNumberType(value.d1) },
        d2: { content: numberWithCommasNumberType(value.d2) },
        d3: { content: numberWithCommasNumberType(value.d3) },
        d4: { content: numberWithCommasNumberType(value.d4) },
        d5: { content: numberWithCommasNumberType(value.d5) },
        d6: { content: numberWithCommasNumberType(value.d6) },
        d7: { content: numberWithCommasNumberType(value.d7) },
        d8: { content: numberWithCommasNumberType(value.d8) },
        d9: { content: numberWithCommasNumberType(value.d9) },
        d10: { content: numberWithCommasNumberType(value.d10) },
        d11: { content: numberWithCommasNumberType(value.d11) },
        d12: { content: numberWithCommasNumberType(value.d12) },
        d13: { content: numberWithCommasNumberType(value.d13) },
        d14: { content: numberWithCommasNumberType(value.d14) },
        d15: { content: numberWithCommasNumberType(value.d15) },
        d16: { content: numberWithCommasNumberType(value.d16) },
        d17: { content: numberWithCommasNumberType(value.d17) },
        d18: { content: numberWithCommasNumberType(value.d18) },
        d19: { content: numberWithCommasNumberType(value.d19) },
        d20: { content: numberWithCommasNumberType(value.d20) },
        d21: { content: numberWithCommasNumberType(value.d21) },
        d22: { content: numberWithCommasNumberType(value.d22) },
        d23: { content: numberWithCommasNumberType(value.d23) },
        d24: { content: numberWithCommasNumberType(value.d24) },
        d25: { content: numberWithCommasNumberType(value.d25) },
        d26: { content: numberWithCommasNumberType(value.d26) },
        d27: { content: numberWithCommasNumberType(value.d27) },
        d28: { content: numberWithCommasNumberType(value.d28) },
        d29: { content: numberWithCommasNumberType(value.d29) },
        d30: { content: numberWithCommasNumberType(value.d30) },
        d31: { content: numberWithCommasNumberType(value.d31) }
    }))

    const faultBody1 = sortValeus(values);

    // const faultBody2 = sortValues(values2);

    return (
        <Card girdArea={girdArea} title={title} onClickMore="">
                <TableContainer>
                    {values.length > 0 && <Table header={header} body={faultBody1} /*body2={faultBody2}*/ clickAble={false}/>}
                    {values.length == 0  &&<p>데이터가 없습니다.</p>}
                </TableContainer>
        </Card>
    )
}

export default ReportDetailsCard

const TableContainer = styled.div`
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    overflow: hidden;
    :hover {
        overflow: auto;
    }
    table {
        text-align: center;
        width: 100%;
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};
        font-size: 14px;

        color: ${(props) => props.theme.textBase2};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 3px;
            border-bottom: 1px solid ${(props) => props.theme.line};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            text-align: right;
        }
        td:nth-of-type(1) {
            position: sticky;
            text-align: center;
            left: 0;
            background-color: ${(props) => props.theme.panel};
        }
    }
    .name {
        position: sticky;
        left: 0;
        background-color: ${(props) => props.theme.tableHeader};
        min-width: 140px;
    }
    .d {
        width: 80px;
        min-width: 60px;
    }
    p{
        padding-top: 20px;
        font-size: 16px;
        color: ${(props) => props.theme.textBase2};
    }
`