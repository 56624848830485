import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'

import useTheme from "../../lib/useTheme";
import Diagram from "../../components/Diagram/Diagram";



interface IProps {
    values:any
    selectDate:string
}

function DiagramsCard({ values, selectDate }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <Card title="계통 상태 (전압: V, 전류: A, 전력: kW)" girdArea="diagram" onClickMore="" header="">
            <CanDiv>
               <Diagram values={values} selectDate={selectDate}></Diagram>
            </CanDiv>
            <div id='diagramShowModal'></div>
        </Card>
    )
}
export default DiagramsCard

const CanDiv = styled.div`
    display: block;
    width: 100%;
    height: calc(100% - 30px);

    overflow: auto;
`

