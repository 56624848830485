import React from 'react'
import SummaryCard from '../Common/SummaryCard'

interface IProps {
    values: {
        type: 'replacement' | 'abnormal' | 'success' | 'document'
        title: string
        content: string
        process: string
        id: number
    }[]
}
function EarnDetailsCard({ values }: IProps) {
    return <SummaryCard summaryList={values} title="정산내역" onClickMore={'earn'} />
}

export default EarnDetailsCard