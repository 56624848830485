import React from 'react'
import MainPanel from '../../components/MainPanel/MainPanel'
import MonthProfitIcon from '../../assets/img/img_1.svg'
import TodayProfitIcon from '../../assets/img/img_2.svg'
import MonthlyRunIcon from '../../assets/img/ic_monthly_run.svg'

interface ValueType {
    current: string
    past: string
    upDown: 'up' | 'down' | 'no'
}
interface IProps {
    values: {
        [index: string]: ValueType
        monthProfit: ValueType
        todayProfit: ValueType
    }
}

function EstimatePanels({ values }: IProps) {
    return (
        <>
            {MainConstantInfo.map((data) => (
                <MainPanel
                    key={data.gridArea}
                    gridArea={data.gridArea}
                    valueCurrent={values[data.gridArea].current}
                    valuePast={values[data.gridArea].past}
                    upDown={values[data.gridArea].upDown}
                    bgIcon={data.bgIcon}
                    title={data.title}
                    unit={data.unit}
                    pastCompared={data.pastCompared}
                />
            ))}
        </>
    )
}

const MainConstantInfo = [
    {
        gridArea: 'monthProfit',
        title: '이달의 예상수익',
        unit: '원',
        pastCompared: '지난달 동일 대비',
        bgIcon: MonthProfitIcon,
    },
    {
        gridArea: 'todayProfit',
        title: '오늘의 예상수익',
        unit: '원',
        pastCompared: '전일 동시간 대비',
        bgIcon: TodayProfitIcon,
    }
]
export default EstimatePanels
