import styled from 'styled-components'
import ICTime from '../../assets/img/ic_time.svg'
import Ic_cal from "../../assets/img/icon_calendar.svg";
import Ic_select_chev from "../../assets/img/ic_select_chev.svg";
import Ic_select_chev_dark from "../../assets/img/ic_select_chev_dark.svg";
import Ic_select_chev_new from "../../assets/img/ic_select_chev_new.svg";
import Ic_select_chev_dark_new from "../../assets/img/ic_select_chev_dark_new.svg";

export const TopBarDiv = styled.div`
    grid-area: topbar;
    color: ${(props) => props.theme.textBase2};
    display: flex;
    justify-content: space-between;
    align-items: center;
    .plant {
        display: flex;
        align-items: center;
        .page-title {
            font-size: 30px;
            margin-right: 50px;
        }
    }
    .time {
        position: relative;
        font-size: 20px;

        color: ${(props) => props.theme.textBase};
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -30px;
            background: url(${ICTime}) no-repeat;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
        }
    }
`

export const Button = styled.button`
  width: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 40px;
  padding: 0 15px;
  color: ${(props) => props.theme.grayBtnText};
  border-radius: 16px;
  margin: 0 20px;
  background-color: ${(props) => props.theme.grayBtn};
  &:hover {
    background-color: ${(props) => props.theme.grayBtn_hover};
  }
  background-image: url(${Ic_cal});
  background-position: 110px center;
  background-repeat: no-repeat;
  /*.icon-drop {
    position: absolute;
    right: 12px;
  }
  .icon {
    margin-left: 10px;
  }*/
`

export const SelectDropdown = styled.div<{themeMode:string}>`
    width: 250px;
    min-width: 250px;
    .rs-selection-container {
        border-radius: 16px;
        border: none;
        background-color: ${(props) => props.theme.panel};
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            background: url(${(props) => props.themeMode==='light'?Ic_select_chev:Ic_select_chev_dark}) no-repeat;
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
            z-index: 0;
            pointer-events: none;
        }
    }
    .rs-selection-dropdown {
        min-width: 250px;
    }
    
    .rs-selection-toggle .rs-selection-toggle-icon {
           display: none;
           pointer-events: none;
    }
    
    .rs-selection-caption, .rs-selection-dropdown .rs-selection__input {
        color: ${(props) => props.theme.textBase};
        font-size: 16px;
    }
    .rs-selection-dropdown .rs-selection-dropdown-items li {
        font-size: 16px;
        line-height: 1.5;
    }
    .rs-selection-dropdown .rs-selection-dropdown-footer button.rs-selection-dropdown-footer__btn-submit {
        background-color: ${(props) => props.theme.grayBtn};
        border: none;
    }    
    .rs-selection-dropdown-search__input {
        color: #3C4046 !important;
    } 
`

export const SelectDropdownSingle = styled.div<{themeMode:string}>`
    width: 250px;
    min-width: 250px;
    .rs-selection-container {
        border-radius: 16px;
        border: none;
        background-color: ${(props) => props.theme.topBg};
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            background: url(${(props) => props.themeMode==='light'?Ic_select_chev_new:Ic_select_chev_dark_new}) no-repeat;
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
            z-index: 0;
            pointer-events: none;
        }
    }
    
    .rs-selection-dropdown {
        min-width: 250px;
    }
    
    .rs-selection-toggle .rs-selection-toggle-icon {
           display: none;
           pointer-events: none;
    }
    
    .rs-selection-caption, .rs-selection-dropdown .rs-selection__input {
        color: ${(props) => props.theme.topColor};
        font-size: 16px;
    }
    .rs-selection-dropdown .rs-selection-dropdown-items li {
        font-size: 16px;
        line-height: 1.5;
    }
    .rs-selection-dropdown .rs-selection-dropdown-footer button.rs-selection-dropdown-footer__btn-submit {
        background-color: ${(props) => props.theme.grayBtn};
        border: none;
    }     
    .rs-selection-dropdown-search__input {
        color: #3C4046 !important;
    }
`

export const Capacity = styled.div`
  width: 250px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px;
  line-height: 36px;
  padding-left: 15px;
  color: ${(props) => props.theme.textBase}; 
  span {
    font-size: 20px;
    margin: 0px 5px;
    display: contents;
  } 
`