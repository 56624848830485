import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import Table from '../../components/Table/Table'
import {IEstimate} from "../../types/operation";
import {numberWithCommas, numberWithCommasNumberType} from "../../lib/asyncUtils";


interface IProps {
    values: IEstimate[]
    sendType: string
}

const headerH = [
    { className: 'time', name: '시간', key: 'time' },
    { className: 'smp_price', name: '시간별 SMP', key: 'smp_price' },
    { className: 'pg', name: '발전량(kWh)', key: 'pg' },
    { className: 'price', name: '금액', key: 'price' }
]

const header = [
    { className: 'time', name: '일자', key: 'time' },
    { className: 'pg', name: '발전량(kWh)', key: 'pg' },
    { className: 'price', name: '금액', key: 'price' }
]

function EstimateDetailsCard({ values, sendType }: IProps) {

    const faultBody = values.map((value) => ({
        time: { content: value.category },
        smp_price: { content: value.smp_price },
        pg: { content: numberWithCommasNumberType(value.pg) },
        price: {content: numberWithCommasNumberType(value.real)+'원', className: 'txt-right' }
    }))

    return (
        <Card girdArea="smpTable" title="세부내역" onClickMore=''>
            <TableContainer>
                <Table header={sendType==='H'? headerH:header} body={faultBody} clickAble={false} />
            </TableContainer>
        </Card>
    )
}

export default EstimateDetailsCard

const TableContainer = styled.div`
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    table {
        text-align: center;
        width: 100%;
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};
        font-size: 14px;

        color: ${(props) => props.theme.textBase2};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 3px;
            border-bottom: 1px solid ${(props) => props.theme.line};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .time {
        width: 165px;
    }
    .smp_price {
        width: 165px;
    }
    .pg {
        width: 165px;
    }
    .price {
        width: 165px;
    }
    .txt-right {
        text-align: right;
    }
    
`
