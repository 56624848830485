import styled from 'styled-components'
import { Container } from '../Card/Card.style'

export const PgTimeContainer = styled.div<{ gridArea: string }>`
    position: relative;
    grid-area: ${(props) => props.gridArea};
    background-color: ${(props) => props.theme.panel};
    border-radius: 8px;
    padding: 18px;
    display: flex;

    .title {
        width: 160px;
        line-height: 20px;
        color: ${(props) => props.theme.textBase};
        border-right: 1px solid ${(props) => props.theme.subBase};
    }

    .marqueeText {
        height: 20px;
        cursor: default;

        .marquee {
            height: 18px;

            .text {
                margin-right: 100px;
                color: ${(props) => (props.theme.textBase)};
                
                span {
                    color: ${(props) => (props.theme.textOrange)};
                    font-size: 18px;
                }
            } 
        }
    }
`