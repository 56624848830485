import styled from 'styled-components'

export const StatusDiv = styled.div<{ gauge: number }>`
    @keyframes anime {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    padding: 20px 25px;
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        border-left: 1px solid ${(props) => props.theme.subBase};
    }
    @media (max-width: 1180px) {
        padding: 20px 0;
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            border-right: none;
            border-left: none;
            border-top: 1px solid ${(props) => props.theme.subBase};
            border-bottom: 1px solid ${(props) => props.theme.subBase};
        }
    }
    .status-inner {
        color: ${(props) => props.theme.textBase};
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 110px 98px 27px;
        grid-template-areas:
            'face faceData'
            'rate weight'
            'action label';
        .status {
            &-face {
                grid-area: face;
                .face {
                    z-index: 1;
                    position: relative;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 90px;
                    height: 90px;
                    border-radius: 25px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                    @media (max-width: 1180px) {
                        width: 90px;
                        height: 90px;
                        border-radius: 25px;
                    }
                    .gauge {
                        position: absolute;
                        bottom: ${(props) => (props.gauge ? '-'+props.gauge+ 'px' : '-110px')};
                        left: 0;
                        z-index: -1;
                        width: 100%;
                        height: 100%;

                        .anime {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 400%;
                            height: 400%;

                            div {
                                width: 100%;
                                height: 100%;
                                border-radius: 48%;
                                background-color: ${(props) => props.theme.guageColor};

                                animation: anime 4s infinite linear;
                            }
                        }
                    }
                    .gauge-sleep {
                        position: absolute;
                        bottom: ${(props) => (props.gauge ? '-'+props.gauge+ 'px' : '-110px')};
                        left: 0;
                        background-color: ${(props) => props.theme.guageSleepColor};
                        z-index: -1;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &-face-data {
                grid-area: faceData;
                justify-self: end;
                align-self: center;
                text-align: right;
            }
            &-rate {
                grid-area: rate;
                align-self: end;
                padding-bottom: 16px;
            }
            &-weight {
                grid-area: weight;
                justify-self: end;
                align-self: end;
                text-align: right;
                padding-bottom: 16px;
            }
            &-action {
                grid-area: action;
            }
            &-label {
                grid-area: label;
                justify-self: end;
            }
        }
        @media (max-width: 1180px) {
            grid-template-columns: 110px auto auto 1fr 100px;
            grid-template-rows: 45px 45px;
            gap: 0 10px;
            grid-template-areas:
                'face faceData . . .'
                'face rate weight label action';
            .status {
                &-face {
                    grid-area: face;
                }
                &-face-data {
                    grid-area: faceData;
                    justify-self: start;
                    padding-top: 11px;
                }
                &-rate {
                    grid-area: rate;
                    align-self: center;
                    padding-top: 5px;
                    padding-bottom: 0;
                }
                &-weight {
                    grid-area: weight;
                    justify-self: start;
                    align-self: center;
                    padding-top: 5px;
                    padding-bottom: 0;
                }
                &-action {
                    grid-area: action;
                    justify-self: end;
                    align-self: center;
                }
                &-label {
                    grid-area: label;
                    justify-self: end;
                    align-self: center;
                }
            }
        }

        .face-data {
            font-size: 22px;
            color: ${(props) => props.theme.darkLight};
            
            @media (max-width: 1180px) {
                display: flex;
                align-items: center;
            }
            .text-data {
                margin-bottom: 10px;
                @media (max-width: 1180px) {
                    margin-bottom: 0;
                    margin-right: 8px;
                }
            }
            .numeric-data {
                @media (max-width: 1180px) {
                    display: flex;
                    align-items: center;
                }
                em {
                    font-size: 33px;
                    padding-bottom: 5px;
                    @media (max-width: 1180px) {
                        margin-right: 4px;
                    }
                }
            }
        }
        .sleep{
                color: ${(props) => props.theme.sleepTxt};
        }
            
        .rate {
            font-size: 16px;
            color: ${(props) => props.theme.textBase2};;
            line-height: 1.5em;
            @media (max-width: 1180px) {
                display: flex;
                align-items: center;
                .label {
                    margin-right: 8px;
                }
            }
        }
        .weight {
            font-size: 16px;
            justify-self: end;
            text-align: right;
            line-height: 1.5em;
            @media (max-width: 1180px) {
                display: flex;
                align-items: center;
            }
        }
        .action {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`

export const WarningLabel = styled.div`
    display: inline-block;
    margin: 0 0 0 auto;
    position: relative;
    font-size: 13px;
    background-color: ${(props) => props.theme.textRed};
    padding: 7px 10px;
    border-radius: 50vh;
    color: white;
    white-space: nowrap;
    cursor: default;
}`