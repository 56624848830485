import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { State } from 'store/reducers'
import { mySids } from 'store/actions/operation'
import useInterval from 'lib/useInterval'
import { useAnalytics } from 'lib/googleAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import { numberWithCommas, upDown } from 'lib/asyncUtils'
import { subhomeChart, subhomeDevice, subhomeRead } from 'store/actions/subhome'

import TopBar from 'components/TopBar/TopBar'
import DiagramsCard from 'templates/Subhome/DiagramsCard'
import SubhomePanels from 'templates/Subhome/SubhomePanels'
import SubhomeChart from 'templates/Subhome/SubhomeChart'
import SubhomeTable from 'templates/Subhome/SubhomeTable'
import { InnerContainer } from './Subhome.style'

function SubhomePresenter() {
    useAnalytics('Subhome')
    const [value, setValue] = useState<Moment>(moment(new Date()))
    const [type, setType] = useState<string>('H')

    const dispatch = useDispatch()
    const deviceIndex: any = useSelector((state: State) => state.devices)
    const subhomeIndex: any = useSelector((state: State) => state.subhome)

    useEffect(() => {
        if (deviceIndex.mySids.length <= 0) {
            dispatch(mySids())
        }

        dispatch(subhomeRead(value?.format('yyyy-MM-DD')))
        dispatch(subhomeChart(value?.format('yyyy-MM-DD'), type))
        dispatch(subhomeDevice(value?.format('yyyy-MM-DD')))
    }, [])

    const initPage = () => {
        dispatch(subhomeRead(value?.format('yyyy-MM-DD')))
        dispatch(subhomeChart(value?.format('yyyy-MM-DD'), type))
        dispatch(subhomeDevice(value?.format('yyyy-MM-DD')))
    }

    const handleType = (chartType: string) => {
        setType(chartType)
        dispatch(subhomeChart(value?.format('yyyy-MM-DD'), chartType))
    }

    const selectDateParent = (newValue: Moment) => {
        setValue(newValue)
        dispatch(subhomeRead(newValue?.format('yyyy-MM-DD')))
        dispatch(subhomeChart(newValue?.format('yyyy-MM-DD'), type))
        dispatch(subhomeDevice(newValue?.format('yyyy-MM-DD')))
    }

    // Dynamic delay
    const [delay] = useState<number>(60000)
    // ON/OFF
    const [isPlaying] = useState<boolean>(true)

    useInterval(
        () => {
            dispatch(subhomeRead(value?.format('yyyy-MM-DD')))
            dispatch(subhomeChart(value?.format('yyyy-MM-DD'), type))
            dispatch(subhomeDevice(value?.format('yyyy-MM-DD')))
        },
        isPlaying ? delay : null,
    )

    return (
        <>
            <TopBar
                initPage={initPage}
                searchOn={true}
                allSid={deviceIndex.mySids}
                calendar={true}
                selectDate={value?.format('yyyy-MM-DD')}
                selectDateValue={selectDateParent}
                type='H'
                timerOff={false}
            />
            <InnerContainer>
                <SubhomePanels
                    values={{
                        monthProfit: {
                            current: subhomeIndex.subhome.today_time,
                            past: subhomeIndex.subhome.today_time_sub,
                            upDown: upDown(subhomeIndex.subhome.today_time_sub),
                        },
                        todayProfit: {
                            current: subhomeIndex.subhome.now_rate,
                            past: subhomeIndex.subhome.now_rate_sub,
                            upDown: upDown(subhomeIndex.subhome.now_rate_sub > 50 ? '1' : '-1'),
                        },
                        todayEnergy: {
                            current: numberWithCommas(subhomeIndex.subhome.yesterdayPg, 1),
                            past: numberWithCommas(subhomeIndex.subhome.yesterday_pg_sub, 1),
                            upDown: upDown(subhomeIndex.subhome.yesterday_pg_sub),
                        },
                        monthEnergy: {
                            current: numberWithCommas(subhomeIndex.subhome.today_pg, 1),
                            past: numberWithCommas(subhomeIndex.subhome.today_pg_sub, 1),
                            upDown: upDown(subhomeIndex.subhome.today_pg_sub),
                        },
                    }}
                />
                <SubhomeChart setChartType={handleType} values={subhomeIndex.subhomeChart} sendType={type} />
                <DiagramsCard values={subhomeIndex.subhomeDevice} selectDate={value?.format('yyyy-MM-DD')} />
                <SubhomeTable values={subhomeIndex.subhomeDevice} selectDate={value?.format('yyyy-MM-DD')} />
            </InnerContainer>
        </>
    )
}

export default SubhomePresenter
