import React from 'react'
import styled from 'styled-components'
import waitImg from 'assets/img/common/wait.svg'
import { fetchCode } from 'lib/login'

function WaitPage() {
    return (
        <MiscWrapper>
            <div className='misc-inner p-2 p-sm-3'>
                <div className='text-center'>
                    <h2 className='mb-1'>회원 인증 및 대기 화면입니다.️</h2>
                    <div className='text-center'>
                        <Button className='btn-sm-block mb-2' onClick={fetchCode}>
                            로그인하기
                        </Button>
                    </div>
                    <img className='img-fluid' src={waitImg} alt='Not authorized page' />
                </div>
            </div>
        </MiscWrapper>
    )
}

export default WaitPage

const Button = styled.button`
    background-color: rgb(18, 54, 147);
    color: white;
    font-size: 16px;
    border-radius: 3px;
    margin-bottom: 1rem !important;
    display: inline-block;
    text-align: center;
    padding: 0.786rem 1.5rem;
    font-weight: 500;
    &:hover {
        background-color: rgb(18, 54, 147, 0.8);
    }
`

const MiscWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;

    .text-center {
        text-align: center !important;
    }

    h2,
    h3 {
        font-size: 1.6em;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: #5e5873;
    }
    .mb-1 {
        margin-bottom: 3rem !important;
    }
    .mb-2 {
        margin-bottom: 1.5rem !important;
    }
`
