import React, {useEffect, useRef, useState} from 'react'
import { InnerContainer } from './Estimate.style'
import type { Moment } from 'moment';
import moment from 'moment';
import TopBar from "../../components/TopBar/TopBar";
import {numberWithCommas, upDown} from "../../lib/asyncUtils";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/reducers";
import {
    monthRevenue,
    todayRevenue,
    contractData,
    runPrice,
    revenueDetails, mySids, recData
} from "../../store/actions/operation";
import EstimatePanels from "../../templates/Estimate/EstimatePanels";
import EstimateDetailsCard from "../../templates/Estimate/EstimateDetailsCard";
import useInterval from "../../lib/useInterval";
import EstimateChart from "../../templates/Estimate/EstimateChart";
import ContractTable from "../../components/Contract/Contract";
import EstimateBanner from "../../templates/Estimate/EstimateBanner";
import RecChart from "../../templates/Estimate/RecChart";
// @ts-ignore
import {useAnalytics} from "../../lib/googleAnalytics";


function EstimatePresenter() {
    useAnalytics('Estimate')
    const [value, setValue] = useState<Moment>(moment(new Date));
    const [type, setType] = useState<string>("H");//H 기본

    const [load, setLoad] = useState(true);

    const dispatch = useDispatch();

    const deviceIndex: any = useSelector((state:State) => state.devices);

    useEffect(() => {
        if(load){
            if(deviceIndex.mySids.length <= 0){
                dispatch(mySids())
            }
            //오늘의 예상수익
            dispatch(todayRevenue('Y'));
            //이달의 예상수익
            dispatch(monthRevenue('Y'));

            //이달의 운영수익
            dispatch(runPrice('Y'));


            //계약정보
            dispatch(contractData());
            //운영수익
            dispatch(runPrice('Y'));

            //세부내역
            dispatch(revenueDetails(value?.format('yyyy-MM-DD'),type));

            //REC 정보
            dispatch(recData());

            setLoad(false)
        }
    },[type]);

    const initPage = () =>{

        //오늘의 예상수익
        dispatch(todayRevenue('Y'));
        //이달의 예상수익
        dispatch(monthRevenue('Y'));

        //이달의 운영수익
        dispatch(runPrice('Y'));


        //계약정보
        dispatch(contractData());
        //운영수익
        dispatch(runPrice('Y'));

        //세부내역
        dispatch(revenueDetails(value?.format('yyyy-MM-DD'),type));

        //REC 정보
        dispatch(recData());
    }

    // Dynamic delay
    const [delay, setDelay] = useState<number>(60000)
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(true)

    useInterval(
        () => {
            //오늘의 예상수익
            dispatch(todayRevenue('Y'));
            //이달의 예상수익
            dispatch(monthRevenue('Y'));

            //세부내역
            dispatch(revenueDetails(value?.format('yyyy-MM-DD'),type));
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    const handleType = (chartType:string) =>{
        setType(chartType)
        dispatch(revenueDetails(value?.format('yyyy-MM-DD'),chartType));
    }

    return (
        <><TopBar calendar={false} selectDate={value?.format('yyyy-MM-DD')} type={type} timerOff={false} initPage={initPage} allSid={deviceIndex.mySids}/>
            <InnerContainer>
                <EstimatePanels
                    values={{
                        monthProfit: { current: numberWithCommas(deviceIndex.monthRevenue), past: numberWithCommas(deviceIndex.lastMonthRevenue), upDown: upDown(deviceIndex.lastMonthRevenue) },
                        todayProfit: { current: numberWithCommas(deviceIndex.todayRevenue), past: numberWithCommas(deviceIndex.yesterdayRevenue), upDown: upDown(deviceIndex.yesterdayRevenue) },
                    }}
                />
                <ContractTable values={deviceIndex.contractData} clickEdit={false} selectSiteId='N' clickAble='N'/>
                <EstimateChart setChartType={handleType} values={deviceIndex.revenueDetails} sendType={type}/>
                <EstimateDetailsCard
                    values={deviceIndex.revenueDetails} sendType={type}
                />
                <EstimateBanner />
                <RecChart values={deviceIndex.recData}/>
            </InnerContainer>
        </>
    )
}

export default EstimatePresenter
