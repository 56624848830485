import React from 'react'
import useTheme from 'lib/useTheme'
import { ROUTES } from 'constant/routes'
import { ThemeProvider } from 'styled-components'
import { LightTheme, DarkTheme } from 'constant/theme'
import { Redirect, Route, Switch } from 'react-router-dom'

import Navbar from 'components/Navbar/Navbar'
import WaitPage from 'pages/Exception/WaitPage'
import NotFound from 'pages/Exception/NotFound'
import { InnerLayout, MainLayout } from './DefaultLayout.style'

function DefaultLayout() {
    const [theme] = useTheme() // 화면모드
    const themes = { light: LightTheme, dark: DarkTheme }

    return (
        <ThemeProvider theme={themes[theme]}>
            <MainLayout>
                <Navbar />
                <InnerLayout>
                    <Switch>
                        {ROUTES.map((route) => {
                            return <Route key={route.path} path={route.path} component={route.component} />
                        })}
                        <Route path='/oops' component={NotFound} />
                        <Route path='/wait' component={WaitPage} />
                        <Route path='*'>
                            <Redirect to='/oops' />
                        </Route>
                    </Switch>
                </InnerLayout>
            </MainLayout>
        </ThemeProvider>
    )
}

export default DefaultLayout
