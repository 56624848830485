import styled from 'styled-components'

export const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'topbar topbar topbar topbar'
        'reportPanel reportPanel reportPanel reportPanel'
        'energyChart energyChart energyChart energyChart'
        'reportAmountDetailsCard reportAmountDetailsCard reportAmountDetailsCard reportAmountDetailsCard'
        'reportTimeDetailsCard reportTimeDetailsCard reportTimeDetailsCard reportTimeDetailsCard';
    gap: 20px;    
`

