import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useRef } from 'react'
import { IRec} from '../../types/operation';
import useTheme from "../../lib/useTheme";


interface IProps {
    chartId: string
    chartData: IRec[]
    lineType: string
}
function RecChart({ chartId, chartData, lineType }: IProps) {
    const [theme] = useTheme(); // 화면모드
    let useData:IRec[] = chartData

    am4core.useTheme(am4themes_animated)
    
    const chartRef = useRef<am4charts.XYChart | null>(null)

    useEffect(() => {
        const chart = am4core.create(chartId, am4charts.XYChart)
        chart.data = useData
        chart.zoomOutButton.disabled = true
        chart.numberFormatter.numberFormat = "#,###"

        let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        dateAxis.renderer.grid.template.strokeOpacity = 0
        dateAxis.renderer.labels.template.fillOpacity = 0.5
        dateAxis.renderer.labels.template.fontSize = 12
        dateAxis.renderer.minGridDistance = 40
        dateAxis.cursorTooltipEnabled = false
        dateAxis.dataFields.category = "date"


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.labels.template.fillOpacity = 0.5
        valueAxis.cursorTooltipEnabled = false
        valueAxis.fontSize = 10

        let topContainer = chart.chartContainer.createChild(am4core.Container)
        topContainer.layout = 'absolute'
        topContainer.toBack()
        topContainer.paddingBottom = 15
        topContainer.width = am4core.percent(100)

        let axisTitle = topContainer.createChild(am4core.Label)
        axisTitle.text = '(원)'
        axisTitle.fillOpacity = 0.5
        axisTitle.fontSize = 12
        axisTitle.align = 'left'
        axisTitle.paddingLeft = 10

        if(lineType !== 'H') {
            let series1 = chart.series.push(new am4charts.LineSeries())
            series1.dataFields.categoryX = 'date'
            series1.dataFields.valueY = 'close'
            series1.strokeWidth = 3;
            series1.tensionX = 0.8;
            series1.tooltipText = '{categoryX}' + ' [bold]{valueY}[/] 원';
            series1.fillOpacity = 0.1;

            let range = valueAxis.createSeriesRange(series1);
            range.value = 30000;
            range.endValue = 0;
            range.contents.stroke = chart.colors.getIndex(9);
            range.contents.fill = range.contents.stroke;
            range.contents.strokeOpacity = 0.7;
            range.contents.fillOpacity = 0.1;
        }else{
           let series1 = chart.series.push(new am4charts.CandlestickSeries());
            series1.dataFields.categoryX = "date";
            series1.dataFields.valueY = "close";
            series1.dataFields.openValueY = "open";
            series1.dataFields.lowValueY = "low";
            series1.dataFields.highValueY = "high";
            series1.simplifiedProcessing = true;
            series1.tooltipText = "{categoryX}\n시가: {openValueY.value}원\n저가: {lowValueY.value}원\n고가: {highValueY.value}원\n종가: {valueY.value}원";

            // a separate series for scrollbar
            /*let lineSeries = chart.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.categoryX = "date";
            lineSeries.dataFields.valueY = "close";
            // need to set on default state, as initially series is "show"
            lineSeries.defaultState.properties.visible = false;

            // hide from legend too (in case there is one)
            lineSeries.hiddenInLegend = true;
            lineSeries.fillOpacity = 0.5;
            lineSeries.strokeOpacity = 0.5;*/
        }
        //다크모드
        if(theme==='dark') {
            dateAxis.renderer.grid.template.stroke = am4core.color('#fff')
            dateAxis.renderer.labels.template.fill = am4core.color('#fff')
            valueAxis.renderer.grid.template.stroke = am4core.color('#fff')
            valueAxis.renderer.labels.template.fill = am4core.color('#fff')
            axisTitle.fill = am4core.color('#fff')
        }

        chart.cursor = new am4charts.XYCursor()
        chart.cursor.behavior = 'panX'

        chartRef.current = chart

        return () => {
            chart.dispose()
        }
    }, [chartId, chartData, lineType])

    return <div id={chartId} style={{ width: '100%', height: '100%' }}></div>
}

export default RecChart