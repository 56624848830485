import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useRef } from 'react'
import {IEstimate} from '../../types/operation';
import useTheme from "../../lib/useTheme";


interface IProps {
    chartId: string
    chartData: IEstimate[]
    toolStr: string
}
function EsChart({ chartId, chartData,  toolStr }: IProps) {
    const [theme] = useTheme(); // 화면모드
    let useData:IEstimate[] = chartData

    am4core.useTheme(am4themes_animated)
    
    const chartRef = useRef<am4charts.XYChart | null>(null)

    useEffect(() => {
        const chart = am4core.create(chartId, am4charts.XYChart)
        chart.data = useData
        chart.zoomOutButton.disabled = true
        chart.numberFormatter.numberFormat = "#,###.#"

        /*let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.renderer.grid.template.strokeOpacity = 0
        dateAxis.renderer.labels.template.fillOpacity = 0.5
        dateAxis.renderer.labels.template.fontSize = 12
        dateAxis.renderer.minGridDistance = 20
        dateAxis.cursorTooltipEnabled = false
        dateAxis.dataFields.data = "date"
        */
        //dateAxis.dateFormats.setKey('hour', 'H')
        
        let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        dateAxis.renderer.grid.template.strokeOpacity = 0
        dateAxis.renderer.labels.template.fillOpacity = 0.5
        dateAxis.renderer.labels.template.fontSize = 12
        dateAxis.renderer.minGridDistance = 20
        dateAxis.cursorTooltipEnabled = false
        dateAxis.dataFields.category = "category"
        

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.labels.template.fillOpacity = 0.5
        valueAxis.cursorTooltipEnabled = false
        valueAxis.fontSize = 10

        let topContainer = chart.chartContainer.createChild(am4core.Container)
        topContainer.layout = 'absolute'
        topContainer.toBack()
        topContainer.paddingBottom = 15
        topContainer.width = am4core.percent(100)

        let axisTitle = topContainer.createChild(am4core.Label)
        axisTitle.text = '(원)'
        axisTitle.fillOpacity = 0.5
        axisTitle.fontSize = 12
        axisTitle.align = 'left'
        axisTitle.paddingLeft = 10

        let series1 = chart.series.push(new am4charts.ColumnSeries())
        series1.dataFields.categoryX = 'category'
        series1.dataFields.valueY = 'real'
        series1.name = 'real'
        series1.tooltipText = '{categoryX}'+toolStr+' [bold]{valueY}[/] 원'

        let columnTemplate = series1.columns.template
        columnTemplate.width = am4core.percent(50)
        columnTemplate.column.cornerRadiusTopLeft = 10
        columnTemplate.column.cornerRadiusTopRight = 10
        columnTemplate.strokeOpacity = 0
        columnTemplate.fill = am4core.color('#3376DB')

        /*
        let series2 = chart.series.push(new am4charts.LineSeries())
        series2.dataFields.categoryX = 'category'
        series2.dataFields.valueY = 'real'
        series2.name = 'real'
        series2.strokeWidth = 3
        series2.tensionX = 0.8
        */
        // series2.tooltipText = '{name}: [bold]{valueY}[/]'

        /*
        let range = dateAxis.createSeriesRange(series2)
        range.date = new Date(2021, 5, 17)
        range.endDate = new Date(2021, 5, 31)
        range.contents.stroke = am4core.color('#C1CCDB')
        */

        //다크모드
        if(theme==='dark') {
            dateAxis.renderer.grid.template.stroke = am4core.color('#fff')
            dateAxis.renderer.labels.template.fill = am4core.color('#fff')
            valueAxis.renderer.grid.template.stroke = am4core.color('#fff')
            valueAxis.renderer.labels.template.fill = am4core.color('#fff')
            axisTitle.fill = am4core.color('#fff')
        }

        chart.cursor = new am4charts.XYCursor()
        chart.cursor.behavior = 'panX'

        chartRef.current = chart

        return () => {
            chart.dispose()
        }
    }, [chartId, chartData])

    return <div id={chartId} style={{ width: '100%', height: '100%' }}></div>
}

export default EsChart