import React from 'react'
import styled from 'styled-components'

import useTheme from "../../lib/useTheme";
import BannerLight from '../../assets/img/banner.png';
import BannerDark from '../../assets/img/banner_dark.png';

import BannerAni from '../../assets/img/banner/bannerAni-light.gif'
import BannerSolar from '../../assets/img/banner/banner-solar.svg'
import BannerDown from '../../assets/img/banner/banner-down.svg'

import BannerAniDark from '../../assets/img/banner/bannerAni-dark.gif'
import BannerDownDark from '../../assets/img/banner/down-dark.svg'

interface IProps {

}

function EstimateBanner({ }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <>
            <Banner>
                {/*<img src={theme==='light'?BannerLight:BannerDark}  />*/}
                <div className='text'>
                    <div className='title'>시너지 통합 솔루션 장점</div>
                    <ul>
                        <li>• 365일 제초비용 할인제공 </li>
                        <li>• 먼지제거비용 전국 최저가</li>
                        <li>• 월 -300,000원 상당 운영비절감</li>
                    </ul>
                </div>
                <div className='ani'>
                    <img className='leaves' src={theme==='light'?BannerAni:BannerAniDark} alt='' />
                    <img className='solar' src={BannerSolar} alt='' />
                </div>
                <img className='down' src={theme==='light'?BannerDown:BannerDownDark} alt='' />
            </Banner>
        </>
    )
}

export default EstimateBanner

const Banner = styled.div`
  grid-area: banner;
  background-color: ${(props) => props.theme.bannerBg};
  position: relative;
  overflow: hidden;
  min-width: 390px;
  height: 260px;
  border-radius: 8px;
  border: 4px solid ${(props) => props.theme.bannerBorder};
  .ani {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .leaves {
    position: absolute;
    bottom: -45px;
    right: -175px;
  }
  .solar {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .text {
    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 1;
  }
  li {
    color: ${(props) => props.theme.bannerList};
    margin-bottom: 6px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.bannerTitle};
    margin-bottom: 10px;
  }
  .down {
    position: absolute;
    top: 0;
    right: 20px;
  }
`