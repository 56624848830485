import React, { useState, useEffect } from 'react'
import { ModalListDiv, BtnNew, Button, ButtonClose, ModalDropdown } from './ModalRental.style'

import { useDispatch } from 'react-redux';

import useTheme from "../../lib/useTheme";
import {IMonthlyRentalType, IRentalType} from "../../types/operation";
import Input from "../Input/Input";
import { numberWithCommas, onlyNumber} from "../../lib/asyncUtils";
import {
  monthlyRentalCreate, monthlyRentalDelete,
  monthlyRentalUpdate,
  rentalCreate,
  rentalDelete,
  rentalUpdate, settlementRead
} from "../../store/actions/operation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberInput from "../NumberInput/NumberInput";
import {number} from "@amcharts/amcharts4/core";


interface Props {
  values: IRentalType[] | IMonthlyRentalType[]
  modalVisible: boolean
  setModalVisible: any
  init: boolean
  setInit: any
  selectSiteId: string
  day:string
}

function ModalRental({values, modalVisible, setModalVisible, init, setInit, selectSiteId, day }: Props) {
  const [theme] = useTheme(); // 화면모드
  const dispatch = useDispatch();
  const [listTitle, setListTitle] = useState('')
  const [showItem, setShowItem] = useState<IRentalType | IMonthlyRentalType>({
    id: 0,
    site_id: '',
    day: day,
    name: '',
    price: 0,
    fix_yn: 'N',
    use_yn: 'Y',
    org: 0,
    vat: 0
  })

  const [updateBtn, setUpdateBtn] = useState(false);
  const [addType, setAddType] = useState(false);//true 추가 상태, false 업데이트 상태

  const [useFix, setUseFix] = useState('N');

  const addRental = () => {
    setShowItem({
      id: 0,
      site_id: selectSiteId,
      day:day,
      name: '',
      price: 0,
      fix_yn: 'N',
      use_yn: 'Y',
      org: 0,
      vat: 0
    })
    setAddType(true)
    setUseFix('N')
  }

  const handleChange = (rental:IRentalType) =>{
    selectShowItem(rental.id)
  }

  const selectShowItem = (id:number)=>{
    var noSiteData = true
    values.map((rental:IRentalType|IMonthlyRentalType, index:number, arr:any)=>{
      if (rental.site_id === selectSiteId) {
        if (id === 0 && index === arr.length - 1) {
          setShowItem(rental)
          setUseFix(rental.fix_yn)
        } else if (rental.id === id) {
          setShowItem(rental)
          setUseFix(rental.fix_yn)
        }
        noSiteData = false
      }
      if(noSiteData===true){
        setAddType(true) //추가 모드로 시작
      }
    })
  }

  const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value } = e.target
    let tempValue = value

    setShowItem({
      ...showItem,
      [name]: tempValue
    })
    setUpdateBtn(true)
  }

  const handleChangeNumberInput = (name:string, value:number) => {
    let tempOrg=0, tempVat=0, tempTotal=0
    if(name==='org'){
      tempOrg = Number(value)
      tempVat = Math.floor(tempOrg/10)
      tempTotal = Number(tempOrg)+Number(tempVat)
    }else if(name==='vat') {
      tempOrg = showItem.org
      tempVat = Number(value)
      tempTotal = Number(tempOrg) + Number(tempVat)
    }else{
      //price
      return
    }

    setShowItem({
      ...showItem,
      org: tempOrg,
      vat: tempVat,
      price: tempTotal
    })

    setUpdateBtn(true)
  }


  const handleChangeFix = () =>{
    if(showItem.fix_yn==='Y'){
      showItem.fix_yn='N'
    }else{
      showItem.fix_yn='Y'
    }
    setUseFix(showItem.fix_yn)
  }

  const updateRental = () =>{
    const result = dispatch(day===''?rentalUpdate(showItem):monthlyRentalUpdate(showItem as IMonthlyRentalType))
    // @ts-ignore
    result.then(() => {
      toast.dark("업데이트 되었습니다.")
      callSettlement()
    }).catch(() => {
      toast.dark("업데이트 실패! 다시 시도해주세요.")
    })
  }

  const createRental = () =>{
    const result = dispatch(day===''?rentalCreate(showItem):monthlyRentalCreate(showItem as IMonthlyRentalType))
    // @ts-ignore
    result.then(() => {
      toast.dark("저장 되었습니다.")
      callSettlement()
      setAddType(false)
    }).catch(() => {
      toast.dark("저장 실패! 다시 시도해주세요.")
    })
  }

  const deleteRental = () =>{
    const result = dispatch(day===''?rentalDelete(showItem):monthlyRentalDelete(showItem as IMonthlyRentalType))
    // @ts-ignore
    result.then(() => {
      toast.dark("삭제 되었습니다.")
      callSettlement()
    }).catch(() => {
      toast.dark("삭제 실패! 다시 시도해주세요.")
    })
  }
  const callSettlement = () =>{
    if(day!==''){
      //정산 데이터 호출
      const tick = setTimeout(() => {
        dispatch(settlementRead(day))
      }, 1000);
    }
  }

  useEffect(() => {
    if(init === true){
       setListTitle('지출')
       setInit(false)

      if(values.length <= 0){
        setAddType(true) //추가 모드로 시작
       }else{
        selectShowItem(0)
       }
    }
    showItem.site_id = selectSiteId

  },[useFix]);

  useEffect(() => {
    const handleEsc = (event:any) => {
      if (event.keyCode === 27) {
        setModalVisible(false)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

    if(!modalVisible){
      return null;
    }

    return (
      <ModalListDiv>
        <div className='modal'>
          <div className='modal-header'>
            <div className='sub'>
              <div className='title'>{listTitle}</div>
              <BtnNew themeMode={theme}  onClick={()=>addRental()}><i className='icon-pencil'></i>추가</BtnNew>
            </div>
            <ButtonClose onClick={() => {setModalVisible(false)}} themeMode={theme}></ButtonClose>
          </div>
          <div className='modal-body'>
            <div className='modal-grid'>
              <div className='col-left'>
                <div className='plant-issues'>
                  {values.slice(0).reverse().map((rental: IRentalType | IMonthlyRentalType, index:number) => {
                    if (rental.site_id === selectSiteId)
                      return (
                          <div key={index}>
                            <input type='radio' id={'' + rental.id} name='plantIssues' value={rental.id}
                                   defaultChecked={index === 0 ? true : false} onChange={() => handleChange(rental)}/>
                            <label htmlFor={'' + rental.id}>
                              <div className='title'>{rental.name}</div>
                              <div className='summary'>
                                {rental.fix_yn === 'Y' ? '고정' : '변동'} {(rental.fix_yn === 'Y' || day !=='')  ? ': ' + numberWithCommas('' + rental.price, 0) + ' 원' : ''}
                              </div>
                            </label>
                          </div>
                      )
                  })}
                </div>
              </div>
              <div className='col-right'>
                  <div className='content'>
                    <div className='content-body'>
                      <div className='body-grid'>
                        <div className='grid-sub'>
                          <div className='edit_title'>장비명</div>
                          <Input type='text' name='name' value={showItem.name} handleChangeInput={handleChangeInput} max={50} cln='left'/>
                        </div>
                      </div>

                      <div className='body-grid-4'>
                        <div className='grid-sub'>
                          <div className='edit_title'>고정/변동</div>
                          <ModalDropdown themeMode={theme} onClick={()=>handleChangeFix()}>
                            <span>{useFix==='Y'?'고정':'변동'}</span>
                          </ModalDropdown>
                        </div>
                        {(useFix === 'Y' || day !== '') &&
                        <div className='grid-sub-3'>
                          <div className='grid-sub'>
                            <div className='edit_title'>공급가액</div>
                            <NumberInput name='org' value={showItem.org} handleChangeInput={handleChangeNumberInput} suffix=" 원"/>
                          </div>
                          <div className='grid-sub'>
                            <div className='edit_title'>부가세</div>
                            <NumberInput name='vat' value={showItem.vat} handleChangeInput={handleChangeNumberInput} suffix=" 원"/>
                          </div>
                          <div className='grid-sub'>
                            <div className='edit_title'>합계</div>
                            <NumberInput name='price' value={showItem.price} handleChangeInput={handleChangeNumberInput} suffix=" 원" disabled={true}/>
                          </div>
                        </div>
                        }
                      </div>

                    </div>

                    <div className='content-action'>
                      {!addType &&
                        <Button bg={false} onClick={()=>deleteRental()}>삭제</Button>
                      }
                      {!addType &&
                        <Button bg={updateBtn} onClick={()=>updateRental()}>수정</Button>
                      }
                      {addType &&
                        <Button bg={updateBtn} onClick={()=>createRental()}>저장</Button>
                      }
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1500}/>
      </ModalListDiv>
    );
  }

  export default ModalRental;

  