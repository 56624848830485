import styled from 'styled-components'
import Ic_1 from "../../assets/img/ic_1.svg";
import Ic_2 from "../../assets/img/ic_2.svg";
import Ic_3 from "../../assets/img/ic_3.svg";
import Ic_4 from "../../assets/img/ic_4.svg";
import Ic_5 from "../../assets/img/ic_5.svg";
import Ic_6 from "../../assets/img/ic_6.svg";
import Ic_pencel from "../../assets/img/pencel.svg";
import Ic_pen_white from "../../assets/img/ic_pen_white.svg";

import Ic_smp from "../../assets/img/ico_smp.svg";
import Ic_rec from "../../assets/img/ico_rec.svg";

export const Panel = styled.div`
  background-color: ${(props) => props.theme.panel};
  padding: 20px 40px;
  border-radius: 8px;
  color: ${(props) => props.theme.textBase};
  margin-bottom: 5px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-size: 17px;
      margin-left: -4px;
      color: ${(props) => props.theme.textBase};
      i {
        font-size: 24px;
      }
    }
  }
  &.cost {
    .header {
      .title {
        i {
          color: #31b9e4;
          vertical-align: -3px;
        }
      }
    }
  }
  &.rent {
    .header {
      .title {
        i {
          color: #17a59c;
          vertical-align: -3px;
        }
      }
    }
  }
  &.crosscheck {
    .header {
      .title {
        i {
          color: #03a93c;
          vertical-align: -3px;
        }
      }
    }
  }
  &.tax {
    .header {
      .title {
        i {
          color: #fb6232;
          vertical-align: -3px;
        }
      }
    }
  }
  &.status {
    .header {
      .title {
        i {
          color: #886dd3;
          vertical-align: -3px;
        }
      }
    }
  }
  &.theme {
    .header {
      .title {
        i {
          color: #886dd3;
          vertical-align: -3px;
        }
      }
    }
  }
  .more {
    margin: 15px 0 0 auto;
    color: ${(props) => props.theme.textBase2};
  }
  
  [class^='icon-']:before,
  [class*=' icon-']:before {
         margin-right: 0.2em;
          text-align: center;
          margin-left: 0.1em;
          vertical-align: bottom;
     
    }
  
  .icon-ic_1:before {
      content: url(${Ic_1});
      margin-right: 0.2em;
      text-align: center;
      margin-left: 0.1em;
      vertical-align: bottom;
  } 
  .icon-ic_2:before {
      content: url(${Ic_2});
  } 
  .icon-ic_3:before {
      content: url(${Ic_3});
  } 
  .icon-ic_4:before {
      content: url(${Ic_4});
  } 
  .icon-ic_5:before {
      content: url(${Ic_5});
  } 
  .icon-ic_6:before {
      content: url(${Ic_6});
  } 
  .icon-ic_smp:before {
      content: url(${Ic_smp});
  } 
  .icon-ic_rec:before {
      content: url(${Ic_rec});
  } 
`

export const Row = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
  color: ${(props) => props.theme.tableHeaderColor};
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.line};
  }
  &.total {
    color: ${(props) => props.theme.active};
  }
  &.rental {
    color: ${(props) => props.theme.textOrange};
  }
  .span-rental {
    margin-right: 8px;
    font-size: 14px;
    color: #676f7a;
  }
  .span-base {
    margin-left: 8px;
    font-size: 14px;
    color: #676f7a;
  }
  dt, dd {
    font-size: 16px;
  }
`

export const BtnEdit = styled.button<{themeMode:string}>`
  position: relative;
  border-radius: 50vh;
  padding: 5px 10px;
  color: ${(props) => props.theme.textBase};
  background-color: ${(props) => props.theme.mainBase};
  i {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 10px;
    &::before {
      font-family: 'icomoon' !important;
      content: url(${(props) => props.themeMode==='light'?Ic_pencel:Ic_pen_white});
    }
  }

  &:hover {
    background-color: #7f898d;
    color: white;
    &::before {
      background: url(${(props) => props.themeMode==='light'?Ic_pencel:Ic_pen_white}) no-repeat;
    }
  }
`

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .link-btn {
    font-size: 14px;
    color: ${(props) => props.theme.textBase};
    text-decoration: underline;
    background-color: transparent;
  }
  .radio-default {
    display: flex;
    align-items: center;
    input[type='radio'] {
      display: none;
      & + label {
        position: relative;
        color: ${(props) => props.theme.textBase};
        font-size: 14px;
        padding-left: 30px;
        cursor: pointer;
        &:hover {
          &::before {
            border: 1px solid #28589f;
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 20px;
          height: 20px;
          background-color: #e7ecf4;
          border: 1px solid #e7ecf4;
          border-radius: 50vh;
        }
      }
      &:checked {
        & + label {
          &::before {
            box-sizing: border-box;
            background-color: ${(props) => props.theme.panel};
            box-shadow: inset 0 0 0 6px ${(props) => props.theme.active};
            border: 1px solid ${(props) => props.theme.active};
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .radio-default {
    input[type='radio'] {
      & + label {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
`

export const ComboContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-direction: column;
  .link-btn {
    font-size: 14px;
    color: ${(props) => props.theme.textBase};
    text-decoration: underline;
    background-color: transparent;
  }
  .mg-l-10 {
    margin-left: 10px;
  }
  .mg-t-30 {
    margin-top: 30px;
  }
  .radio-default {
    display: flex;
    align-items: center;
    input[type='radio'] {
      display: none;
      & + label {
        position: relative;
        color: ${(props) => props.theme.textBase};
        font-size: 14px;
        padding-left: 30px;
        cursor: pointer;
        &:hover {
          &::before {
            border: 1px solid #28589f;
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 20px;
          height: 20px;
          background-color: #e7ecf4;
          border: 1px solid #e7ecf4;
          border-radius: 50vh;
        }
      }
      &:checked {
        & + label {
          &::before {
            box-sizing: border-box;
            background-color: ${(props) => props.theme.panel};
            box-shadow: inset 0 0 0 6px ${(props) => props.theme.active};
            border: 1px solid ${(props) => props.theme.active};
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    
    input[type='checkbox'] {
      display: none;
      & + label {
        position: relative;
        color: ${(props) => props.theme.textBase};
        font-size: 14px;
        padding-left: 30px;
        padding-right: 10px;
        cursor: pointer;
        &:hover {
          &::before {
            border: 1px solid #28589f;
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 20px;
          height: 20px;
          background-color: #e7ecf4;
          border: 1px solid #e7ecf4;
        }
      }
      &:checked {
        & + label {
          &::before {
            box-sizing: border-box;
            background-color: ${(props) => props.theme.panel};
            box-shadow: inset 0 0 0 9px ${(props) => props.theme.active};
            border: 1px solid ${(props) => props.theme.active};
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    
  }

  .radio-default {
    input[type='radio'] {
      & + label {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
`