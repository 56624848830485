import styled from 'styled-components';

import Ic_pen from '../../assets/img/ic_pen.svg';
import Ic_pen_white from '../../assets/img/ic_pen_white.svg';
import Ic_modalClose from '../../assets/img/ic_modal_close.svg';
import Ic_modalCloseDark from '../../assets/img/ic_modal_close_dark.svg';
import Ic_select_chev from "../../assets/img/ic_select_chev.svg";
import Ic_select_chev_dark from "../../assets/img/ic_select_chev_dark.svg";
import Ic_bt_select from "../../assets/img/ico_bt_select.svg";
import Ic_bt_select_dark from "../../assets/img/ico_bt_select_dark.svg";
import Ic_pencel from "../../assets/img/pencel.svg";

export const ModalListDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  .modal {
    background-color: ${(props) => props.theme.panel};
    border: 1px solid ${(props) => props.theme.subBase};
    border-radius: 10px;
    width: 900px;
    height: 700px;
    overflow: hidden;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 30px;
      height: 60px;
      background-color: ${(props) => props.theme.modalHeader};
      .sub {
        display: flex;
        align-items: center;
      }
      .title {
        font-size: 24px;
        margin-right: 30px;
        padding-bottom: 0px;
        color: ${(props) => props.theme.panelValue};
      }
    }
    &-body {
      height: calc(100% - 48px);
      display: flex;
      flex-direction: column;
      .radio-container {
        padding: 30px;
        border-bottom: 1px solid ${(props) => props.theme.subBase};
        .radio-default {
          input[type='radio'] {
            & + label {
              &:not(:last-child) {
                margin-right: 30px;
              }
            }
          }
        }
      }
      .checkbox-container {
        padding: 30px;
        .checkbox-default {
          margin-bottom: 30px;
          input[type='checkbox'] {
            display: none;
            & + label {
              &:not(:last-child) {
                margin-right: 30px;
              }
            }
          }
        }
        .plant-name-checkbox {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          input[type='checkbox'] {
            display: none;
            & + label {
              padding: 10px;
              font-size: 13px;
              border: 1px solid ${(props) => props.theme.subBase};
              margin: -1px -1px 0 0;
            }
            &:checked {
              & + label {
                background-color: ${(props) => props.theme.mainBlue};
                color: white;
              }
            }
          }
        }
      }
      .modal-grid {
        display: grid;
        grid-template-columns: 1fr;
        height: 100%;
        gap: 0 20px;
      }
      .subinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        .date {
          font-size: 14px;
          color: ${(props) => props.theme.textBase};
        }
        .status {
          font-size: 12px;
          display: contents;
          &.state1 {
            color: #fb6232;
          }
          &.state2 {
            color: #03a93c;
          }
        }
      }

      .col-right {
        display: grid;
        grid-template-rows: 1fr;
        padding: 20px 20px 25px 20px;
        .content {
          border: 1px solid ${(props) => props.theme.mainBase};
          border-radius: 3px;
          width: 100%;
          height: 600px;
          &-header {
            border-bottom: 1px solid ${(props) => props.theme.mainBase};
            padding: 8px 10px;
            .subinfo {
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 0;
              .date {
                margin-left: 20px;
                display: block;
                width: 80px;
              }
            }
          }
          &-body {
            padding: 30px 20px 0 20px;
            height: 530px;
            overflow: auto;
            h2 {
              font-size: 17px;
              color: ${(props) => props.theme.panelValue};
              padding-bottom: 20px;
            }
            .price {
              display: grid;
              text-align: right;
              justify-content: right;
              align-items: right;
              background-color: ${(props) => props.theme.editBtn};
              border: 1px solid ${(props) => props.theme.settlementSortBorder};
              border-radius: 5px;
              width: 90%;
              height: 40px;
              margin-top: 10px;
              z-index: 1;
              position: relative;
              font-size: 16px;
              padding: 10px;
            }
            .body-grid-2 {
              display: grid;
              grid-template-columns: 1fr 1fr;
              height: 100px;
              margin-top: 20px;
              .input_type {
                width: 90%;
                display: inline;
              }
            }
            .body-grid {
              .input_type {
                width: 92%;
                display: inline;
              }
            }
            .line {
              border-top: 1px solid ${(props) => props.theme.mainBase};
              padding-top: 20px;
            }
            .edit_title {
              font-size: 17px;
              color: ${(props) => props.theme.textBase};
              line-height: 25px;
              height: 25px;
            }
            .total {
              font-size: 20px;
            }
          }
          &-edit {
            padding: 0 20px;
            background-color: ${(props) => props.theme.panel};
            &-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              h3 {
                font-size: 15px;
                color: ${(props) => props.theme.panelValue};
              }
            }
            textarea {
              width: 100%;
              height: 160px;
              resize: none;
              font-size: 14px;
              padding: 20px;
              overflow: auto;
              border: 1px solid ${(props) => props.theme.settlementSortBorder};
              border-radius: 3px;
              background-color: ${(props) => props.theme.panel};
              &:focus {
                border: 1px solid ${(props) => props.theme.settlementSortBorder};
              }
            }
          }
          &-action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
            padding: 0 20px;
            margin-top: 20px;
          }
        }
      }
    }
  }
`

export const BtnEdit = styled.button`
  position: relative;
  border-radius: 50vh;
  padding: 5px 10px 5px 24px;
  color: ${(props) => props.theme.lightBlack};
  background-color: ${(props) => props.theme.editBtn};
  &:hover {
    background-color: #7f898d;
    color: ${(props) => props.theme.lightBlack};
    &::before {
      //background: url(${Ic_pen_white}) no-repeat;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    background: url(${Ic_pen}) no-repeat;
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    padding-bottom: 1px;
  }
`

export const BtnNew = styled.button<{themeMode:string}>`
  position: relative;
  border-radius: 50vh;
  padding: 5px 10px;
  color: ${(props) => props.theme.textBase};
  background-color: ${(props) => props.theme.whiteBase2};
  i {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 10px;
    &::before {
      font-family: 'icomoon' !important;
      content: url(${(props) => props.themeMode==='light'?Ic_pencel:Ic_pen_white});
    }
  }

  &:hover {
    background-color: #7f898d;
    color: white;
    &::before {
      background: url(${(props) => props.themeMode==='light'?Ic_pencel:Ic_pen_white}) no-repeat;
    }
  }
`

export const Button = styled.button<{ bg: boolean }>`
  padding: 7px 15px;
  background-color: ${(props) => (
    props.bg ? props.theme.mainBlue : props.theme.modalBtn
  )};
  color: ${(props) => props.theme.panel};
  font-size: 14px;
  border-radius: 3px;
  &:hover {
    background-color: ${(props) => (
      props.bg ? '#28589f' :  props.theme.modalBtn
    )};
  }
`

export const ButtonClose = styled.button<{themeMode:string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  appearance: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: url(${(props) => props.themeMode==='light'?Ic_modalClose:Ic_modalCloseDark}) no-repeat;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    padding-bottom: 1px;
  }
`;

export const ModalDropdown = styled.div<{themeMode:string}>`
    span {
        font-size: 16px;
        width: 100%;
        padding: 0 0 0 10px;
    }    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.textBase};
    background-color: ${(props) => props.theme.panel};
    border: 1px solid ${(props) => props.theme.settlementSortBorder};
    border-radius: 5px;
    width: 100px;
    height: 40px;
    margin-top: 10px;
    z-index: 1;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        background: url(${(props) => props.themeMode==='light'?Ic_bt_select:Ic_bt_select_dark}) no-repeat;
        background-size: 10px 6px;
        width: 10px;
        height: 6px;
        z-index: -1;
    }
}`