import types from '../actions/actionTypes'
import { Actions } from '../actions/subhome'
import { State, initialState } from './index'

const subhomeReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case types.SUBHOME_READ : {
      return { ...state, subhome: action.data }
    }
    case types.SUBHOME_DEVICE : {
      return { ...state, subhomeDevice: action.data }
    }
    case types.SUBHOME_CHART : {
      return { ...state, subhomeChart: action.data }
    }
    default: {
      return state
    }
  }
}

export default subhomeReducer
