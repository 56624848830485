import React from 'react'
import {Panel, Row, BtnEdit, RadioContainer, ComboContainer} from './SettingPanel.style'
import useTheme from "../../lib/useTheme";
import {IMonthlyEarnType, IMonthlyRentalType, IRentalType, ISettlementType} from "../../types/operation";
import {numberWithCommasNumberType} from "../../lib/asyncUtils";

interface IProps {
    title: string
    mainClass: string
    bgIcon: string
    type: string
    tableData?: IRentalType[] | IMonthlyRentalType[]
    earnData?: IMonthlyEarnType[]
    radioStr?: string
    onClickMethod?: any
    onClickCrossYn?: any
    onClickBillYn?: any
    leftStr?: string
    rightStr?: string
    chkValue?: string
    selectSiteId?: string
    settlementData?: ISettlementType
}
function SettingPanel({ mainClass, title, bgIcon, type, tableData, earnData, radioStr, onClickMethod, leftStr, rightStr, chkValue, selectSiteId, settlementData, onClickCrossYn, onClickBillYn }: IProps) {
    const [theme] = useTheme(); // 화면모드

    return (
        <Panel className={mainClass}>
            <div className='header'>
                <div className='title'>
                    <i className={bgIcon}></i> {title}
                </div>
                {(type==='A'||type==='A1'||type==='A3') && <BtnEdit themeMode={theme} onClick={()=>onClickMethod()}>
                    <i className='icon-pencil'></i>수정
                </BtnEdit>}
            </div>
            {type==='A' && tableData &&
                tableData.slice(0).reverse().map((item, i) => {
                    if(item.site_id===selectSiteId)
                return (
                        <Row key={i}>
                            <dt>{item.fix_yn && <span>{item.fix_yn==='Y'?'고정':'변동'}</span>}{item.name}</dt>
                            <dd>{item.fix_yn==='Y'?numberWithCommasNumberType(item.price)+'원':''}</dd>
                        </Row>
                    )
            })}
            {type==='A1' && tableData &&
            tableData.slice(0).reverse().map((item, i) => {
                if(item.site_id===selectSiteId)
                    return (
                        <Row key={i}>
                            <dt>{item.fix_yn && <span className='span-rental'>{item.fix_yn==='Y'?'고정':'변동'}</span>}{item.name}</dt>
                            <dd>{numberWithCommasNumberType(item.price)+'원'}</dd>
                        </Row>
                    )
            })}
            {type==='A2' && settlementData &&
                            <div >
                                {settlementData.smp !== 0 &&
                                <Row>
                                    <dt>SMP</dt>
                                    <dd>{numberWithCommasNumberType(settlementData.smp) + '원'}</dd>
                                </Row>
                                }
                                {settlementData.rec!==0 &&
                                <Row>
                                    <dt>REC</dt>
                                    <dd>{numberWithCommasNumberType(settlementData.rec)+'원'}</dd>
                                </Row>
                                }
                                {settlementData.rental!==0 &&
                                <Row className='rental'>
                                    <dt>지출</dt>
                                    <dd>- {numberWithCommasNumberType(settlementData.rental)+'원'}</dd>
                                </Row>
                                }
                                <Row className='total'>
                                    <dt>합계</dt>
                                    <dd>{numberWithCommasNumberType(settlementData.total)+'원'}</dd>
                                </Row>
                            </div>

            }
            {type==='A3' && earnData &&
            earnData.slice(0).reverse().map((item, i) => {
                if(item.site_id===selectSiteId && item.name===title)
                    return (
                        <Row key={i}>
                            <dt>{item.earn_date.substr(5)}{item.base!=0 && <span className='span-base'>단가 : {numberWithCommasNumberType(item.base)}</span>}</dt>
                            <dd>{numberWithCommasNumberType(item.price)+'원'}</dd>
                        </Row>
                    )
            })}
            {type==='B'  &&
            <RadioContainer>
                <div className='radio-default'>
                    <input
                        type='radio'
                        id={mainClass+'Complete'}
                        name={mainClass+'CompleteCheck'}
                        value='Y'
                        checked={chkValue === 'Y' ? true : false}
                        onChange={()=>onClickMethod('Y')}
                    />
                    <label htmlFor={mainClass+'Complete'}>{!leftStr?'처리완료':leftStr}</label>

                    <input
                        type='radio'
                        id={mainClass+'Incomplete'}
                        name={mainClass+'CompleteCheck'}
                        value='N'
                        checked={chkValue === 'N' ? true : false}
                        onChange={()=>onClickMethod('N')}
                    />
                    <label htmlFor={mainClass+'Incomplete'}>{!rightStr?'미처리':rightStr}</label>
                </div>
                <button className='link-btn'>{radioStr}</button>
            </RadioContainer>
            }
            {type==='B1'  &&
            <RadioContainer>
                <div className='radio-default'>
                    <input
                        type='radio'
                        id={mainClass+'Complete'}
                        name={mainClass+'CompleteCheck'}
                        value='Y'
                        checked={chkValue === 'Y' ? true : false}
                        disabled={true}
                    />
                    <label htmlFor={mainClass+'Complete'}>{!leftStr?'처리완료':leftStr}</label>

                    <input
                        type='radio'
                        id={mainClass+'Incomplete'}
                        name={mainClass+'CompleteCheck'}
                        value='N'
                        checked={chkValue === 'N' ? true : false}
                        disabled={true}
                    />
                    <label htmlFor={mainClass+'Incomplete'}>{!rightStr?'미처리':rightStr}</label>
                </div>
                <button className='link-btn'>{radioStr}</button>
            </RadioContainer>
            }
            {type==='C' &&
            <RadioContainer>
                <div className='radio-default'>
                    <input
                        type='radio'
                        id={mainClass+'Complete'}
                        name={mainClass+'CompleteCheck'}
                        value='D'
                        checked={chkValue === 'D' ? true : false}
                        onChange={()=>onClickMethod('D')}
                    />
                    <label htmlFor={mainClass+'Complete'}>정산완료</label>

                    <input
                        type='radio'
                        id={mainClass+'Incomplete'}
                        name={mainClass+'CompleteCheck'}
                        value='I'
                        checked={chkValue === 'I' || chkValue === 'C' || chkValue === 'B' ? true : false}
                        onChange={()=>onClickMethod('I')}
                    />
                    <label htmlFor={mainClass+'Incomplete'}>처리중</label>

                    <input
                        type='radio'
                        id={mainClass+'Hold'}
                        name={mainClass+'CompleteCheck'}
                        value='H'
                        checked={chkValue === 'H' ? true : false}
                        onChange={()=>onClickMethod('H')}
                    />
                    <label htmlFor={mainClass+'Hold'}>보류</label>
                </div>
            </RadioContainer>
            }
            {type==='D' && settlementData &&
            <ComboContainer>
                {/*<div className='radio-default'>
                    <input
                        type='checkbox'
                        id={mainClass+'Cross'}
                        defaultChecked={settlementData.cross_yn === 'Y' ? true : false}
                        onChange={(e)=>onClickCrossYn(e.target.checked)}
                    />
                    <label htmlFor={mainClass+'Cross'}>크로스체크</label>

                    <input
                        type='checkbox'
                        id={mainClass+'Bill'}
                        defaultChecked={settlementData.bill_yn === 'Y' ? true : false}
                        onChange={(e)=>onClickBillYn(e.target.checked)}
                    />
                    <label htmlFor={mainClass+'Bill'}>세금계산서</label>
                    <button className='link-btn'>{settlementData.bill_date!==''?'요청일 : 매달'+ settlementData.bill_date+'일':'요청일 미입력'}</button>
                </div>*/}

                <div className='radio-default'>
                    <input
                        type='radio'
                        id={mainClass+'Incomplete'}
                        name={mainClass+'CompleteCheck'}
                        value='I'
                        checked={settlementData.state === 'I' ? true : false}
                        onChange={()=>onClickMethod('I')}
                    />
                    <label htmlFor={mainClass+'Incomplete'}>처리중</label>

                    <input
                        type='radio'
                        id={mainClass+'Complete'}
                        name={mainClass+'CompleteCheck'}
                        value='D'
                        checked={settlementData.state === 'D' ? true : false}
                        onChange={()=>onClickMethod('D')}
                    />
                    <label htmlFor={mainClass+'Complete'}>정산완료</label>

                    <input
                        type='radio'
                        id={mainClass+'Hold'}
                        name={mainClass+'CompleteCheck'}
                        value='H'
                        checked={settlementData.state === 'H' ? true : false}
                        onChange={()=>onClickMethod('H')}
                    />
                    <label htmlFor={mainClass+'Hold'}>보류</label>
                </div>
            </ComboContainer>
            }
        </Panel>
    )
}

export default SettingPanel
