import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import ModalStatus from '../../components/ModalStatus'
import Table, {TableBody} from '../../components/Table/Table'

import {numberWithCommasNumberType} from '../../lib/asyncUtils'
import useTheme from "../../lib/useTheme";
import { readJunctionInfo } from '../../store/actions/status'
import { IInverterType } from '../../types/status'

interface JunctionStatusFull {
    name: string
    input_v: number
    input_i: number
    input_p: number
    site_id: string
    id: string
    uuid: string
    sid: string
}

interface IProps {
    values: JunctionStatusFull[]
    info: IInverterType[]
}
function JunctionStatusCard({ values, info }: IProps) {
    const [theme] = useTheme(); // 화면모드
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState({
        site: '',
        device: '',
        inverter_id: '',
        sid: ''
    })

    const listCount = (value: JunctionStatusFull) => {
        return info.filter(item => item.site === value.site_id && item.inverter_id+"" === value.id).length;
    }

    const [first, second, third] = makeThridPart(
        values.sort((a: JunctionStatusFull, b: JunctionStatusFull) => a.name > b.name ? 1: -1).map((value) => ({
            name: {
                content: (
                    <>
                        {value.name}
                    </>
                ),
            },
            input_v: { content: numberWithCommasNumberType(value.input_v) },
            input_i: { content: numberWithCommasNumberType(value.input_i) },
            input_p: { content: numberWithCommasNumberType(value.input_p) },
            status: { 
                content: <button onClick={() => modalView(value.site_id, value.id, value.uuid, value.sid)}>세부사항
                    { listCount(value) === 0 ? '' : <span>{listCount(value)}</span> }
                </button>},
        }))
    )

    const modalView = async (site: string, inverter_id: string, device: string, sid: string) => {
        await dispatch(readJunctionInfo(site, inverter_id));
        setModalData({
            site,
            device,
            inverter_id,
            sid
        })
        setModalVisible(true);
    }

    return (
        <Card title="접속반 상태" girdArea="junction" onClickMore="" header="">
            <TableContainer>
                <Table header={header} body={first} clickAble={false}/>
                <Table header={header} body={second} clickAble={false} />
                <Table header={header} body={third} clickAble={false} />
            </TableContainer>
            { modalVisible && <ModalStatus setModalVisible={setModalVisible} modalData={modalData} type="6" ></ModalStatus> }
        </Card>
    )
}
const header = [
    { name: '이름', key: 'name' },
    { name: '전압(V)', key: 'input_v' },
    { name: '전류(A)', key: 'input_i' },
    { name: '전력(kW)', key: 'input_p' },
    { name: '세부사항', key: 'status' },
]

const makeThridPart = (arr: TableBody[]) => {
    let tempLength = arr.length
    let len = tempLength % 3
    if(len === 1) len = 2
    if(len === 2) len = 1
    const newArr = arr.concat(
        Array( len )
            .fill(undefined)
            .map((_) => ({
                name: { content: '-' },
                input_v: { content: '-' },
                input_i: { content: '-' },
                input_p: { content: '-' },
                status: {content: '-'},
            }))
    )
    let firstPart: TableBody[] = [], secondPart: TableBody[] = [], thirdPart: TableBody[] = [];
    newArr.forEach((element, index) => {
        if(index % 3 === 0){
            firstPart.push(element)
        }else if(index % 3 === 1){
            secondPart.push(element)
        }else {
            thirdPart.push(element)
        }
    })

    return [firstPart, secondPart, thirdPart]
}

export default JunctionStatusCard

const TableContainer = styled.div`
    border-top: 1px solid ${(props) => props.theme.tableTopBorder};
    overflow-x: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    white-space: nowrap;
    width: 100%;
    
    @media (max-width: 1320px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    @media (max-width: 960px) {
        grid-template-columns: 1fr;
    }
    table {
        text-align: center;
        width: 100%;
        &.middle-table {
            border-left: 1px solid ${(props) => props.theme.mainBase};
            border-right: 1px solid ${(props) => props.theme.mainBase};
        }
    }
    thead {
        background-color: ${(props) => props.theme.tableHeader};

        font-size: 14px;
       
        color: ${(props) => props.theme.panelSubLabel};
        th {
            padding: 10px 0;
        }
    }
    tbody {
        font-size: 15px;
        tr {
            height: 50px;
        }

        color: ${(props) => props.theme.tableTxt};
        td {
            padding: 10px 0;
            border-bottom: 1px solid ${(props) => props.theme.line};
            vertical-align: middle;
            &.inverter-status {
                width: 130px;
            }
            &.inverter-rate {
                width: 130px;
            }
            &.check-complete {
                color: #fb6232;
            }
            &.work-complete {
                color: #03a93c;
            }
            &.blue-bord {
                color: #03a93c;
            }
            em {
                font-size: 13px;
                color: #ef1212;
            }
            img {
                display: inline;
            }
            button {
                position: relative;
                margin: 0 auto;
                padding: 6px 8px;
                border-radius: 4px;
                background-color: ${(props) => props.theme.mainBase};
                color: ${(props) => props.theme.tableTxt};

                span {
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    width: 16px;
                    height: 16px;
                    background-color: #03a93c;
                    color: #fff;
                    border-radius: 8px;
                    line-height: 16px;
                }
            }
            button:hover {
                color: #fff;
                background-color: #2c58bb;
            }
        }
    }
`
