import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '../../components/Card/Card'
import EnergyChart from '../../components/Chart/EnergyChart'

import { State } from '../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';

import { todayPgGraph, monthPgGraph } from '../../store/actions/operation'
import useInterval from "../../lib/useInterval";

function EnergyChartCard() {
    const [checkBox, setCheckBox] = useState("H");
    const dispatch = useDispatch();
    const deviceIndex: any = useSelector((state:State) => state.devices);

    const handleInputChange = (type: any) => {
        setCheckBox(type);
         if(checkBox === 'H'){
            //오늘의 발전량
            dispatch(todayPgGraph('Y'))
        }else{
            //이달의 발전량
            dispatch(monthPgGraph('Y'));
        }    
    };
    
    useEffect(() => { 
        //오늘의 발전량
        dispatch(todayPgGraph('Y'));
        //이달의 발전량
        dispatch(monthPgGraph('Y'));
    },[dispatch]);

    // Dynamic delay
    const [delay, setDelay] = useState<number>(60000)
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(true)

    useInterval(
        () => {
            if(checkBox === 'H'){
                //오늘의 발전량
                dispatch(todayPgGraph('Y'));
            }else{
                //이달의 발전량
                dispatch(monthPgGraph('Y'));
            }
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    return (
        <Card
            title="발전량"
            girdArea="energyChart"
            onClickMore=""
            header={
                <ChartSort>
                    <input type="radio" id="daily" name="chartSort" value="H" onChange={e => handleInputChange("H")}
              defaultChecked={"H" === checkBox}/>
                    <label htmlFor="daily">시간</label>

                    <input type="radio" id="monthly" name="chartSort" value="D"  onChange={e => handleInputChange("D")}
              defaultChecked={"D" === checkBox}/>
                    <label htmlFor="monthly">일</label>
                </ChartSort>
            }
        >
            <ChartConatiner>
                <EnergyChart chartId="energyChart" chartType={checkBox} chartData={checkBox==='H'?deviceIndex.todayPgGraph:deviceIndex.monthPgGraph} toolStr={checkBox==='H'?'시':'일'}/>
            </ChartConatiner>
        </Card>
    )
}

export default EnergyChartCard

const ChartConatiner = styled.div`
    @media (max-width: 1440px) {
        height: 250px;
    }
    height: 92%;
`

const ChartSort = styled.div`
    display: flex;
    margin: 0 0 0 auto;
    align-items: center;
    border: 1px solid ${(props) => props.theme.textBlue};
    border-radius: 6px;

    input[type='radio'] {
        display: none;
    & + label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: ${(props) => props.theme.textBase};
            padding: 7px 0;
            width: 50px;
            cursor: pointer;
        }
    &:checked {
        & + label {
                background-color: ${(props) => props.theme.textBlue};
                color: white;
            }
        }
    }
}`