import React, { useState } from 'react'
import { Container, Button } from './Card.style'
import ModalList from '../ModalList'
import { useHistory } from "react-router-dom";

interface IProps {
    title: string
    girdArea: string
    header?: React.ReactNode
    children?: React.ReactNode
    onClickMore: string
    homeUrl?: string
}
function Card({ title, girdArea, children, onClickMore, header, homeUrl }: IProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [init, setInit] = useState(false);

    const [useType, setUseType] = useState('');
    const [useId, setUseId] = useState(0);


    const handleSelect = (id:any, type:any) => {
        setUseType(type)
        setUseId(id)
        setIsModalOpen(true)
        setInit(true)
    }

    const history = useHistory();
    const handleHomeUrl = () => {
        if(homeUrl !== undefined){
            history.push("/"+homeUrl);
        }
    }

    return (
        <Container gridArea={girdArea} onClick={()=>handleHomeUrl()}>
            <div className="title">
                {title}
                {onClickMore !== '' && (
                    <Button className="more" onClick={() => handleSelect(0, onClickMore)}>
                        더보기
                    </Button>
                )}
                {header}
            </div>
            {children}
            {isModalOpen && <ModalList modalVisible={isModalOpen} setModalVisible={setIsModalOpen} useId={useId} useType={useType} init={init} setInit={setInit}></ModalList>}
        </Container>
    )
}

export default Card
