import React from 'react'
import {Container, ChartAction, ChartSort, Button} from './ReportPanel.style'
import Ic_excel from '../../assets/img/icon_excel.svg'
import Ic_excel_dark from '../../assets/img/icon_excel_dark.svg'
import useTheme from "../../lib/useTheme";

interface IProps {
    type: string
    sendDataToParent: any
    sendExcelDownload: any
}
function ReportPanel({type, sendDataToParent, sendExcelDownload}:IProps) {
    const [theme] = useTheme(); // 화면모드
    const handleInputChange = (type: string) => {
        sendDataToParent(type);
    };

    const handleExcelDownload = () =>{
        sendExcelDownload();
    }

    return (
        <Container>
            <ChartAction>
                <ChartSort>
                    <input type="radio" id="daily" name="chartSort" value="H" onChange={e => handleInputChange("H")}
                           defaultChecked={"H" === type}/>
                    <label htmlFor="daily">시간</label>

                    <input type="radio" id="monthly" name="chartSort" value="D" onChange={e => handleInputChange("D")}
                           defaultChecked={"D" === type}/>
                    <label htmlFor="monthly" className='middle'>일</label>

                    <input type="radio" id="yearly" name="chartSort" value="M" onChange={e => handleInputChange("M")}
                           defaultChecked={"M" === type}/>
                    <label htmlFor="yearly">월</label>
                </ChartSort>
                <Button onClick={handleExcelDownload}>
                    <div className='icon'><img src={theme==='light'?Ic_excel:Ic_excel_dark} alt='' /></div> <span>Excel 다운</span>
                </Button>
            </ChartAction>
        </Container>
    );
}

export default ReportPanel
