import React from 'react'
import useTheme from 'lib/useTheme'
import classNames from 'classnames'
import { onLogout } from 'lib/login'
import { useLocation } from 'react-router-dom'
import { ROUTES, ROUTES_DARK } from 'constant/routes'

import Icon8 from 'assets/img/ico_8.svg'
import Icon8Active from 'assets/img/ico_8_blue.svg'
import Icon8Dark from 'assets/img/ico_8_dark.svg'
import Icon8DarkActive from 'assets/img/ico_8_dark_active.svg'
import PVMSLogo from 'assets/img/pvms-logo.svg'
import PVMSLogoDark from 'assets/img/pvms_logo_dark.svg'

import SynergyLogo from 'assets/img/synergy_logo.svg'
import SynergyLogoDark from 'assets/img/synergy_logo_dark.svg'
import SynergySymbol from 'assets/img/synergy_symbol.svg'
import SynergySymbolDark from 'assets/img/synergy_symbol_dark.svg'

import { MenuItem, Nav } from './Navbar.style'

function Navbar() {
    const location = useLocation()
    const [theme] = useTheme() // 화면모드

    if (location.pathname === '/oops') {
        return null
    }

    return (
        <Nav>
            <div className='upper'>
                <div className='pvms-logo'>
                    <a href='/'>
                        <img src={theme === 'light' ? PVMSLogo : PVMSLogoDark} alt='logo' />
                    </a>
                </div>
                <ul className='menu-list'>
                    {theme === 'light' &&
                        ROUTES.map((route) => (
                            <li key={route.path}>
                                <MenuItem
                                    className={classNames({ active: route.path === location.pathname })}
                                    to={route.path}
                                    icon={route.icon.basic}
                                    icon_active={route.icon.active}>
                                    {route.name}
                                </MenuItem>
                            </li>
                        ))}
                    {theme === 'dark' &&
                        ROUTES_DARK.map((route) => (
                            <li key={route.path}>
                                <MenuItem
                                    className={classNames({ active: route.path === location.pathname })}
                                    to={route.path}
                                    icon={route.icon.basic}
                                    icon_active={route.icon.active}>
                                    {route.name}
                                </MenuItem>
                            </li>
                        ))}
                </ul>
            </div>

            <div className='synergy-symbol'>
                <img src={theme === 'light' ? SynergySymbol : SynergySymbolDark} alt='' />
            </div>
            <div className='contact'>
                <a href='tel:070-5226-1147'>
                    <img className='synergy-logo' src={theme === 'light' ? SynergyLogo : SynergyLogoDark} alt='' />
                    <div>
                        <i className='icon-call' /> 통합콜센터
                    </div>
                    <div className='tel'>070-5226-1147</div>
                </a>
            </div>

            <div className='logout'>
                <li>
                    <MenuItem
                        to='logout'
                        icon={theme === 'light' ? Icon8 : Icon8Dark}
                        icon_active={theme === 'light' ? Icon8Active : Icon8DarkActive}
                        onClick={() => onLogout()}>
                        로그아웃
                    </MenuItem>
                </li>
            </div>
        </Nav>
    )
}

export default Navbar
