import React from 'react'
import { InputContainer } from './Input.style'

interface IProps {
    type: string
    name: string
    value: string | number
    handleChangeInput: any
    max?: number
    cln?: string
}
function Input({ type, name, value, handleChangeInput, max, cln }: IProps) {
    return (
        <InputContainer type={type} name={name} value={value} onChange={handleChangeInput} maxLength={max?max:100} align={cln?false:true}/>
    )
}

export default Input
