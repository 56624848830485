import React from 'react'
import loadingImg from '../../assets/img/common/loading.svg'
import styled from "styled-components";

function WaitPage() {

    return (
        <MiscWrapper>
            <div className='misc-inner p-2 p-sm-3'>
                <div className='text-center'>
                    <h2 className='mb-1'>로딩중 입니다...</h2>

                    <img className='img-fluid' src={loadingImg} alt='Not authorized page' />
                </div>
            </div>
        </MiscWrapper>
    )
}

export default WaitPage

const MiscWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;

.text-center {
    text-align: center!important;
}

h2, h3 {
    font-size: 1.6em;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873;
}
.mb-1 {
    margin-bottom: 3rem!important;
}
.mb-2 {
    margin-bottom: 1.5rem!important;
}
`
