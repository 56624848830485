import types from '../actions/actionTypes'
import { Actions } from '../actions/status'
import { State, initialState } from './index'

const statusReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case types.READ_INVERTERINFO : {
        return {...state, status:action.data, statusPaging:action.page}
    }
    case types.READ_INVERTERINFO_ALL : {
      return {...state, statusAll:action.data }
    }
    case types.DELETE_INVERTERINFO : {
      return {...state, status:action.data }
    }
    case types.READ_JUNCTIONINFO : {
      return {...state, statusAll_j:action.data}
    }
    case types.READ_NODATA_DEVICE : {
      return {...state, nodataDevice:action.data}
    }
    default: {
      return state
    }
  }
}

export default statusReducer