import styled from 'styled-components'
import ICMoreChev from '../../assets/img/ic_more_chev.svg'

export const Container = styled.div<{ gridArea: string }>`
    position: relative;
    grid-area: ${(props) => props.gridArea};
    background-color: ${(props) => props.theme.panel};
    border-radius: 8px;
    padding: 30px 30px;
    .title {
        display: flex;
        align-items: center;
        font-size: 24px;
        padding-bottom: 20px;
        color: ${(props) => props.theme.textBase};
        &.small {
            font-size: 18px;
        }
    }
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    appearance: none;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    padding: 0;
    margin: 0;
&.nav-tray {
        background: url('../img/ic_close.svg') no-repeat;
        background-size: 24px 24px;
        background-position: center;
        width: 24px;
        height: 24px;
    }
&.round {
        position: relative;
        font-size: 13px;
        background-color: #a4b2b8;
        padding: 6px 30px 6px 20px;
        border-radius: 50vh;
        color: white;
    &:hover {
            background-color: ${(props) => props.theme.textBlue};
        }
    &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            background: url(${ICMoreChev}) no-repeat;
            background-size: 4px 8px;
            width: 4px;
            height: 8px;
            padding-bottom: 1px;
        }
    }
&.more {
        position: relative;
        font-size: 13px;
        color: ${(props) => props.theme.textBase2};
        background-color: transparent;
        margin-left: 20px;

    &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            background: url(${ICMoreChev}) no-repeat;
            background-size: 4px 8px;
            width: 4px;
            height: 8px;
            padding-bottom: 1px;
        }
    }
}`