import React from 'react'
import {
    DefaultLinkFactory, DefaultLinkModel, DefaultPortModel
} from "@projectstorm/react-diagrams";
import { DefaultLinkWidget, PointModel, LinkWidget } from '@projectstorm/react-diagrams';
import {Point} from "@projectstorm/geometry";

import {
    SelectingState,
    State,
    Action,
    InputType,
} from '@projectstorm/react-canvas-core';
import ReactDOM from 'react-dom';
import ModalSubhome from "../components/ModalSubhome";

export class AdvancedLinkModel extends DefaultLinkModel {
    constructor() {
        super({
            type: 'advanced',
            width: 4
        });
    }
}

export class AdvancedPortModel extends DefaultPortModel {
    // @ts-ignore
    createLinkModel(): AdvancedLinkModel | null {
        return new AdvancedLinkModel();
    }
}

export class AdvancedLinkSegment extends React.Component<{ model: AdvancedLinkModel; path: string }> {
    path: SVGPathElement | null | undefined;
    circle: SVGCircleElement | null | undefined;
    callback: (() => any) | undefined;
    percent: number;
    handle: any;
    mounted: boolean | undefined;

    constructor(props: { model: AdvancedLinkModel; path: string; } | Readonly<{ model: AdvancedLinkModel; path: string; }>) {
        super(props);
        this.percent = 100;
    }

    componentDidMount() {
        this.mounted = true;
        this.callback = () => {
            if (!this.circle || !this.path) {
                return;
            }

            this.percent -= 1;
            if (this.percent <= 0) {
                this.percent = 100;
            }

            let point = this.path.getPointAtLength(this.path.getTotalLength() * (this.percent / 100.0));

            this.circle.setAttribute('cx', '' + point.x);
            this.circle.setAttribute('cy', '' + point.y);

            if (this.mounted) {
                // @ts-ignore
                requestAnimationFrame(this.callback);
            }
        };
        requestAnimationFrame(this.callback);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <>
                <path
                    fill="none"
                    ref={(ref) => {
                        this.path = ref;
                    }}
                    strokeWidth={this.props.model.getOptions().width}
                    stroke="rgba(255,0,0,0.5)"
                    d={this.props.path}
                />
                <circle
                    ref={(ref) => {
                        this.circle = ref;
                    }}
                    r={8}
                    fill="orange"
                />

            </>
        );
    }
}

export class AdvancedLinkFactory extends DefaultLinkFactory {
    constructor() {
        super('advanced');
    }

    // @ts-ignore
    generateModel(): AdvancedLinkModel {
        return new AdvancedLinkModel();
    }

    generateLinkSegment(model: AdvancedLinkModel, selected: boolean, path: string) {
        return (
            <g>
                <AdvancedLinkSegment model={model} path={path} />
            </g>
        )
    }

    generateReactWidget({model}:{model:DefaultLinkModel}): JSX.Element {
        return <AdvancedLinkWidget link={model} diagramEngine={this.engine} />;
    }

}

export class AdvancedLinkWidget extends DefaultLinkWidget {

    render() {
        //ensure id is present for all points on the path
        var points = this.props.link.getPoints();
        var paths = [];
        this.refPaths = [];

        let pointLeft;
        let pointRight;
        // @ts-ignore
        //console.log(this.props.link.sourcePort.options.name)
        // @ts-ignore
        if(this.props.link.sourcePort.options.name === 'C'){
            /*pointLeft = points[points.length - 1];
            pointRight = points[0];
            if (pointRight.getX() > pointLeft.getX()) {
                pointRight = points[points.length - 1];
                pointLeft = points[0];
            }*/
            pointRight = points[points.length - 1];
            pointLeft = points[0];
        }else {
            // @ts-ignore
            if(this.props.link.sourcePort.options.name === 'A'){
                pointLeft = points[points.length - 1];
                pointRight = points[0];
                if (pointRight.getX() > pointLeft.getX()) {
                    pointRight = points[points.length - 1];
                    pointLeft = points[0];
                }
            }else {
                pointLeft = points[0];
                pointRight = points[points.length - 1];
                if (pointLeft.getX() > pointRight.getX()) {
                    pointLeft = points[points.length - 1];
                    pointRight = points[0];
                }
            }
        }
        // Get points based on link orientation
        /*let pointLeft = points[0];
        let pointRight = points[points.length - 1];
        if (pointLeft.getX() > pointRight.getX()) {
            pointLeft = points[points.length - 1];
            pointRight = points[0];
        }*/

        let dy = Math.abs(points[0].getY() - points[points.length - 1].getY());

        if (points.length === 2 && dy !== 0 ) {
            this.props.link.addPoint(
                new PointModel({
                    link: this.props.link,
                    position: new Point(pointLeft.getX(), pointRight.getY())
                })
            );
        }

        for (let j = 0; j < points.length - 1; j++) {
            paths.push(
                this.generateLink(
                    LinkWidget.generateLinePath(points[j], points[j + 1]),
                    {
                        'data-linkid': this.props.link.getID(),
                        'data-point': j,
                    },
                    j
                )
            );
        }

        return <g data-default-link-test={this.props.link.getOptions().testName}>{paths}</g>;
    }
}

export class DiagramState extends State {
    constructor() {
        super({
            name: 'default-diagrams'
        });
        this.childStates = [new SelectingState()];

        // determine what was clicked on
        this.registerAction(
            new Action({
                type: InputType.MOUSE_DOWN,
                fire: (event) => {
                    // @ts-ignore
                    if (event.event.button !== 0) {
                        // we don't want to do anything on right-clicks
                        return;
                    } else {
                        try {
                            // @ts-ignore
                            const element = this.engine.getActionEventBus().getModelForEvent(event);
                            let temp = element.getOptions()
                            // @ts-ignore
                            const element1 = <ModalSubhome selectId={temp.value} selectDate={temp.date}></ModalSubhome>
                            // @ts-ignore
                            document.getElementById('diagramShowModal').style.display = "block";
                            ReactDOM.render(
                                element1,
                                document.getElementById('diagramShowModal')
                            );
                        }catch (e){}
                    }
                }
            })
        )
    }
}


