import styled from 'styled-components'

export const MainLayout = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    background-color: ${(props) => props.theme.mainBase}
`

export const InnerLayout = styled.main`
    padding: 40px 50px 50px 50px;
    height: 100vh;
    overflow: auto;
`

// export const MainLayout = styled.div`
//     display: grid;
//     grid-template-columns: auto 1fr;
//     &.close {
//         grid-template-columns: auto 1fr;
//     }

//     main {
//         padding: 40px 50px 50px 50px;
//         height: 100vh;
//         overflow: auto;
//         .inner {
//             display: grid;
//             grid-template-columns: 1fr 1fr 1fr 1fr;
//             grid-template-rows: auto;
//             grid-template-areas:
//                 'topbar topbar topbar topbar'
//                 'monthProfit todayProfit todayEnergy monthEnergy'
//                 'status status status energyChart'
//                 'fault fault fault summary';
//             gap: 20px;
//             @media (max-width: 1440px) {
//                 grid-template-columns: 1fr 1fr;
//                 grid-template-areas:
//                     'topbar topbar'
//                     'monthProfit todayProfit'
//                     'todayEnergy monthEnergy'
//                     'energyChart energyChart'
//                     'status status'
//                     'fault fault'
//                     'summary summary';
//             }
//             &.menu3 {
//                 grid-template-areas:
//                     'topbar topbar topbar topbar'
//                     'status status status summary'
//                     'fault fault fault fault';
//                 @media (max-width: 1440px) {
//                     grid-template-columns: 1fr;
//                     grid-template-areas:
//                         'topbar'
//                         'status'
//                         'summary'
//                         'fault';
//                 }
//             }
//             .top-bar {
//                 grid-area: topbar;
//                 // grid-column: 1/-1;
//                 // background-color: greenyellow;
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 .plant {
//                     display: flex;
//                     align-items: center;
//                     .page-title {
//                         font-size: 30px;
//                         margin-right: 50px;
//                     }
//                 }
//                 .time {
//                     position: relative;
//                     font-size: 20px;

//                     color: $pvmsLightGray;
//                     &::before {
//                         content: '';
//                         position: absolute;
//                         top: 50%;
//                         transform: translateY(-50%);
//                         left: -30px;
//                         background: url('../img/ic_time.svg') no-repeat;
//                         background-size: 20px 20px;
//                         width: 20px;
//                         height: 20px;
//                     }
//                 }
//             }

//             .panel {
//                 background-color: white;
//                 border-radius: 8px;
//                 padding: 30px 30px;
//                 &::after {
//                     content: '';
//                     position: absolute;
//                     right: 20px;
//                     bottom: 20px;
//                 }
//                 .title {
//                     display: flex;
//                     align-items: center;
//                     font-size: 24px;
//                     padding-bottom: 20px;
//                     color: #3c4046;
//                     &.small {
//                         font-size: 18px;
//                     }
//                     .chart-sort {
//                         margin: 0 0 0 auto;
//                     }
//                 }
//                 .value {
//                     font-size: 38px;

//                     color: #111;
//                     span {
//                         font-size: 24px;
//                     }
//                 }
//                 dl {
//                     display: flex;
//                     align-items: center;

//                     margin-top: 16px;
//                     dt {
//                         font-size: 15px;
//                         color: #888888;
//                         margin-right: 10px;
//                     }
//                     dd {
//                         position: relative;
//                         font-size: 17px;
//                         padding-bottom: 2px;
//                         &::after {
//                             content: '';
//                             position: absolute;
//                             top: 50%;
//                             transform: translateY(-50%);
//                             right: -24px;
//                             background-size: 14px 16px;
//                             width: 14px;
//                             height: 16px;
//                             padding-bottom: 6px;
//                         }
//                     }
//                     &.up {
//                         dd {
//                             color: $pvmsRed;
//                             &::after {
//                                 background: url('../img/ic_up.svg') no-repeat;
//                             }
//                         }
//                     }
//                     &.down {
//                         dd {
//                             color: $pvmsBlue;
//                             &::after {
//                                 background: url('../img/ic_down.svg') no-repeat;
//                             }
//                         }
//                     }
//                 }
//                 &.month-profit {
//                     position: relative;
//                     grid-area: monthProfit;
//                     &::after {
//                         background: url('../img/img_1.svg') no-repeat;
//                         background-size: 60px 60px;
//                         width: 60px;
//                         height: 60px;
//                     }
//                 }
//                 &.today-profit {
//                     position: relative;
//                     grid-area: todayProfit;
//                     &::after {
//                         background: url('../img/img_2.svg') no-repeat;
//                         background-size: 60px 60px;
//                         width: 60px;
//                         height: 60px;
//                     }
//                 }
//                 &.today-energy {
//                     position: relative;
//                     grid-area: todayEnergy;
//                     &::after {
//                         background: url('../img/img_3.svg') no-repeat;
//                         background-size: 60px 60px;
//                         width: 60px;
//                         height: 60px;
//                     }
//                 }
//                 &.month-energy {
//                     position: relative;
//                     grid-area: monthEnergy;
//                     background-color: $pvmsDarkBlue;
//                     color: white;
//                     &::after {
//                         background: url('../img/img_4.svg') no-repeat;
//                         background-size: 60px 60px;
//                         width: 60px;
//                         height: 60px;
//                     }
//                     .title {
//                         color: white;
//                     }
//                     .value {
//                         color: white;
//                     }
//                     dl {
//                         dt {
//                             color: white;
//                         }
//                         dd {
//                             color: $pvmsYellow;
//                         }
//                         &.up {
//                             dd {
//                                 &::after {
//                                     background: url('../img//ic_up_yellow.svg') no-repeat;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 &.status {
//                     grid-area: status;
//                     .status-grid {
//                         display: grid;
//                         grid-template-columns: 1fr 1fr 1fr;
//                         @media (max-width: 1180px) {
//                             grid-template-columns: 1fr;
//                         }
//                         @import 'status';
//                     }
//                 }
//                 &.energy-chart {
//                     grid-area: energyChart;
//                 }
//                 &.fault-detection {
//                     grid-area: fault;
//                     .table-container {
//                         width: 100%;
//                         border-top: 1px solid #516581;
//                         table {
//                             text-align: center;
//                             width: 100%;
//                         }
//                         thead {
//                             background-color: #fafcfd;
//                             font-size: 14px;

//                             color: #111;
//                             th {
//                                 padding: 10px 0;
//                             }
//                         }
//                         tbody {
//                             font-size: 15px;

//                             color: #676f7a;
//                             td {
//                                 padding: 10px 0;
//                                 border-bottom: 1px solid #f1f1f1;
//                                 &.check-complete {
//                                     color: #fb6232;
//                                 }
//                                 &.work-complete {
//                                     color: #03a93c;
//                                 }
//                             }
//                         }
//                         .location {
//                             width: 165px;
//                         }
//                         .content {
//                             width: 589px;
//                         }
//                         .date {
//                             width: 215px;
//                         }
//                         .status {
//                             width: 160px;
//                         }
//                     }
//                 }

//                 &.inverter-table {
//                     grid-area: fault;
//                     overflow: hidden;
//                     .title {
//                         .warning-label {
//                             margin: 0 0 0 auto;
//                         }
//                     }
//                     .inverter-subinfo {
//                         display: flex;
//                         justify-content: space-between;
//                         align-items: center;
//                         font-size: 14px;
//                         color: #3c4046;
//                         padding: 10px 0 30px 0;
//                         .inverter-name {
//                             display: flex;
//                             align-items: center;
//                             flex-wrap: wrap;
//                         }
//                         em {
//                             position: relative;
//                             font-size: 18px;
//                             color: #0b51af;
//                             padding: 0 10px 0 20px;

//                             &::before {
//                                 content: '•';
//                                 position: absolute;
//                                 top: 50%;
//                                 transform: translateY(-50%);
//                                 left: 0;
//                                 padding-right: 10px;
//                                 font-size: 32px;
//                             }
//                         }
//                         .inverter-status {
//                             // display: flex;
//                             // align-items: center;
//                             display: grid;
//                             grid-template-columns: auto auto auto;
//                             gap: 0 50px;
//                             font-size: 17px;
//                             white-space: nowrap;
//                             .good {
//                                 position: relative;
//                                 color: $pvmsBlue;
//                                 padding-left: 34px;
//                                 &::before {
//                                     content: '';
//                                     position: absolute;
//                                     top: 50%;
//                                     transform: translateY(-50%);
//                                     left: 0;
//                                     background: url('../img/ic_good.svg') no-repeat;
//                                     background-size: 24px 24px;
//                                     width: 24px;
//                                     height: 24px;
//                                     padding-bottom: 2px;
//                                 }
//                             }
//                             .standard {
//                                 position: relative;
//                                 color: $pvmsGreen;
//                                 padding-left: 34px;
//                                 &::before {
//                                     content: '';
//                                     position: absolute;
//                                     top: 50%;
//                                     transform: translateY(-50%);
//                                     left: 0;
//                                     background: url('../img/ic_standard.svg') no-repeat;
//                                     background-size: 24px 24px;
//                                     width: 24px;
//                                     height: 24px;
//                                     padding-bottom: 2px;
//                                 }
//                             }
//                             .bad {
//                                 position: relative;
//                                 color: $pvmsRed;
//                                 padding-left: 34px;
//                                 &::before {
//                                     content: '';
//                                     position: absolute;
//                                     top: 50%;
//                                     transform: translateY(-50%);
//                                     left: 0;
//                                     background: url('../img/ic_bad.svg') no-repeat;
//                                     background-size: 24px 24px;
//                                     width: 24px;
//                                     height: 24px;
//                                     padding-bottom: 2px;
//                                 }
//                             }
//                         }
//                     }
//                     .table-container {
//                         // width: 100%;
//                         border-top: 1px solid #516581;
//                         overflow-x: auto;
//                         // white-space: nowrap;
//                         .outer-table {
//                             display: grid;
//                             grid-template-columns: 1fr 1fr 1fr;
//                             white-space: nowrap;
//                             min-width: 1067px;
//                             // min-width: 1053px;
//                         }
//                         table {
//                             text-align: center;
//                             width: 100%;

//                             margin-top: -5px;
//                             // table-layout: fixed;
//                             &.middle-table {
//                                 border-left: 1px solid #eff3f9;
//                                 border-right: 1px solid #eff3f9;
//                             }
//                         }
//                         thead {
//                             background-color: #fafcfd;
//                             font-size: 14px;

//                             color: #111;
//                             th {
//                                 padding: 10px 0;
//                             }
//                         }
//                         tbody {
//                             font-size: 15px;

//                             color: #676f7a;
//                             td {
//                                 padding: 10px 0;
//                                 border-bottom: 1px solid #f1f1f1;
//                                 vertical-align: middle;
//                                 &.inverter-status {
//                                     width: 130px;
//                                 }
//                                 &.inverter-rate {
//                                     width: 130px;
//                                 }
//                                 // &.check-complete {
//                                 //   color: #fb6232;
//                                 // }
//                                 // &.work-complete {
//                                 //   color: #03a93c;
//                                 // }
//                                 em {
//                                     font-size: 13px;
//                                     color: $pvmsRed;
//                                 }
//                                 img {
//                                     display: inline;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 &.summary {
//                     grid-area: summary;
//                     .summary-inner {
//                         .row {
//                             display: flex;
//                             align-items: center;
//                             flex-wrap: wrap;
//                             border-bottom: 1px solid #f1f1f1;
//                             padding: 10px 0;
//                         }
//                         .summary-icon {
//                             margin-right: 20px;
//                         }
//                         .summary-content {
//                             flex: 1 0 182px;
//                             font-size: 12px;
//                             color: #888;
//                             line-height: 1.5em;
//                             padding-right: 10px;
//                             em {
//                                 display: block;
//                                 font-size: 14px;
//                                 color: #3c4046;
//                             }
//                         }
//                         .summary-label {
//                             font-size: 13px;

//                             margin: 0 0 0 auto;
//                             background-color: $pvmsRed;
//                             color: white;
//                             padding: 5px 10px;
//                             border-radius: 50vh;
//                             white-space: nowrap;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `
