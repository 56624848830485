import types from '../actions/actionTypes'
import { Actions } from '../actions/alerts'
import { State, initialState } from './index'
import { IAlertType } from '../../types/alert';

const alertReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case types.CREATE_ALERT : {
      const alerts = [ ...state.alerts, action.data ]
      return { ...state, alerts }
    }
    case types.READ_ALERT : {
      return { ...state, alerts: action.data, alertsPaging: action.paging }
    }
    case types.UPDATE_ALERT : {
      const { id, title } = action.data
      const { alerts, index } = getTargetAlertInfo(state, id)
      alerts[index].title = title
      return { ...state, alerts }
    }
    case types.READ_ALERT_ABNORMAL : {
      return { ...state, alertsAbnormal: action.data, abnormalPaging: action.paging }
    }
    case types.READ_ALERT_REPLACEMENT : {
      return { ...state, alertsReplacement: action.data, replacementCnt: action.total, mainPaging: action.paging }
    }
    case types.UPDATE_ALERT_PROCESS : {
      return { ...state }
    }
    case types.UPDATE_ALERT_COMMENT : {
      return { ...state }
    }
    default: {
      return state
    }
  }
}

export default alertReducer

function getTargetAlertInfo (state: State, id: number) {
  const alerts: IAlertType[] = JSON.parse(JSON.stringify(state.alerts))
  const index = alerts.findIndex(alert => alert.id === id)
  return { alerts, index }
}