import { ActionType, createAction } from 'typesafe-actions'

/* Define Action Type */
const SET_MY_INFO = 'SET_MY_INFO'

/* Define Aciton Creator */
export const setMyInfo = createAction(SET_MY_INFO)<{ authority: string; username: string }>()

/* Action */

const actions = {
    setMyInfo
}
type CommonAction = ActionType<typeof actions>

/* State */
interface CommonState {
    myInfo: { authority: string; username: string } | null
}
const initialState: CommonState = {
    myInfo: null
}

/*Reducer and Reducer Util Function */
const common = (state = initialState, action: CommonAction): CommonState => {
    switch (action.type) {
        case SET_MY_INFO:
            return {
                ...state,
                myInfo: action.payload,
            }
        default:
            return state
    }
}
export default common
