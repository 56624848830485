import styled from 'styled-components'

export const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'topbar topbar topbar topbar'
        'status status status summary'
        'fault fault fault fault'
        'junction junction junction junction';
    gap: 20px;
    @media (max-width: 1440px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'topbar'
            'status'
            'summary'
            'fault'
            'junction';
    }
`
