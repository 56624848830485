export default {
    CREATE_ALERT : 'CREATE_ALERT',
    READ_ALERT   : 'READ_ALERT',
    UPDATE_ALERT : 'UPDATE_ALERT',
    READ_ALERT_ABNORMAL   : 'READ_ALERT_ABNORMAL',
    READ_DEVICES   : 'READ_DEVICES',
    READ_JUNCTIONS   : 'READ_JUNCTIONS',
    SELECT_SID   : 'SELECT_SID',
    MY_SIDS   : 'MY_SIDS',
    TODAY_PG  : 'TODAY_PG',
    MONTH_PG  : 'MONTH_PG',
    TODAY_PG_GRAPH  : 'TODAY_PG_GRAPH',
    MONTH_PG_GRAPH  : 'MONTH_PG_GRAPH',
    READ_ALERT_REPLACEMENT   : 'READ_ALERT_REPLACEMENT',
    UPDATE_ALERT_PROCESS : 'UPDATE_ALERT_PROCESS',
    UPDATE_ALERT_COMMENT : 'UPDATE_ALERT_COMMENT',
    TODAY_REVENUE  : 'TODAY_REVENUE',
    MONTH_REVENUE  : 'MONTH_REVENUE',
    SMP_DATA  : 'SMP_DATA',
    CONTRACT_DATA  : 'CONTRACT_DATA',
    RUN_PRICE  : 'RUN_PRICE',
    REVENUE_DETAILS  : 'REVENUE_DETAILS',
    CONTRACT_UPDATE : 'CONTRACT_UPDATE',
    RENTAL_READ   : 'RENTAL_READ',
    RENTAL_CREATE : 'RENTAL_CREATE',
    RENTAL_UPDATE : 'RENTAL_UPDATE',
    RENTAL_DELETE : 'RENTAL_DELETE',
    SETTLEMENT_READ   : 'SETTLEMENT_READ',
    SETTLEMENT_PRICE   : 'SETTLEMENT_PRICE',
    SETTLEMENT_CROSSYN   : 'SETTLEMENT_CROSSYN',
    SETTLEMENT_BILLYN   : 'SETTLEMENT_BILLYN',
    SETTLEMENT_STATE   : 'SETTLEMENT_STATE',
    MONTHLY_RENTAL_READ   : 'MONTHLY_RENTAL_READ',
    MONTHLY_RENTAL_CREATE : 'MONTHLY_RENTAL_CREATE',
    MONTHLY_RENTAL_UPDATE : 'MONTHLY_RENTAL_UPDATE',
    MONTHLY_RENTAL_DELETE : 'MONTHLY_RENTAL_DELETE',
    MONTHLY_EARN_READ   : 'MONTHLY_EARN_READ',
    MONTHLY_EARN_CREATE : 'MONTHLY_EARN_CREATE',
    MONTHLY_EARN_UPDATE : 'MONTHLY_EARN_UPDATE',
    MONTHLY_EARN_DELETE : 'MONTHLY_EARN_DELETE',
    REC_DATA  : 'REC_DATA',
    SUBHOME_READ  : 'SUBHOME_READ',
    SUBHOME_DEVICE  : 'SUBHOME_DEVICE',
    SUBHOME_CHART  : 'SUBHOME_CHART',
    REPORT_READ : 'REPORT_READ',
    CREATE_INVERTERINFO : 'CREATE_INVERTERINFO',
    READ_INVERTERINFO : 'READ_INVERTERINFO',
    READ_INVERTERINFO_ALL : 'READ_INVERTERINFO_ALL',
    DELETE_INVERTERINFO: 'DELETE_INVERTERINFO',
    READ_JUNCTIONINFO : 'READ_JUNCTIONINFO',
    READ_NODATA_DEVICE   : 'READ_NODATA_DEVICE',
} as const