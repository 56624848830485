import React from 'react'
import {StatusDiv, WarningLabel} from './StatusPanel.style'

interface IProps {
    gauge: any
    name: string
    utilizationRate: string
    statusIcon: string
    number: number
    percent: number
    totalNum: number
    warningNum?: number
}
function StatusPanel({ gauge, name, utilizationRate, statusIcon, number, percent, totalNum, warningNum }: IProps) {
    const showWarning = warningNum && warningNum > 0 ? true:false
    return (
        <StatusDiv gauge={gauge}>
            <div className="status-inner">
                <div className="status-face">
                    <div className="face">
                        <img src={statusIcon} alt="" />
                        <div className={utilizationRate==='-'?'gauge-sleep':'gauge'}>
                            <div className="anime">
                                <div> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="status-face-data">
                    <div className={utilizationRate==='-'?'face-data sleep':'face-data'}>
                        <div className="text-data">{name}</div>
                        <div className="numeric-data">
                            <em>{number}</em>개
                        </div>
                    </div>
                </div>
                <div className="status-rate">
                    <div className="rate">
                        <div className="label">가동율</div>
                        <div>{utilizationRate}</div>
                    </div>
                </div>
                <div className="status-weight">
                    <div className="weight">
                        <div className="label">비중</div>
                        <div>
                            {percent}%({number}/{totalNum})
                        </div>
                    </div>
                </div>
                {showWarning &&
                    <div className="status-label">
                        <WarningLabel>교체필요 {warningNum}</WarningLabel>
                    </div>
                }
            </div>
        </StatusDiv>
    )
}

export default StatusPanel
